import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAlertDialog } from "../../../hooks/useAlertDialog";
import PropTypes from "prop-types";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import DefaultValues from "./DefaultValues";
import RequiredFields from "./RequiredFields";
import Content from "../../Content/Content";
import { CONTACT_SUPPORT } from "../../../constants/global";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import "./style.css";
// import UserPreferences from "./UserPreferences";
import PayablesTemplates from "./Templates";
import { useSnackbar } from "../../../hooks";
import { sassEndpoints } from "../../../constants/endpoints";
import { stringFormat } from "../../../utils/stringHelpers";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { GatewayConfigContext } from "../../../contexts/GatewayConfigContext";

export const ManageSettingsContext = React.createContext({});

function getTabs() {
  const gatewayContext = useContext(GatewayContext);

  const customerPayableEnabled =
    gatewayContext.gatewaySettings
      .find((obj) => obj.code === "CUSTOMER_PAYABLE")
      .value.toLowerCase() === "true";

  return [
    {
      props: { label: "Required Fields" },
      panel: <RequiredFields />,
    },
    {
      props: { label: "Default Values" },
      panel: <DefaultValues />,
    },
    // {
    //   props: { label: "User Preferences" },
    //   panel: <UserPreferences />,
    // },
    customerPayableEnabled && {
      props: { label: "Templates" },
      panel: <PayablesTemplates />,
    },
  ];
}
function TabPanel(props) {
  const { children, value, index, hidePanels, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-${index}`}
      aria-labelledby={`settings-${index}`}
      {...other}
    >
      {hidePanels ? (
        <Box hidden={value !== index} sx={{ mt: 2 }}>
          {children}
        </Box>
      ) : (
        value === index && <Box sx={{ mt: 2 }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  hidePanels: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  hidePanels: false,
};

function commonTabProps(label, index) {
  return {
    key: `tab-${index}`,
    id: `${label}-tab-${index}`,
    "aria-controls": `${label}-tabpanel-${index}`,
    iconPosition: "start",
  };
}

function ManageSettings() {
  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog(["Error"]);
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const { setGatewayConfigSettings } = useContext(GatewayConfigContext);

  const [value, setValue] = React.useState(0);
  const [settings, setSettings] = useState(null);
  const { ...methods } = useForm();

  const location = useLocation();
  const history = useHistory();

  const handleChange = (e, newValue) => {
    setValue(newValue);
    // reset form data on tab change
    methods.reset();
  };

  const TAB_INDEX = {
    REQUIRED: 0,
    DEFAULTS: 1,
    TEMPLATES: 2,
    PREFERENCES: 3,
  };

  const { userSettings } = useContext(UserSettingsContext);

  useEffect(() => {
    location?.state?.theme === "theme"
      ? setValue(TAB_INDEX.PREFERENCES)
      : setValue(TAB_INDEX.REQUIRED);
  }, []);

  useEffect(() => {
    const configUrl = stringFormat(sassEndpoints.gatewayUsers.configuration, [
      userSettings.gatewayId,
    ]);
    axios
      .get(configUrl)
      .then((response) => {
        setSettings(response.data.data);
        setGatewayConfigSettings(response.data.data);
      })
      .catch((error) => {
        setIsAlertOpen(true);
        displayMessages([
          error?.response?.data?.statusDetails || CONTACT_SUPPORT,
        ]);
      });
  }, [snackbarOpen]);

  const returnCategoryCode = (value) => {
    const codes = {
      0: "required_fields",
      1: "default_values",
      2: "templates",
    };
    return codes[value];
  };

  useLayoutEffect(
    () => () => {
      history.push({ state: null });
    },
    [],
  );
  const sendMerchantData = async (payload) => {
    let saasPayload = {
      categoryCode: returnCategoryCode(value),
      fieldEntryValues: [],
    };
    if (value === 0) {
      if (payload && payload.default) {
        Object.entries(payload.default).forEach(([key, value]) => {
          saasPayload.fieldEntryValues.push({
            fieldCode: key,
            fieldValue:
              typeof value === "boolean" ? [value.toString()] : [value],
          });
        });
      }
    } else if (value === 1) {
      Object.keys(payload).forEach((key1) => {
        return Object.entries(payload[key1]).forEach(([key2, value]) => {
          if (key2 === "currency_merchant") {
            saasPayload.fieldEntryValues.push({
              fieldCode: key2,
              fieldValue: ["USD"],
            });
          } else {
            saasPayload.fieldEntryValues.push({
              fieldCode: key2,
              fieldValue:
                typeof value === "boolean" ? [value.toString()] : [value],
            });
          }
        });
      });
    } else if (value === 2) {
      saasPayload.fieldEntryValues = constructTemplatesPayload(payload);
    }
    const putUrl = stringFormat(sassEndpoints.gatewayUsers.configuration, [
      userSettings.gatewayId,
    ]);
    axios
      .put(putUrl, saasPayload)
      .then(() => {
        showSnackbar("Settings updated!");
      })
      .catch((error) => {
        setIsAlertOpen(true);
        displayMessages([
          error?.response?.data?.statusDetails || CONTACT_SUPPORT,
        ]);
      });
  };

  const constructTemplatesPayload = (payload) => {
    let arr = [];
    Object.entries(payload).forEach(([key, value]) => {
      const keyVal = {
        fieldCode: key,
        fieldValue: [value] || [""],
      };
      arr.push(keyVal);
    });
    return arr;
  };

  const onSubmit = (data) => {
    sendMerchantData(data);
  };

  const onError = () => {
    displayMessages(["Please correct validation errors highlighted in red."]);
  };
  return (
    <ManageSettingsContext.Provider value={settings}>
      <FormProvider {...methods}>
        <form>
          <Content
            useFixedWidth={true}
            title="Manage Settings"
            headerContent={
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label={"settings"}
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  {getTabs().map((tab, index) => (
                    <Tab
                      key={index}
                      {...tab.props}
                      {...commonTabProps("settings", index)}
                    />
                  ))}
                </Tabs>
              </Box>
            }
            bodyContent={
              <>
                {getTabs().map((tab, index) => (
                  <TabPanel
                    key={`panel-${index}`}
                    value={value}
                    index={index}
                    hidePanels={false}
                  >
                    {tab.panel}
                  </TabPanel>
                ))}
              </>
            }
            footerContent={
              <div>
                <Button
                  size="small"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  data-cy="settings-save-template"
                  onClick={methods.handleSubmit(onSubmit, onError)}
                >
                  {value !== TAB_INDEX.TEMPLATES ? "Submit" : "Save Template"}
                </Button>
              </div>
            }
            alertDialogOpen={isAlertOpen}
            alertDialogProps={{
              alertTitle: "Error",
              alertLevel: "error",
              alertMessages: alertMessages,
              closeButtonText: "Ok",
              onCloseButtonClick: () => setIsAlertOpen(false),
            }}
            snackbarProps={snackbarProps}
            snackbarOpen={snackbarOpen}
          />
        </form>
      </FormProvider>
    </ManageSettingsContext.Provider>
  );
}

export default ManageSettings;
