import React, { useState, useContext } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PropTypes from "prop-types";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { USER_ROLES } from "../../../constants/global";
import { useTheme } from "@mui/styles";
function MigrationScheduler({
  isOpen,
  handleClose,
  onMigration,
  errorMessage,
}) {
  const [date, setDate] = useState();
  const theme = useTheme();
  const { userSettings } = useContext(UserSettingsContext);
  const sliceAmount =
    userSettings?.userRole === USER_ROLES.SUPER_USER ? 16 : 10;

  const onClick = () => {
    let jsDate = new Date(date);
    if (userSettings?.userRole === USER_ROLES.SUPER_USER) {
      jsDate.toUTCString();
      onMigration(jsDate.toISOString().split("Z")[0], userSettings?.email);
    } else {
      //convert local time -> central time
      let CSTDate = new Date(
        jsDate.toLocaleString("en-US", {
          timeZone: "America/Chicago",
        }),
      );
      // if it's today and past 9pm already adjust timer to tomorrow
      if (
        new Date().getDate() === CSTDate.getDate() &&
        CSTDate.getHours() > 21
      ) {
        CSTDate.setDate(CSTDate.getDate() + 1);
      }
      CSTDate.setHours(21, 0, 0);
      CSTDate.toUTCString();
      onMigration(CSTDate.toISOString().split("Z")[0], userSettings?.email);
    }
  };

  const onDateChange = (e) => {
    if (userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN) {
      const hour = new Date().getHours();
      const minutes = new Date().getMinutes();
      setDate(`${e.target.value}T${hour}:${minutes}`);
    } else {
      setDate(e.target.value);
    }
  };

  const getMaxDate = () => {
    let todaysDate = new Date();
    const adjustedDate = todaysDate.setDate(todaysDate.getDate() + 5);
    return new Date(adjustedDate).toISOString().slice(0, sliceAmount);
  };

  return (
    <Dialog open={isOpen}>
      <div className="migration-scheduler-container">
        <div>
          <div className={`${theme?.palette?.mode} scheduler-banner`}>
            <WarningAmberIcon />
            <p>Once the migration is Scheduled, it CANNOT be canceled.</p>
          </div>
          <DialogTitle>Schedule data migration on:</DialogTitle>
          <div className="migration-scheduler__body">
            <TextField
              style={{ margin: "auto" }}
              size="small"
              onChange={onDateChange}
              label="Set Migration Date"
              inputProps={{
                type:
                  userSettings?.userRole === USER_ROLES.SUPER_USER
                    ? "datetime-local"
                    : "date",
                style: { fontSize: 12 },
                min: new Date().toISOString().slice(0, sliceAmount),
                max: getMaxDate(),
              }}
              InputLabelProps={{ shrink: true }}
            />
            <div className="schedule-migration-button-group">
              <Button
                variant="contained"
                color="neutrals"
                size="small"
                onClick={handleClose}
                sx={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onClick}
              >
                Schedule Migration
              </Button>
            </div>

            {userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN && (
              <p className="migration--label__hint">
                Gateway migrations are scheduled to run at 9 PM CST.
              </p>
            )}
            {errorMessage && <p className="error--text">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

MigrationScheduler.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onMigration: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default MigrationScheduler;
