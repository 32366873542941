import React, { useEffect, useState, useImperativeHandle } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "../../../ReactHookForm/Checkbox";
import { useFormContext } from "react-hook-form";
import AccordionGroup from "../../../Accordion/AccordionGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
function GatewayProcessorsPaya({ reference, editDetails, isEdit }) {
  useImperativeHandle(reference, () => ({
    onSubmit: () => {
      const payload = structureSECPayload();
      return payload;
    },
  }));
  const methods = useFormContext();
  const checkboxes = methods.watch("paya-group");
  const [secCodes, setSECCodes] = useState([]);
  const [actionTrigger, setActionTrigger] = useState(null);
  const [displayAccordions, setDisplayAccordions] = useState(false);
  const [switchAccordions, setSwitchAccordions] = useState(false);

  const [ppdButtons, setPPDButtons] = useState({
    allowCredits: false,
    checkVerification: false,
    dlRequired: false,
    idRequired: false,
    isGatewayTerminal: false,
    secCode: "PPD",
  });

  const [ccdButtons, setCCDButtons] = useState({
    allowCredits: false,
    checkVerification: false,
    dlRequired: false,
    idRequired: false,
    isGatewayTerminal: false,
    secCode: "CCD",
  });
  const [webButtons, setWEBButtons] = useState({
    allowCredits: false,
    checkVerification: false,
    dlRequired: false,
    idRequired: false,
    isGatewayTerminal: false,
    secCode: "WEB",
  });
  const [telButtons, setTELButtons] = useState({
    allowCredits: false,
    checkVerification: false,
    dlRequired: false,
    idRequired: false,
    isGatewayTerminal: false,
    secCode: "TEL",
  });

  const options = [
    { name: "PPD", value: "PPD" },
    { name: "CCD", value: "CCD" },
    { name: "WEB", value: "WEB" },
    { name: "TEL", value: "TEL" },
  ];

  useEffect(() => {
    if (editDetails) {
      Object.values(editDetails).map((val) => {
        switch (val.secCode.toUpperCase()) {
          case "PPD":
            setPPDButtons(val);
            break;
          case "CCD":
            setCCDButtons(val);
            break;
          case "WEB":
            setWEBButtons(val);
            break;
          case "TEL":
            setTELButtons(val);
            break;
        }
      });
    }
    setDisplayAccordions(true);
  }, []);

  useEffect(() => {
    if (actionTrigger) {
      updateSECState(
        actionTrigger.accordionType,
        actionTrigger.column,
        actionTrigger.value,
      );
    }
    setActionTrigger(null);
  }, [actionTrigger]);

  useEffect(() => {
    setSwitchAccordions(false);
  }, [secCodes]);

  useEffect(() => {
    if (displayAccordions) {
      const selectedBoxes = methods.getValues("paya-group");
      let chosenCodes = [];
      if (selectedBoxes.length > 0) {
        setSwitchAccordions(true);
        selectedBoxes.forEach((code) => {
          chosenCodes.push({
            title: code,
            body: accordionTemplate(code),
          });
        });
      }
      setSECCodes(chosenCodes);
    }
  }, [checkboxes, displayAccordions, isEdit]);

  const determineFullEdit = (code) => {
    if (editDetails) {
      let fullDetails = false;
      Object.values(editDetails).map((val) => {
        if (val?.secCode?.toUpperCase() === code?.toUpperCase()) {
          fullDetails = true;
        }
      });
      return fullDetails;
    } else {
      return false;
    }
  };

  const getSavedVals = () => {
    if (editDetails) {
      let secArr = [];
      Object.values(editDetails).map((val) => {
        secArr.push(val?.secCode?.toUpperCase());
      });
      return secArr;
    } else {
      return [];
    }
  };

  const structureSECPayload = () => {
    const allCodes = {
      PPD: ppdButtons,
      CCD: ccdButtons,
      WEB: webButtons,
      TEL: telButtons,
    };
    let setting = {
      CheckProcessorConfiguration: {
        checkTerminals: [],
      },
    };
    secCodes.forEach((code) => {
      const name = methods.getValues(`${code.title}terminalId`);
      const terminalDetails = allCodes[code.title];
      const secDetails = {
        terminalId: name,
        secCode: terminalDetails.secCode,
        ...(editDetails &&
          determineFullEdit(code?.title) && {
            dlRequired: terminalDetails.dlRequired,
            idRequired: terminalDetails.idRequired,
            checkVerification: terminalDetails.checkVerification,
            isGatewayTerminal: terminalDetails.isGatewayTerminal,
            allowCredits: terminalDetails.allowCredits,
          }),
      };
      setting.CheckProcessorConfiguration.checkTerminals.push(secDetails);
    });
    return setting;
  };

  const updateSECState = (code, column, value) => {
    switch (code) {
      case "PPD":
        setPPDButtons({
          ...ppdButtons,
          [column]: value,
        });
        break;
      case "CCD":
        setCCDButtons({
          ...ccdButtons,
          [column]: value,
        });
        break;
      case "WEB":
        setWEBButtons({
          ...webButtons,
          [column]: value,
        });
        break;
      case "TEL":
        setTELButtons({
          ...telButtons,
          [column]: value,
        });
        break;
    }
  };

  const accordionTemplate = (accordionName) => {
    const allCodes = {
      PPD: ppdButtons,
      CCD: ccdButtons,
      WEB: webButtons,
      TEL: telButtons,
    };
    const codeState = allCodes[accordionName.toUpperCase()];
    let defaultName = "";
    if (editDetails) {
      Object.values(editDetails).forEach((val) => {
        if (val.secCode.toUpperCase() === accordionName.toUpperCase()) {
          defaultName = val.terminalId;
        }
      });
    }
    return (
      <div>
        <Grid container>
          <Grid item xs={12} md={4}>
            <RegisteredTextField
              id={`${accordionName}-terminal-id`}
              name={`${accordionName}terminalId`}
              label="Terminal ID Number"
              sx={{ marginBottom: "10px" }}
              defaultValue={defaultName}
              viewOnly={!isEdit}
              fullWidth
              errors={methods.errors}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
        {editDetails && determineFullEdit(accordionName) && (
          <Grid container>
            <Grid item xs={6} md={2}>
              <FormControl>
                <FormLabel id={`${accordionName}-dl`}>DL Required?</FormLabel>
                <RadioGroup
                  aria-labelledby={`${accordionName}-dl`}
                  defaultValue={codeState.dlRequired ? "yes" : "no"}
                  name={`${accordionName}-dl`}
                  onChange={(e) =>
                    setActionTrigger({
                      accordionType: accordionName,
                      column: "dlRequired",
                      value: e.target.value === "yes",
                    })
                  }
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={!isEdit}
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value={"no"}
                    control={<Radio />}
                    label="No"
                    disabled={!isEdit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl>
                <FormLabel id={`${accordionName}-id`}>ID Required?</FormLabel>
                <RadioGroup
                  aria-labelledby={`${accordionName}-id`}
                  defaultValue={codeState.idRequired ? "yes" : "no"}
                  name={`${accordionName}-id`}
                  onChange={(e) =>
                    setActionTrigger({
                      accordionType: accordionName,
                      column: "idRequired",
                      value: e.target.value === "yes",
                    })
                  }
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={!isEdit}
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value={"no"}
                    control={<Radio />}
                    label="No"
                    disabled={!isEdit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl>
                <FormLabel id={`${accordionName}-verification`}>
                  Check Verification?
                </FormLabel>
                <RadioGroup
                  aria-labelledby={`${accordionName}-verification`}
                  defaultValue={codeState.checkVerification ? "yes" : "no"}
                  name={`${accordionName}-verification`}
                  onChange={(e) =>
                    setActionTrigger({
                      accordionType: accordionName,
                      column: "checkVerification",
                      value: e.target.value === "yes",
                    })
                  }
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={!isEdit}
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value={"no"}
                    control={<Radio />}
                    label="No"
                    disabled={!isEdit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl>
                <FormLabel id={`${accordionName}-terminal`}>
                  Gateway Terminal?
                </FormLabel>
                <RadioGroup
                  aria-labelledby={`${accordionName}-terminal`}
                  defaultValue={codeState.isGatewayTerminal ? "yes" : "no"}
                  name={`${accordionName}-terminal`}
                  onChange={(e) =>
                    setActionTrigger({
                      accordionType: accordionName,
                      column: "isGatewayTerminal",
                      value: e.target.value === "yes",
                    })
                  }
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={!isEdit}
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value={"no"}
                    control={<Radio />}
                    label="No"
                    disabled={!isEdit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl>
                <FormLabel id={`${accordionName}-credit`}>
                  Allow Credits?
                </FormLabel>
                <RadioGroup
                  aria-labelledby={`${accordionName}-credit`}
                  defaultValue={codeState.allowCredits ? "yes" : "no"}
                  name={`${accordionName}-credit`}
                  onChange={(e) =>
                    setActionTrigger({
                      accordionType: accordionName,
                      column: "allowCredits",
                      value: e.target.value === "yes",
                    })
                  }
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={!isEdit}
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value={"no"}
                    control={<Radio />}
                    label="No"
                    disabled={!isEdit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <div className="sage-block">
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={4}>
          <Checkbox
            name={"paya-group"}
            defaultValues={getSavedVals()}
            label={
              editDetails
                ? "Choose all SEC Codes that apply"
                : "Choose to add terminals"
            }
            control={methods.control}
            errors={methods.errors}
            options={options}
            disabled={!isEdit}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <div className="sec-info-block">
            <InfoIcon
              color="info"
              sx={{
                fontSize: "1.5em",
              }}
            />
            <Typography
              variant="p"
              color="textSecondary"
              sx={{ paddingLeft: "5px" }}
            >
              One checkbox must be selected.
            </Typography>
          </div>
        </Grid>
        {secCodes.length > 0 && !switchAccordions && (
          <Grid item xs={12}>
            <AccordionGroup accordionDetails={secCodes} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
GatewayProcessorsPaya.propTypes = {
  reference: PropTypes.object,
  editDetails: PropTypes.array,
  isEdit: PropTypes.bool,
};
export default GatewayProcessorsPaya;
