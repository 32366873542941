import PropTypes from "prop-types";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Box from "@mui/material/Box";
import empty from "../../assets/images/empty-state.png";
import { getEntityKeyTitle, getSortableTableCell } from "./searchHelpers";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
const SearchTable = ({
  fieldList,
  searchResults,
  getEntityKeyValue,
  getActionsComponent,
  handleSortOnClick,
  handleFilterOnClick,
  rowSx,
  componentCellSx,
  fieldCellSx,
  footerList,
  expandData,
}) => {
  const fieldBoldness = 600;
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="search results table">
          {searchResults ? (
            <>
              <TableHead>
                <TableRow sx={rowSx}>
                  {getActionsComponent && (
                    <TableCell
                      sx={{
                        fontWeight: fieldBoldness,
                        whiteSpace: "nowrap",
                        pl: 1,
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                  {fieldList.map((keyInfo, i) => (
                    <TableCell
                      key={i}
                      {...getSortableTableCell(keyInfo, handleSortOnClick)}
                    >
                      {keyInfo.sortable ? (
                        <div
                          className={
                            keyInfo.sortType?.toLowerCase() === "desc"
                              ? "sortable-header is-desc"
                              : "sortable-header"
                          }
                        >
                          {getEntityKeyTitle(keyInfo.key)}
                          <KeyboardArrowDownIcon />
                        </div>
                      ) : keyInfo.filterable ? (
                        <div
                          className="filter-btn"
                          onClick={handleFilterOnClick}
                        >
                          <FilterAltIcon
                            color={keyInfo.filtered ? "secondary" : ""}
                          />
                          {getEntityKeyTitle(keyInfo.key)}
                        </div>
                      ) : (
                        <> {getEntityKeyTitle(keyInfo.key)}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ verticleAlign: "top" }}>
                {searchResults.map((row, i) => (
                  <React.Fragment key={i}>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticleAlign: "top",
                      }}
                      className={
                        (row?.disabled ? "disabled" : null,
                        row?.isExpanded ? "active" : null)
                      }
                    >
                      {getActionsComponent && (
                        <TableCell
                          component="th"
                          scope="row"
                          sx={componentCellSx}
                        >
                          <Box textAlign="left">{getActionsComponent(row)}</Box>
                        </TableCell>
                      )}
                      {fieldList.map((keyRow, i) => (
                        <TableCell key={i} sx={fieldCellSx}>
                          {getEntityKeyValue(keyRow.key, row)}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* // if expand collapse do another row with collapse state on it */}
                    {expandData && (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            border: 0,
                          }}
                        >
                          <Collapse
                            in={row.isExpanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            {expandData(row)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ borderBottom: 0 }}>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                      variant="h2"
                      textAlign="center"
                    >
                      <Box>
                        <img src={empty} style={{ height: 200 }} />
                      </Box>
                      <Box>Currently no results.</Box>
                      <Box>Refine search or add data.</Box>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          )}
          {footerList && footerList.length > 0 && (
            <TableFooter>
              <TableRow>
                {footerList.map((keyRow, i) => (
                  <TableCell key={i} sx={fieldCellSx}>
                    {keyRow}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

SearchTable.propTypes = {
  fieldList: PropTypes.array,
  searchResults: PropTypes.array,
  results: PropTypes.array,
  getEntityKeyValue: PropTypes.func,
  getActionsComponent: PropTypes.func,
  expandData: PropTypes.func,
  handleSortOnClick: PropTypes.func,
  handleFilterOnClick: PropTypes.func,
  rowSx: PropTypes.object,
  componentCellSx: PropTypes.object,
  fieldCellSx: PropTypes.object,
  footerList: PropTypes.array,
};

SearchTable.defaultProps = {
  componentCellSx: { padding: 1 },
  fieldCellSx: {
    padding: 1,
    maxWidth: 250,
    wordWrap: "break-word",
  },
};

export default SearchTable;
