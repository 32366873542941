import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Typography } from "@mui/material";

const LabelWithTooltip = ({ label, tooltip }) => {
  return (
    <React.Fragment>
      <Typography variant="body1" className="label-with-tooltip-label">
        {label}
      </Typography>
      <Tooltip title={tooltip} placement="top">
        <InfoIcon fontSize="small" className="label-with-tooltip-icon" />
      </Tooltip>
    </React.Fragment>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
};

export default LabelWithTooltip;
