import MUISwitch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AchIcon from "@mui/icons-material/AccountBalance";
export const payTableHeaders = [
  {
    default_field: true,
    key: "default",
    sortable: false,
  },
  {
    default_field: true,
    key: "payment_method",
    sortable: false,
  },
];
export const addressTableHeaders = [
  {
    default_field: true,
    key: "default",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "company",
    sortable: false,
  },
  {
    default_field: true,
    key: "address",
    sortable: false,
  },
  {
    default_field: true,
    key: "contact",
    sortable: false,
  },
];

export const getPayTableValue = (key, data) => {
  switch (key) {
    case "default": {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Off</Typography>
          <MUISwitch color="secondary" defaultChecked={data.isDefault} />
          <Typography>On</Typography>
        </Stack>
      );
    }
    case "payment_method": {
      if (data.payment_method === "card") {
        return (
          <div className="payment-cell">
            <CreditCardIcon />
            <div>
              <p>{data.masked_number}</p>
              <p> {data.expiration_date}</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="payment-cell">
            <AchIcon />
            <div>
              <p> {data.masked_account_number}</p>

              <p>Type: {data.account_type}</p>
            </div>
          </div>
        );
      }
    }

    default:
      return data[key];
  }
};
