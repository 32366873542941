import React, { useEffect, useState, useContext } from "react";
import ContentComponent from "../Content/Content";
import axios from "axios";
import { sassEndpoints } from "../../constants/endpoints";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { stringFormat } from "../../utils/stringHelpers";
import "./application.scss";
import { Button, Grid } from "@mui/material";
import emptyImage from "../../assets/images/empty-state.png";
import ListCard from "./ListCard";
import { useAlertDialog } from "../../hooks";
import { useSnackbar } from "../../hooks";

const WebhooksContainer = () => {
  const [webhooksData, setWebhooksData] = useState([]);
  const [webhooksList, setWebhooksList] = useState([]);
  const [editCard, setEditCard] = useState(null);

  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog();

  const { userSettings } = useContext(UserSettingsContext);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const [alertDialogProps, setAlertDialogProps] = useState({
    alertTitle: "Error",
    alertLevel: "error",
    alertMessages: alertMessages,
    onCloseButtonClick: () => setIsAlertOpen(false),
  });
  const [refreshWebhooksList, setRefreshWebhooksList] = useState(false);

  const [createWebhook, setCreateWebhook] = useState(false);

  useEffect(() => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.gateways.configuration, [
      userSettings.gatewayId,
    ]);
    axios
      .get(url)
      .then((response) => {
        let webhooksCount = 0;
        const webhooksObject = [];
        let webhooksCategories = response.data.data.categories.find(
          (d) => d.displayName === "Webhooks",
        ).groups;
        webhooksCategories.forEach((g) =>
          g.fields[0].value.forEach((url) => {
            webhooksObject.push({ [g.name]: url });
          }),
        );

        webhooksCategories.forEach((g) => {
          webhooksCount += g.fields[0].value.length;
        });

        if (webhooksCount === 0) {
          setWebhooksData(null);
          setWebhooksList(null);
        } else {
          setWebhooksData(webhooksCategories);
          setWebhooksList(webhooksObject);
        }
      })
      .catch(() => {
        setAlertDialogProps({
          alertTitle: "Error",
          alertLevel: "error",
          alertMessages: [
            "Unable to retrieve webhooks. Please try again later.",
          ],
          onCloseButtonClick: () => setIsAlertOpen(false),
        });
        setIsAlertOpen(true);
        setShowBackdrop(false);
      })
      .finally(() => {
        setShowBackdrop(false);
        if (refreshWebhooksList) {
          setRefreshWebhooksList(false);
        }
      });
  }, [refreshWebhooksList]);

  const callPutWebhook = (type, payload) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.gateways.configuration, [
      userSettings.gatewayId,
    ]);
    axios
      .put(url, payload)
      .then(() => {
        if (type === "create") {
          setCreateWebhook(false);
        } else if (type === "delete" || type === "edit") {
          setEditCard(null);
        }
        showSnackbar(
          type === "delete"
            ? "Webhook successfully deleted"
            : type === "edit"
              ? "Webhook successfully edited"
              : "Webhook successfully created",
        );
        setRefreshWebhooksList(true);
      })
      .catch(() => {
        setAlertDialogProps({
          alertTitle: "Error",
          alertLevel: "error",
          alertMessages:
            type === "create"
              ? ["Unable to add new webhooks. Please try again later."]
              : type === "delete"
                ? ["Unable to delete webhook. Please try again later."]
                : type === "edit"
                  ? ["Unable to edit webhooks. Please try again later."]
                  : [""],
          onCloseButtonClick: () => setIsAlertOpen(false),
        });
        setIsAlertOpen(true);
        setShowBackdrop(false);
      });
  };

  return (
    <div className="developer-body">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Webhooks"
        headerContent={
          <Grid
            container
            className="developer-header-div"
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={10}>
              {" "}
              Webhooks are user-defined HTTP callbacks. They are triggered by
              some event in a web application and can facilitate integrating
              different applications or third-party APIs.
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setCreateWebhook(true)}
                sx={{ float: "right" }}
                variant="contained"
                className="primary-medium-button"
                disabled={editCard !== null}
              >
                Create New +
              </Button>
            </Grid>
          </Grid>
        }
        bodyContent={
          <>
            {webhooksData === null && !createWebhook ? (
              <Grid
                container
                className="no-developer-container"
                flexDirection={"column"}
                justifyItems="center"
              >
                {" "}
                <Grid item style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <img src={emptyImage}></img>
                </Grid>
                <Grid item>You currently have no webhooks configured</Grid>
              </Grid>
            ) : (
              ((webhooksData && webhooksList) ||
                (webhooksData === null && createWebhook)) && (
                <div>
                  <ListCard
                    type={"webhooks"}
                    data={webhooksList}
                    webhooksData={webhooksData}
                    showSnackbar={showSnackbar}
                    createWebhook={createWebhook}
                    setCreateWebhook={setCreateWebhook}
                    displayMessages={displayMessages}
                    setIsAlertOpen={setIsAlertOpen}
                    setAlertDialogProps={setAlertDialogProps}
                    editCard={editCard}
                    setEditCard={setEditCard}
                    callPutWebhook={callPutWebhook}
                  ></ListCard>
                </div>
              )
            )}
          </>
        }
        alertDialogOpen={isAlertOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};
export default WebhooksContainer;
