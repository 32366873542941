import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Contact from "./Contact";
import { useFormContext } from "react-hook-form";
import useIsInitialRender from "../../hooks/useIsInitialRender";
import { DEFAULT_REQUIRED_FIELDS } from "../../constants/global";
import { State } from "country-state-city";
const defaultHiddenFields = {
  firstName: false,
  lastName: false,
  company: false,
  address1: false,
  address2: false,
  country: false,
  state: false,
  city: false,
  postalCode: false,
  email: false,
  phone: false,
  fax: false,
};

const ContactContainer = ({
  type,
  countries,
  required,
  defaultValues,
  viewOnly,
  hidden,
  setTouchedAddressForm,
}) => {
  let country = useRef(defaultValues?.country || "US");
  let isInitialRender = useIsInitialRender();
  const [states, setStates] = useState([]);
  const contactType = type ? type.toLowerCase() : "billing";
  const [requiredFields, setRequiredFields] = useState(DEFAULT_REQUIRED_FIELDS);
  const [hiddenFields, setHiddenFields] = useState(defaultHiddenFields);
  const {
    setValue,
    resetField,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();

  useEffect(() => {
    if (required) {
      let newRequiredFields = {};
      required.forEach((field) => (newRequiredFields[field] = true));
      setRequiredFields({
        ...DEFAULT_REQUIRED_FIELDS,
        ...newRequiredFields,
      });
    }
    if (hidden) {
      setHiddenFields({ ...DEFAULT_REQUIRED_FIELDS, ...hidden });
    }

    if (isSubmitted) trigger();
  }, [required]);

  useEffect(() => {
    if (isInitialRender) {
      loadStates(defaultValues?.country || "US", defaultValues?.state || "");
    } else {
      resetField(type, { defaultValue: { ...defaultValues, state: "" } });
      handleCountryChange(
        defaultValues?.country || "",
        defaultValues?.state || "",
      );
    }
  }, [defaultValues]);

  const handleCountryChange = (newCountry, state) => {
    if (country === newCountry) return;
    setValue(`${type}.state`, "");
    country = newCountry;
    newCountry === "" ? setStates([]) : loadStates(newCountry, state);
  };

  const loadStates = (country, state) => {
    setStates(
      State.getStatesOfCountry(country).map((s) => {
        s.code = s.isoCode;
        return s;
      }),
    );
    if (state) setValue(`${type}.state`, state);
    if (country) setValue(`${type}.country`, country);
  };

  return (
    <Contact
      type={contactType}
      onCountryChange={handleCountryChange}
      countries={countries}
      states={states}
      requiredFields={requiredFields}
      defaultValues={defaultValues}
      viewOnly={viewOnly}
      hiddenFields={hiddenFields}
      setTouchedAddressForm={setTouchedAddressForm}
    />
  );
};

export default ContactContainer;

ContactContainer.propTypes = {
  type: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  required: PropTypes.array,
  defaultValues: PropTypes.object,
  viewOnly: PropTypes.bool,
  hidden: PropTypes.object,
  setTouchedAddressForm: PropTypes.func,
};

ContactContainer.defaultProps = {
  viewOnly: false,
};
