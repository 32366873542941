import React, { useEffect, useState, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ContentComponent from "../Content/Content";
import axios from "axios";
import { sassEndpoints } from "../../constants/endpoints";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { stringFormat } from "../../utils/stringHelpers";
import "./application.scss";
import { Button, Grid } from "@mui/material";
import emptyImage from "../../assets/images/empty-state.png";
import ListCard from "./ListCard";
import { useAlertDialog } from "../../hooks";
import { useSnackbar } from "../../hooks";
import RecordSecretModal from "./RecordSecretModal";

const ApplicationContainer = () => {
  const { ...methods } = useForm();
  const { userSettings } = useContext(UserSettingsContext);
  const [applicationData, setApplicationData] = useState([]);
  const [refreshApplicationsList, setRefreshApplicationsList] = useState(false);
  const [createApp, setCreateApp] = useState(false);
  const [manageApp, setManageApp] = useState(null);
  const [openSecretModal, setOpenSecretModal] = useState(null);
  const [createdAppSuccess, setCreatedAppSuccess] = useState(null);
  const [deleteSecretSuccess, setDeleteSecretSuccess] = useState(null);
  const [createdSecretSuccess, setCreatedSecretSuccess] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();

  const { isAlertOpen, setIsAlertOpen, displayMessages } = useAlertDialog();
  const defaultAlertProps = {
    alertTitle: "Error",
    alertLevel: "error",
    onCloseButtonClick: () => setIsAlertOpen(false),
  };

  const [alertDialogProps, setAlertDialogProps] = useState(defaultAlertProps);

  useEffect(() => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.developerHub.application, [
      userSettings.gatewayId,
    ]);
    axios
      .get(url)
      .then((response) => {
        if (response.status === 204) {
          setApplicationData(null);
        } else {
          setApplicationData(response.data.data);
          if (createdAppSuccess) {
            response.data.data.forEach((app, i) => {
              if (
                app.appRegistrationId === createdAppSuccess.appRegistrationId
              ) {
                setManageApp({ index: i });
                showSnackbar("Application successfully created");
              }
            });
            setCreatedAppSuccess(false);
          } else if (createdSecretSuccess) {
            response.data.data.forEach((app, i) => {
              if (
                app.appRegistrationId === createdSecretSuccess.appRegistrationId
              ) {
                setManageApp({ index: i });
                showSnackbar("Secret Created");
              }
            });
            setCreatedSecretSuccess(null);
          } else if (deleteSecretSuccess) {
            response.data.data.forEach((app, i) => {
              if (app.appRegistrationId === deleteSecretSuccess) {
                setManageApp({ index: i });
                showSnackbar("Secret Deleted");
              }
            });
            setDeleteSecretSuccess(false);
          }
        }
      })
      .catch(() => {
        setAlertDialogProps({
          ...defaultAlertProps,
          alertMessages: [
            "Unable to retrieve applications. Please try again later.",
          ],
        });
        setIsAlertOpen(true);
        if (createdAppSuccess) {
          setCreatedAppSuccess(false);
        } else if (createdSecretSuccess) {
          setCreatedSecretSuccess(false);
        } else if (deleteSecretSuccess) {
          setDeleteSecretSuccess(false);
        }
      })
      .finally(() => {
        setRefreshApplicationsList(false);
        setShowBackdrop(false);
      });
  }, [refreshApplicationsList]);

  useEffect(() => {
    if (createdAppSuccess || deleteSecretSuccess || createdSecretSuccess) {
      setRefreshApplicationsList(Math.random());
    }
  }, [createdAppSuccess, deleteSecretSuccess, createdSecretSuccess]);

  const callDeleteApplication = (id) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.developerHub.appId, [
      userSettings.gatewayId,
      id,
    ]);
    axios
      .delete(url)
      .then(() => {
        showSnackbar("Application Deleted");
        setManageApp(null);
        setRefreshApplicationsList(true);
      })
      .catch(() => {
        setAlertDialogProps({
          ...defaultAlertProps,
          alertMessages: [
            "Unable to delete applications. Please try again later.",
          ],
        });
        setIsAlertOpen(true);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const callCreateApplication = (payload) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.developerHub.application, [
      userSettings.gatewayId,
    ]);
    axios
      .post(url, payload, {})
      .then((response) => {
        setCreateApp(false);
        setCreatedAppSuccess(response.data.data);
      })
      .catch(() => {
        setAlertDialogProps({
          ...defaultAlertProps,
          alertMessages: [
            "Unable to create application. Please try again later.",
          ],
        });
        setIsAlertOpen(true);
        setShowBackdrop(false);
      });
  };

  const callCreateSecret = (appId, payload) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.developerHub.appSecret, [
      userSettings.gatewayId,
      appId,
    ]);
    axios

      .post(url, payload, {})
      .then((response) => {
        setOpenSecretModal(response.data.data);
        setCreatedSecretSuccess(response.data.data);
      })
      .catch(() => {
        setAlertDialogProps({
          ...defaultAlertProps,
          alertMessages: ["Unable to create secret. Please try again later."],
        });
        setIsAlertOpen(true);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const callDeleteSecret = (appId, secretId) => {
    setShowBackdrop(true);
    let url =
      stringFormat(sassEndpoints.developerHub.appSecret, [
        userSettings.gatewayId,
        appId,
      ]) + `/${secretId}`;
    axios
      .delete(url)
      .then(() => {
        setDeleteSecretSuccess(appId);
        setShowBackdrop(false);
      })
      .catch(() => {
        setAlertDialogProps({
          ...defaultAlertProps,
          alertMessages: ["Unable to delete secret. Please try again later."],
        });
        setIsAlertOpen(true);
        setShowBackdrop(false);
      });
  };

  return (
    <div className="developer-body">
      <FormProvider {...methods}>
        <ContentComponent
          spinnerOpen={showBackdrop}
          useFixedWidth={false}
          title="Applications"
          headerContent={
            <Grid
              container
              className="developer-header-div"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                Use this section to configure your applications and generate the
                corresponding secrets
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setCreateApp(true)}
                  variant="contained"
                  color="secondary"
                  className="primary-medium-button"
                  disabled={manageApp}
                >
                  Create New +
                </Button>
              </Grid>
            </Grid>
          }
          bodyContent={
            <>
              {applicationData === null && !createApp ? (
                <Grid
                  container
                  className="no-developer-container"
                  flexDirection={"column"}
                  justifyItems="center"
                >
                  {" "}
                  <Grid
                    item
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <img src={emptyImage}></img>
                  </Grid>
                  <Grid item>
                    You currently have no applications configured
                  </Grid>
                </Grid>
              ) : (
                (applicationData?.length > 0 ||
                  (applicationData === null && createApp)) && (
                  <div>
                    <ListCard
                      type={"application"}
                      data={applicationData}
                      showSnackbar={showSnackbar}
                      createApp={createApp}
                      setCreateApp={setCreateApp}
                      displayMessages={displayMessages}
                      setIsAlertOpen={setIsAlertOpen}
                      setAlertDialogProps={setAlertDialogProps}
                      manageApp={manageApp}
                      setManageApp={setManageApp}
                      callDeleteApplication={callDeleteApplication}
                      callCreateApplication={callCreateApplication}
                      callCreateSecret={callCreateSecret}
                      callDeleteSecret={callDeleteSecret}
                    ></ListCard>
                  </div>
                )
              )}
            </>
          }
          alertDialogOpen={isAlertOpen}
          alertDialogProps={alertDialogProps}
          snackbarOpen={snackbarOpen}
          snackbarProps={snackbarProps}
        />
      </FormProvider>
      {openSecretModal && (
        <RecordSecretModal
          openSecretModal={openSecretModal}
          setOpenSecretModal={setOpenSecretModal}
        />
      )}
    </div>
  );
};
export default ApplicationContainer;
