import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { getProperty } from "../../utils/objectHelpers";
import { isRequired } from "./formHelpers";
import { useFormContext } from "react-hook-form";

export const ControllerTextField = ({
  name,
  defaultValue,
  control,
  errors,
  rules,
  transform,
  onChange,
  onBlur,
  viewOnly,
  datacy,
  highlightError,
  ...restTextFieldProps
}) => {
  const error = getProperty(name, errors);
  const { trigger } = useFormContext();

  if (viewOnly) {
    restTextFieldProps.variant = "standard";
    restTextFieldProps.disabled = true;
    restTextFieldProps.InputProps = {
      ...restTextFieldProps.InputProps,
      disableUnderline: viewOnly,
    };
  }

  const validateField = (fieldName) => {
    if (rules) {
      trigger(fieldName);
    }
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...rest } }) => (
        <TextField
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{ datacy: datacy }}
          required={isRequired(rules)}
          error={error !== undefined || highlightError}
          inputRef={ref}
          {...rest}
          onChange={(e) => {
            const newValue = transform.output(e.target.value);
            rest.onChange(newValue);
            onChange && onChange(newValue);
          }}
          onBlur={() => {
            validateField(name);
            onBlur && onBlur();
          }}
          value={transform.input(value)}
          helperText={error && error.message}
          {...restTextFieldProps}
          FormHelperTextProps={{
            sx: { ml: 0 },
          }}
          sx={{
            ...(highlightError && {
              "& .MuiOutlinedInput-root": {
                borderColor: "red",
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "red",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "red",
                },
              },
            }),
          }}
        />
      )}
    />
  );
};

export default ControllerTextField;

ControllerTextField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  rules: PropTypes.object,
  transform: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  viewOnly: PropTypes.bool,
  datacy: PropTypes.string,
  highlightError: PropTypes.bool,
};

ControllerTextField.defaultProps = {
  defaultValue: "",
  viewOnly: false,
  datacy: "",
  highlightError: false,
};
