import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { useScript } from "../../hooks/index";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import "./tokenizer.scss";
import { CARD_FORM_VALIDATION, correctInput } from "./tokenizerHelpers";
import { useTheme } from "@mui/material/styles";
import { sassEndpoints } from "../../constants/endpoints";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import axios from "axios";
import { stringFormat } from "../../utils/stringHelpers";
import AlertDialog from "../../components/Dialog/AlertDialog";
const TokenExComponent = ({
  tokenConfig,
  cvvState,
  handleSubmit,
  tokenizerRef,
  validCard,
}) => {
  useImperativeHandle(tokenizerRef, () => ({
    validate() {
      triggerTokenizer();
    },
  }));
  const config = tokenConfig.iqProV2;
  const theme = useTheme();
  const { userSettings } = useContext(UserSettingsContext);
  const styling = {
    base:
      theme.palette.mode === "light"
        ? "border: none; height: 25px; padding: 0px; width: 100%; vertical-align: -webkit-baseline-middle; color: #666; font-weight: 400;"
        : "border: none; height: 25px; padding: 0px; background: inherit; width: 100%; vertical-align: -webkit-baseline-middle; color: white; font-weight: 400;",
    focus: "outline: none",
    placeholder: "color: rgb(186, 187, 189)",
    cvv: {
      base:
        theme.palette.mode === "light"
          ? "border: none; height: 25px; padding: 0px; width: 100%; vertical-align: -webkit-baseline-middle; color: #666; font-weight: 400;"
          : "border: none; height: 25px; padding: 0px; background: inherit; width: 100%; vertical-align: -webkit-baseline-middle; color: white; font-weight: 400;",
      focus: "outline: none",
      placeholder: "color: rgb(186, 187, 189)",
    },
  };
  const [tokenizer, setTokenizer] = useState(null);
  const [loadedToken, setLoadedToken] = useState(false);
  const [validForm, setValidForm] = useState(CARD_FORM_VALIDATION);
  const [exp, setExp] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const isLoading = useScript(process.env.REACT_APP_TOKENEX_URL);

  // Load TokenEx Script and pass events to our configuration
  useEffect(() => {
    if (!isLoading[0]) {
      /*global TokenEx*/
      const iframeConfig = setUpConfig(config);
      const tokenExPCIIframe = new TokenEx.Iframe(
        "iframe-container",
        iframeConfig,
      );
      if (!loadedToken) {
        setTokenizer(tokenExPCIIframe);
      }
      setLoadedToken(true);
    }
  }, [isLoading]);

  // Upon loaded configuration call IFrame to load
  useEffect(() => {
    if (loadedToken) {
      tokenizer.load();
      tokenizer.on("blur", function () {
        tokenizer.on("validate", function (data) {
          tokenizer.on("tokenize", function (result) {
            if (data.isValid && validCard) {
              validCard(result.token);
            } else if (validCard) {
              validCard(null);
            }
          });
        });
        tokenizer.tokenize();
      });

      tokenizer.on("expired", function () {
        //call api for new config
        reloadIFrame();
      });
      return () => {
        if (tokenizer) {
          tokenizer.remove();
        }
      };
    }
  }, [loadedToken]);

  const setUpConfig = (data) => {
    return {
      pci: true,
      inputType: "text", //Options are number, tel or text. The default is text"
      enablePrettyFormat: true,
      debug: false,
      placeholder: "0000 0000 0000 0000",
      allowUnknownCardTypes: false,
      styles: styling,
      //required
      origin: data.origin,
      timestamp: data.timestamp,
      tokenExID: data.tokenizationId,
      tokenScheme: data.tokenScheme,
      authenticationKey: data.authenticationKey,
      enableValidateOnBlur: false,
      ...(cvvState !== "hidden" && {
        cvv: true,
        cvvContainerID: "CvvTextbox",
        cvvPlaceholder: "CVV",
      }),
      customDataTypes: [
        {
          type: "txtCvvIframe",
          validRegex: "^[0-9]+$",
          possibleRegEx: "^[0-9]+$",
          maxLength: 16,
          ...(cvvState !== "hidden" && {
            cvvValidRegex: "^([0-9]{0,4})?$",
            cvvRequired: cvvState === "required",
            cvvMaxLength: 4,
          }),
          luhnCheck: true,
        },
      ],
    };
  };

  const triggerTokenizer = () => {
    tokenizer.on("validate", function (data) {
      setValidForm({
        ...validForm,
        exp: validateExp(),
        card: data.isValid,
        cvv:
          cvvState !== "hidden"
            ? cvvState === "required"
              ? data.isCvvValid
              : true
            : true,
      });
      const validPayment =
        data.isValid &&
        (cvvState !== "hidden"
          ? cvvState === "required"
            ? data.isCvvValid
            : true
          : true) &&
        validateExp();
      tokenizer.on("tokenize", function (res) {
        if (validPayment) {
          handleSubmit(res, exp);
        }
      });
      setAlertOpen(!validPayment);
    });
    tokenizer.tokenize();
  };

  const validateExp = () => {
    const currentYear = new Date().getFullYear() % 100;
    const expYear = Number(exp?.split("/")[1]);
    return (
      exp &&
      exp.length === 5 &&
      expYear >= currentYear &&
      expYear < currentYear + 10
    );
  };
  const changeExp = (data) => {
    setExp(data?.target?.value);
  };

  const getGatewayTokenConfig = () => {
    tokenizer.remove();
    axios
      .get(
        stringFormat(sassEndpoints.tokenizerConfig, [userSettings.gatewayId]),
      )
      .then((res) => {
        const iframeConfig = setUpConfig(res?.data?.data);
        const tokenExPCIIframe = new TokenEx.Iframe(
          "iframe-container",
          iframeConfig,
        );
        if (!loadedToken) {
          setTokenizer(tokenExPCIIframe);
        }
        setLoadedToken(true);
      });
  };

  const reloadIFrame = () => {
    setLoadedToken(false);
    getGatewayTokenConfig();
  };

  return (
    <>
      <AlertDialog
        alertOpen={isAlertOpen}
        alertTitle="Error"
        alertLevel="error"
        alertMessages={["Please fill in all required fields"]}
        closeButtonText="OK"
        closeButtonColor="primary"
        onCloseButtonClick={() => setAlertOpen(false)}
      />
      <div
        className={`${
          cvvState !== "hidden" ? "cvv" : "no-cvv"
        } tokenEx-card-wrapper`}
      >
        <div className={validForm.card ? "card" : "card invalid"}>
          <span className="form-label">Card Number *</span>
          <div className="card-row">
            <div className="card-wrapper">
              {" "}
              <CreditCardIcon color={"secondary"} />
              <div id="iframe-container"></div>
            </div>
          </div>
        </div>
        <div className={validForm.exp ? "secondary" : "secondary invalid"}>
          <span className="form-label">Exp *</span>
          <div style={{ display: "flex" }}>
            <input
              className="card-row exp"
              onKeyDown={correctInput}
              maxLength={5}
              onChange={changeExp}
              placeholder="MM/YY"
            />
          </div>
        </div>
        {cvvState !== "hidden" && (
          <div className={validForm.cvv ? "secondary" : "secondary invalid"}>
            <span className="form-label">CVV</span>
            <div className="card-row cvv" id="CvvTextbox"></div>
          </div>
        )}

        {/* <button type="button" onClick={() => reloadIFrame()}>
        test
      </button> */}
      </div>
    </>
  );
};

TokenExComponent.propTypes = {
  tokenConfig: PropTypes.object.isRequired,
  cvvState: PropTypes.string,
  handleSubmit: PropTypes.func,
  tokenizerRef: PropTypes.object,
  validCard: PropTypes.func,
};

export default TokenExComponent;
