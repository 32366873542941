import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import axios from "axios";
import "./gateway.scss";
import Switch from "../../ReactHookForm/Switch";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { stringFormat } from "../../../utils/stringHelpers";
import { sassEndpoints } from "../../../constants/endpoints";
import { Grid } from "@mui/material";
import {
  iqProVer,
  SYSTEM_PERMISSION_CODES,
  USER_ROLES,
} from "../../../constants/global";
import { userHasPermission } from "../../../utils/objectHelpers";

const GatewaySettings = ({
  gatewayInfo,
  showSnackbar,
  showError,
  setShowBackdrop,
}) => {
  const methods = useFormContext();
  const { userSettings } = useContext(UserSettingsContext);
  const [editField, setEditField] = useState(null);
  const [expandedAll, setExpandedAll] = useState(false);
  const [panel, setPanel] = useState([0]);

  let groups = {};
  gatewayInfo?.preference.forEach((obj) => {
    if (!Object.keys(groups).includes(obj.preferenceName)) {
      groups[obj.preferenceName] = [obj];
    } else {
      groups[obj.preferenceName].push(obj);
    }
  });

  const patchGateway = async (id, value, obj) => {
    setShowBackdrop(true);
    let payload = [
      {
        value: {
          Metadata: [
            {
              metadataID: id,
              metadataValue: value,
            },
          ],
        },
        path: "/",
        op: "replace",
      },
    ];
    let url = stringFormat(sassEndpoints.gateways.gateway, [
      gatewayInfo.gatewayId,
    ]);
    axios
      .patch(url, payload)
      .then(() => {
        showSnackbar("Updated settings.");
      })
      .catch(() => {
        showError("Failed to update settings.");
        methods.setValue(
          obj.metadataName,
          obj.metadataDataType === "System.Boolean"
            ? !value
            : obj.metadataValue,
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const renderSettingDiv = (key, obj) => {
    return (
      <>
        <Grid item xs={10}>
          <Grid container direction="column">
            {obj.metadataDataType !== "System.Boolean" ? (
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item style={{ maxWidth: "40%" }}>
                  <div>{`${obj.metadataDisplayName}: `}</div>
                </Grid>
                <Grid item>
                  <RegisteredTextField
                    name={obj.metadataName}
                    label=""
                    defaultValue={obj.metadataValue}
                    viewOnly={editField !== obj.metadataName}
                  />
                </Grid>
                {editField === obj.metadataName ? (
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      height="fit-content"
                      width="fit-content"
                      alignItems="center"
                      className="save-cancel-container"
                    >
                      <Grid item xs={6}>
                        <IconButton
                          id="save-setting"
                          title={"Save"}
                          variant="container"
                          onClick={() => {
                            patchGateway(
                              obj.metadataId,
                              methods.getValues()[obj.metadataName],
                              obj,
                            );
                            setEditField(null);
                          }}
                          color={"secondary"}
                        >
                          <CheckBoxIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton
                          id="save-setting"
                          title={"Save"}
                          variant="container"
                          onClick={() => {
                            methods.setValue(
                              obj.metadataName,
                              obj.metadataValue,
                            );
                            setEditField(null);
                          }}
                          sx={{ color: "#9e9e9e" }}
                        >
                          <DisabledByDefaultIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <Grid item>{obj.metadataDisplayName}</Grid>
            )}
            <Grid item className="gateway-settings-descrp">
              {obj.metadataName === "CUSTOMER_PAYABLE"
                ? "Allows or Disallows Invoice customer payable feature. Note: this is only valid if invoicing is enabled and gateway is on IQPROV2 tokenizer."
                : obj.metadataDescription}
            </Grid>
          </Grid>
        </Grid>
        {obj.metadataDataType === "System.Boolean" ? (
          <Grid item xs={2} className="gateway-switch">
            <Switch
              control={methods.control}
              label={""}
              disabled={
                (!userHasPermission(
                  SYSTEM_PERMISSION_CODES.edit,
                  userSettings,
                ) &&
                  userSettings?.userRole !== USER_ROLES.SUPER_USER) ||
                (obj.metadataName === "CUSTOMER_PAYABLE" &&
                  (groups["Gateway Tokenizer Settings"]?.find(
                    (obj) => obj.metadataName === "CARD_TOKENIZER",
                  )?.metadataValue === iqProVer.v1 ||
                    groups["Gateway Tokenizer Settings"]?.find(
                      (obj) => obj.metadataName === "ACH_TOKENIZER",
                    )?.metadataValue === iqProVer.v1))
              }
              labelPlacement="start"
              name={`${obj.metadataName}`}
              color="secondary"
              onChange={(e) => {
                patchGateway(obj.metadataId, e.target.checked, obj);
              }}
              defaultValue={obj.metadataValue.toLowerCase() === "true"}
            />
          </Grid>
        ) : editField === obj.metadataName ? (
          <></>
        ) : userHasPermission(SYSTEM_PERMISSION_CODES.edit, userSettings) ||
          userSettings?.userRole === USER_ROLES.SUPER_USER ? (
          <Grid item xs={2}>
            <IconButton
              id="edit-setting"
              title={"Edit"}
              variant="container"
              onClick={() => {
                if (editField !== null) {
                  methods.setValue(
                    editField,
                    groups[key].find((obj) => obj.metadataName === editField)
                      ?.metadataValue,
                  );
                }
                setEditField(obj.metadataName);
              }}
              color={"warning"}
              fontSize="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="gateway-settings">
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          sx={{ fontSize: "16px" }}
          color="secondary"
          className="expand-close-text"
        >
          {expandedAll ? "Close All" : "Expand All"}
        </Typography>
        <Button
          className="expand-all-button"
          onClick={() => {
            if (panel.length === Object.keys(groups).length) {
              setPanel([]);
            } else {
              setPanel(
                Array.from(
                  { length: Object.keys(groups).length },
                  (_, index) => index,
                ),
              );
            }
            setExpandedAll(!expandedAll);
          }}
        >
          {expandedAll ? "-" : "+"}
        </Button>
      </Grid>
      {Object.keys(groups).map((key, i) => {
        return (
          <Accordion key={i} expanded={expandedAll || panel.includes(i)}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => {
                    if (expandedAll || panel.includes(i)) {
                      setPanel(panel.filter((p) => p !== i));
                      setExpandedAll(false);
                    } else {
                      setPanel([i]);
                    }
                  }}
                />
              }
            >
              <Grid container direction="row" style={{ cursor: "default" }}>
                <Grid item xs={3} sm={1} md={1}>
                  <Typography color="neutrals" className="accordian-text">
                    {key}
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={11} md={11}>
                  <div className="divider"></div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <div>
              <p style={{ fontWeight: "700" }}>
                Any updates made on this page will be applied automatically.
              </p>
              {userHasPermission(SYSTEM_PERMISSION_CODES.edit, userSettings) ||
              userSettings?.userRole === USER_ROLES.SUPER_USER ? (
                groups[key].length < 6 ? (
                  groups[key].map((obj, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: "20px" }}
                        className="single-col-setting-div"
                      >
                        {renderSettingDiv(key, obj)}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={6}>
                      {groups[key]
                        .slice(0, Math.ceil(groups[key].length / 2))
                        .map((obj, i) => {
                          return (
                            <Grid
                              key={i}
                              container
                              direction="row"
                              sx={{ paddingTop: "20px" }}
                              justifyContent="space-between"
                            >
                              {renderSettingDiv(key, obj)}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {groups[key]
                        .slice(Math.ceil(groups[key].length / 2))
                        .map((obj, i) => {
                          return (
                            <Grid
                              key={i}
                              container
                              direction="row"
                              sx={{ paddingTop: "20px" }}
                              justifyContent="space-between"
                            >
                              {renderSettingDiv(key, obj)}
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                )
              ) : groups[key].filter((obj) => obj.isPublic).length < 6 ? (
                groups[key]
                  .filter((obj) => obj.isPublic)
                  .map((obj, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: "20px" }}
                        className="single-col-setting-div"
                      >
                        {renderSettingDiv(key, obj)}
                      </Grid>
                    );
                  })
              ) : (
                <Grid container spacing={2} direction="row">
                  <Grid item xs={12} sm={6}>
                    {groups[key]
                      .filter((obj) => obj.isPublic)
                      .slice(0, Math.ceil(groups[key].length / 2))
                      .map((obj, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            direction="row"
                            sx={{ paddingTop: "20px" }}
                            justifyContent="space-between"
                          >
                            {renderSettingDiv(key, obj)}
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {groups[key]
                      .filter((obj) => obj.isPublic)
                      .slice(Math.ceil(groups[key].length / 2))
                      .map((obj, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            direction="row"
                            sx={{ paddingTop: "20px" }}
                            justifyContent="space-between"
                          >
                            {renderSettingDiv(key, obj)}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              )}
            </div>
          </Accordion>
        );
      })}
    </div>
  );
};
GatewaySettings.propTypes = {
  gatewayInfo: PropTypes.any,
  showSnackbar: PropTypes.func,
  showError: PropTypes.func,
  setShowBackdrop: PropTypes.func,
};
export default GatewaySettings;
