import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import withStyles from "@mui/styles/withStyles";
import styles from "./style";
import "./header.scss";
import logo from "../../components/Drawer/iqPro-dark-mode.png";
import darkLogo from "../../components/Drawer/iqpro-logo-dark.svg";
const Header = (props) => {
  const { classes, onDrawerToggle, theme } = props;
  return (
    <AppBar
      className={classes.appBar}
      color="primary"
      position="fixed"
      elevation={0}
    >
      <Toolbar className={classes.topNav}>
        <Grid className="mobile-header" container alignItems="flex-end">
          <Grid item>
            <IconButton
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <div>
            <img src={theme.palette.mode === "light" ? darkLogo : logo} />
          </div>
          <Grid></Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func,
  handleClose: PropTypes.func,
  handleClick: PropTypes.func,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  history: PropTypes.object,
  userName: PropTypes.string,
  userRole: PropTypes.string,
  theme: PropTypes.object,
  colorMode: PropTypes.object,
  handleUserProfileToggle: PropTypes.func,
};

export default withStyles(styles)(Header);
