import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";
import AlertDialog from "../../../Dialog/AlertDialog";

const PreventNavigationDialog = ({ when, navigate, shouldBlockNavigation }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    if (confirmedNavigation) navigate(nextLocation);
  }, [confirmedNavigation]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setShowAlert(true);
      setNextLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigation = () => {
    if (nextLocation) setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <AlertDialog
        alertTitle="Are you sure?"
        alertLevel="info"
        alertMessages={[
          "If you continue any information you entered will be lost",
        ]}
        actionButtons={[
          {
            text: "Yes, Continue",
            color: "secondary",
            onclick: handleConfirmNavigation,
          },
        ]}
        closeButtonText="Cancel"
        closeButtonColor="secondary"
        closeButtonVariant="outlined"
        onCloseButtonClick={() => setShowAlert(false)}
        alertOpen={showAlert}
      />
    </>
  );
};

PreventNavigationDialog.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
};

export default PreventNavigationDialog;
