import React, { useEffect, useState, useContext } from "react";
import ManageUsers from "./ManageUsers";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import Button from "@mui/material/Button";
import ContentComponent from "../../Content/Content";
import "./manageUsers.scss";
import { useHistory } from "react-router";
import { partnerRoutes } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { USER_ROLES } from "../../../constants/global";
import SearchManager from "../../Search/SearchManager";
import { userSearchFields } from "./usersTableHelpers";

const ManageUsersContainer = () => {
  const [users, setUsers] = useState([]);
  const { userSettings } = useContext(UserSettingsContext);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [searchPayload, setSearchPayload] = useState(null);
  const [searchFields, setSearchFields] = useState(null);
  const [forceUpdateResults, setForceUpdateResults] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const history = useHistory();

  useEffect(() => {
    const copiedArr = structuredClone(userSearchFields);
    setSearchFields(copiedArr);
  }, []);
  const transformToUI = (data) => {
    return {
      username: data.email,
      name: data.firstName + " " + data.lastName,
      status: data.status,
      role: data.userRole === "SA" ? "System Admin" : "Super User",
      created: data.createdDateTime,
      user_id: data.userId,
    };
  };
  useEffect(() => {
    if (searchPayload !== null) {
      filteredUsers();
    }
  }, [searchPayload, resultsPerPage, forceUpdateResults]);

  const filteredUsers = async () => {
    let payload = structuredClone(searchPayload);
    payload.limit = resultsPerPage;
    payload.offset = resultOffset;
    payload.includeUserRole = true;
    if (payload.created_date_time) {
      payload.createdDate = {
        startDate: payload.created_date_time.startDate,
        endDate: payload.created_date_time.endDate,
        operator: "Between",
      };
      delete payload.created_date_time;
    }
    setShowBackdrop(true);
    axios
      .post(sassEndpoints.users.viewUser, payload)
      .then(function (response) {
        if (response.status === 204) {
          setResultsTotal(0);
          setUsers([]);
        } else {
          const users = response.data.data.results.map(function (data) {
            return transformToUI(data);
          });
          setResultsTotal(response?.data?.data?.rowCount || 0);
          setUsers(users || []);
        }
      })
      .catch(function (error) {
        if (error?.response?.data.length > 0) {
          showAlertDialogError(error?.response?.data);
        }
        showAlertDialogError("Failed to get USERS.");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const createUser = () => {
    history.push(`${partnerRoutes.manage.users_create}`);
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
    setForceUpdateResults(Date.now());
  };

  const searchUser = (form) => {
    setResultOffset(0);
    setSearchPayload(form);
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const createCopyUser = (row) => {
    let payload = {
      userId: {
        operator: "Equal",
        value: row.user_id,
      },
      includeUserPermissions: true,
      includeUserRole: true,
    };

    const url = `${sassEndpoints.users.viewUser}`;
    axios.post(url, payload).then(function (response) {
      const user = response.data.data.results[0];
      const copyUser = {
        role: user.userRole,
        permissions: user.userPermissions,
      };
      history.push({
        pathname: `${partnerRoutes.manage.users_create}`,
        state: { copyUser },
      });
    });
  };

  const deleteUser = (row) => {
    let url = `${sassEndpoints.users.user}/${row?.user_id}`;
    axios
      .delete(url)
      .then(function () {
        showSnackBar("Deleted User");
        filteredUsers();
      })
      .catch(function () {
        showAlertDialogError("Failed to delete user");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };
  const handleDeleteClick = (row) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting this user"],
      actionButtons: [
        {
          text: "Yes, delete user",
          color: "primary",
          onclick: () => {
            deleteUser(row);
            handleClosePromptDialog();
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: handleClosePromptDialog,
    });
    setAlertDialogOpen(true);
  };

  const handleClosePromptDialog = () => {
    setAlertDialogProps({});
    setAlertDialogOpen(false);
  };
  return (
    <div className="user-list">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="User List"
        headerContent={
          <div>
            {searchFields?.length > 0 && (
              <SearchManager
                searchButtonOnClick={searchUser}
                showAlertDialogError={showAlertDialogError}
                entitySearchFilterFields={searchFields}
                showSnackBar={showSnackBar}
                savedSearches={false}
                sectionName={"User List"}
              />
            )}
            {userSettings?.userRole === USER_ROLES.SUPER_USER && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => createUser()}
                data-cy="sys-user-create-user-button"
              >
                Create New User
              </Button>
            )}
          </div>
        }
        bodyContent={
          <div>
            <ManageUsers
              users={users.length > 0 ? users : null}
              handleDeleteClick={handleDeleteClick}
              createCopyUser={createCopyUser}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handleChange={handlePageChange}
            />
          </div>
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};

export default ManageUsersContainer;
