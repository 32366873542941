import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

/*
- Export Data Button Component -
Download button component, pass event handler, and types of files as props.
Supported files as of now are CSV and PDF.
*/

const DownloadButton = ({ handleSelection, types, disabledItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (type) => {
    handleSelection(type);
    handleClose();
  };

  const displayIcon = (type) => {
    switch (type) {
      case "CSV":
        return <InsertDriveFileIcon />;
      case "PDF":
        return <PictureAsPdfIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };
  return (
    <div className="download-button">
      <Button
        id="download-button"
        aria-controls={open ? "download-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenuClick}
        endIcon={<DownloadIcon />}
        size="small"
        variant="contained"
        color="secondary"
        sx={{
          position: "relative",
          zIndex: "1000",
        }}
        data-cy="download-button"
      >
        Download
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "download-button",
        }}
      >
        {types.map((type, i) => {
          return (
            <div key={i}>
              {disabledItems?.find((disabledItem) => {
                return disabledItem === type;
              }) ? (
                <Tooltip title="Can't download more than 100 records. Please change search or use another download option.">
                  <div>
                    <MenuItem
                      sx={{ paddingLeft: "16px", paddingRight: "26px" }}
                      onClick={() => handleClick(type)}
                      data-cy={`download-${type}`}
                      disabled={true}
                    >
                      {displayIcon(type)}

                      <span style={{ marginLeft: "15px" }}>{type}</span>
                    </MenuItem>
                  </div>
                </Tooltip>
              ) : (
                <MenuItem
                  sx={{ paddingLeft: "16px", paddingRight: "26px" }}
                  onClick={() => handleClick(type)}
                  data-cy={`download-${type}`}
                >
                  {displayIcon(type)}
                  <span style={{ marginLeft: "15px" }}>{type}</span>
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};
DownloadButton.propTypes = {
  handleSelection: PropTypes.func,
  types: PropTypes.array,
  disabledItems: PropTypes.array,
};
export default DownloadButton;
