import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReportingTransactionDetail from "./ReportingTransactionDetail";
import Content from "../../Content/Content";
import Button from "@mui/material/Button";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../../constants/endpoints";
import { useAxios } from "../../../hooks/useAxios";
import { stringFormat } from "../../../utils/stringHelpers";
import { useSnackbar } from "../../../hooks";
import { Link as RouterLink } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from "@mui/material";
import TabbedContactForm from "../InvoicesCreate/StepOne/TabbedContactForm";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { FormProvider, useForm } from "react-hook-form";
import * as mp from "../../../services/mixpanel/merchant/reportingTransactionsEvents";
import { merchantRoutes } from "../../../constants/routes";
import { DEFAULT_REQUIRED_FIELDS } from "../../../constants/global";
import IncreaseAuthDialog from "./IncreaseAuthDialog";

const ReportingTransactionDetailContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const methods = useForm();
  const { userSettings } = useContext(UserSettingsContext);

  const [showBackdrop, setShowBackdrop] = useState(true);
  const [transactionData, setTransactionData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const countries = useContext(CountriesContext);
  const location = useLocation();
  const [showIncreaseAuthDialog, setShowIncreaseAuthDialog] = useState(false);

  const requiredFields = {
    billing: {
      ...DEFAULT_REQUIRED_FIELDS,
      firstName: true,
      lastName: true,
      addressLine1: true,
      country: true,
      city: true,
      state: true,
      postalCode: true,
    },
    shipping: DEFAULT_REQUIRED_FIELDS,
  };

  useEffect(() => {
    if (location?.state?.emailCallFailed)
      showSnackbar("Failed to send receipt to email", "error");
  }, []);

  const loadTransaction = async (transactionId) => {
    setShowBackdrop(true);
    const url =
      stringFormat(sassEndpoints.transactions.transaction, [
        userSettings.gatewayId,
      ]) +
      "/" +
      transactionId;

    await axios
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          setTransactionData(response.data.data);
        }
      })
      .catch(function (error) {
        console.log("Failed to get transaction details", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const refundTransaction = async (transactionId, amount) => {
    setShowBackdrop(true);
    let outErrorMsg = null;
    const url = stringFormat(sassEndpoints.transactions.refund, [
      userSettings.gatewayId,
      transactionId,
    ]);

    await axios
      .post(url, { amount: amount })
      .then(function () {
        //Reload transaction
        loadTransaction(transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
      })
      .finally(function () {
        setShowBackdrop(false);
      });

    return outErrorMsg;
  };

  const captureTransaction = async (transactionId, amount) => {
    setShowBackdrop(true);
    let outErrorMsg = null;
    const url = stringFormat(sassEndpoints.transactions.capture, [
      userSettings.gatewayId,
      transactionId,
    ]);

    await axios
      .put(url, { amount: amount })
      .then(function () {
        //Reload transaction
        loadTransaction(transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return outErrorMsg;
  };

  const voidTransaction = async (transactionId) => {
    setShowBackdrop(true);
    let outErrorMsg = null;
    const url = stringFormat(sassEndpoints.transactions.void, [
      userSettings.gatewayId,
      transactionId,
    ]);

    await axios
      .put(url, {})
      .then(function () {
        //Reload transaction
        loadTransaction(transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return outErrorMsg;
  };

  const emailTransaction = async (transactionId, emailAddress) => {
    const url = stringFormat(sassEndpoints.transactions.emailReceipt, [
      userSettings.gatewayId,
      transactionId,
    ]);
    let payload = {
      recipient: {
        to: [emailAddress],
        name: "",
      },
      from: gatewayInfo.receiptEmail || "",
    };
    let outErrorMsg = null;

    setShowBackdrop(true);

    await axios
      .post(url, payload)
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
      })
      .finally(function () {
        setShowBackdrop(false);
      });

    return outErrorMsg;
  };

  const printTransaction = async (transactionId) => {
    let outErrorMsg = null;

    const url = stringFormat(sassEndpoints.transactions.receipt, [
      userSettings.gatewayId,
      transactionId,
    ]);

    setShowBackdrop(true);

    await axios
      .get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url, "receipt-" + transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
        return false;
      })
      .finally(function () {
        setShowBackdrop(false);
      });

    return outErrorMsg;
  };

  const handleVaultTransaction = (transactionId) => {
    const billingAddress = transactionData.addresses.find((a) => a.isBilling);

    if (
      Object.keys(requiredFields.billing)
        .filter((key) => requiredFields.billing[key])
        .every((key) => billingAddress[key]?.length > 0)
    )
      vaultTransaction(transactionId);
    else setShowAddressModal(true);
  };

  const mapAddress = (address, isBilling) => {
    if (!address) return null;

    // Only save address info if at least one value was changed
    let finalAddress = Object.entries(address).reduce((a, [key, value]) => {
      if (key !== "country" && value?.length) {
        if (!a) a = { isBilling, isShipping: !isBilling };
        a[key] = value;
      }

      return a;
    }, null);

    // Only save country if it was changed or if other values were provided
    if (
      finalAddress ||
      methods.getFieldState(`${isBilling ? "billing" : "shipping"}.country`)
        .isDirty ||
      (!isBilling && copyBillingAddress) ||
      transactionData.addresses.find((a) => a.isBilling === isBilling)?.country
    ) {
      if (!finalAddress) finalAddress = { isBilling, isShipping: !isBilling };
      finalAddress.country = address.country;
    }

    return finalAddress;
  };

  const vaultTransaction = (transactionId, addresses) => {
    setShowBackdrop(true);

    const customerAddresses = [];
    const billingAddress = mapAddress(addresses?.billing, true);
    const shippingAddress = mapAddress(addresses?.shipping, false);

    if (billingAddress) customerAddresses.push(billingAddress);
    if (shippingAddress) customerAddresses.push(shippingAddress);

    const url = stringFormat(sassEndpoints.transactions.vaultCustomer, [
      userSettings.gatewayId,
      transactionId,
    ]);

    axios
      .post(url, {
        customerName: "",
        description:
          "Customer vaulted from transaction with TransactionId: " +
          transactionId,
        addresses: customerAddresses,
      })
      .then((response) => {
        let mixpanelPayload = {
          page: "transaction details",
          transaction_id: transactionId,
          action_type: "vaulted",
        };

        showSnackbar(
          <>
            Vault Complete.
            <br />
            To access or modify customer information, please click{" "}
            <Link
              component={RouterLink}
              to={`${merchantRoutes.customer.base}/${response.data.data.customerId}`}
            >
              open vaulted customer
            </Link>
            .
          </>,
          "success",
        );

        mp.reportingTransactionsActionSuccess(mixpanelPayload);

        //Reload transaction
        loadTransaction(transactionId);
      })
      .catch((error) => {
        showSnackbar(
          `Vault request failed: ${error.response.data.statusDetails.join(
            ", ",
          )}`,
          "error",
        );
        mp.reportingTransactionsActionFailed({ page: "transaction details" });
      })
      .finally(function () {
        setShowBackdrop(false);
        setShowAddressModal(false);
      });
  };

  const increaseAuthorization = async (transactionId, amount) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.incrementAuth, [
      userSettings.gatewayId,
      transactionId,
    ]);

    try {
      await axios.put(url, { amount: amount });
      loadTransaction(transactionId);
      showSnackbar("Authorization increased successfully", "success");
    } catch (error) {
      showSnackbar(
        `Failed to increase authorization: ${error.response.data.statusDetails.join(
          ", ",
        )}`,
        "error",
      );
    } finally {
      setShowBackdrop(false);
    }
  };

  const handleProcessIncreaseAuth = (increaseAmount) => {
    increaseAuthorization(transactionData.transactionId, increaseAmount);
    setShowIncreaseAuthDialog(false);
  };

  useEffect(() => {
    loadTransaction(id);
  }, [id]);

  const { data: gatewayInfo, loading: isGatewayInfoLoading } = useAxios(
    {
      method: "GET",
      url: stringFormat(sassEndpoints.gateways.gateway, [
        userSettings.gatewayId,
      ]),
    },
    {
      dataTransform: (data) => data.data,
    },
  );

  const { data: gatewayConfiguration, loading: isGatewayConfigurationLoading } =
    useAxios(
      {
        method: "GET",
        url: stringFormat(sassEndpoints.gateways.configuration, [
          userSettings.gatewayId,
        ]),
      },
      {
        dataTransform: (data) => data.data,
      },
    );

  // For some reason the fields won't update unless I reset them like this
  const resetFields = (type) => {
    Object.keys(requiredFields.billing).forEach((key) => {
      methods.resetField(`${type}.${key}`, {
        defaultValue: key === "country" ? "US" : undefined,
      });
    });
  };

  const handleCopyChange = (_event, value, reset = true) => {
    const { billing } = methods.getValues();

    if (reset) resetFields("shipping");

    if (value)
      Object.keys(requiredFields.billing).forEach((key) => {
        methods.setValue(`shipping.${key}`, billing?.[key], {
          shouldDirty: false,
        });
      });

    setCopyBillingAddress(value);
  };

  const handleTabChange = (_event, activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <Content
        spinnerOpen={showBackdrop}
        title="Transaction Details"
        headerContent={
          <Button
            onClick={history.goBack}
            color="secondary"
            sx={{ fontWeight: 600 }}
          >
            &lt; Back to List
          </Button>
        }
        bodyContent={
          <>
            {transactionData !== null &&
              userSettings !== null &&
              !isGatewayInfoLoading &&
              !isGatewayConfigurationLoading && (
                <ReportingTransactionDetail
                  transactionData={transactionData}
                  voidTransaction={voidTransaction}
                  captureTransaction={captureTransaction}
                  refundTransaction={refundTransaction}
                  vaultTransaction={handleVaultTransaction}
                  emailTransaction={emailTransaction}
                  printTransaction={printTransaction}
                  userSettings={userSettings}
                  gatewayConfiguration={gatewayConfiguration}
                  onIncreaseAuthClick={() => setShowIncreaseAuthDialog(true)}
                />
              )}
            <Dialog
              open={showAddressModal}
              onClose={() => setShowAddressModal(false)}
              maxWidth="lg"
            >
              <DialogTitle>
                Address Information Required to Process Vault
              </DialogTitle>
              <DialogContent>
                <FormProvider {...methods}>
                  <form>
                    <TabbedContactForm
                      defaultValues={transactionData?.addresses}
                      activeTab={activeTab}
                      copyBillingAddress={copyBillingAddress}
                      countries={countries}
                      mode="create"
                      onCopyChange={handleCopyChange}
                      onTabChange={handleTabChange}
                      requiredFields={requiredFields}
                    />
                  </form>
                </FormProvider>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => setShowAddressModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={methods.handleSubmit((values) =>
                    vaultTransaction(transactionData.transactionId, values),
                  )}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {transactionData && (
              <IncreaseAuthDialog
                open={showIncreaseAuthDialog}
                onClose={() => setShowIncreaseAuthDialog(false)}
                transactionData={{
                  amountAuthorized: transactionData.remit.amountAuthorized,
                  tax: transactionData.remit.taxAmount,
                  paymentAdjustment:
                    transactionData.remit.paymentAdjustmentValue,
                  surcharge: transactionData.remit.surcharge,
                }}
                onProcess={handleProcessIncreaseAuth}
              />
            )}
          </>
        }
        snackbarProps={snackbarProps}
        snackbarOpen={snackbarOpen}
      />
    </>
  );
};

export default ReportingTransactionDetailContainer;
