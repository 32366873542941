import React from "react";
import { Grid } from "@mui/material";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import "./resetTos.scss";

function ResetTOS({ handleResetClick, tosTime }) {
  return (
    <div className="reset-page-container">
      <Grid container>
        <Grid item xs={12}>
          <div className="tos-header">
            <h2>Reset Terms of Service</h2>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="tos-time">
            <span>
              Last Reset On:{" "}
              {tosTime[0] + " " + "At" + " " + tosTime[1] + tosTime[2]}
            </span>
          </div>
        </Grid>

        <Grid item xs={10}>
          <RegisteredTextField
            id="reason"
            name={"reason"}
            label="Reason for Reset"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ alignItems: "flexStart", marginTop: "15px" }}
      >
        <Grid item md={2} xs={12}>
          <div className="tos-button">
            {" "}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleResetClick();
              }}
              data-cy="reset-tos-button"
            >
              {" "}
              <SettingsIcon /> <span>Reset TOS</span>
            </Button>
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <div className="permissions-notify">
            <InfoIcon
              color="info"
              sx={{
                fontSize: "1.5em",
              }}
            />
            <div className="permissions-copybody">
              <p>
                This will reset Terms of Service for all gateway users and will
                force each gateway user to accept TOS on their next login. This
                action cannot be undone.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
ResetTOS.propTypes = {
  handleResetClick: PropTypes.func,
  tosTime: PropTypes.array,
};

export default ResetTOS;
