import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tokenizer from "fluidpay-tokenizer";
import colors from "../assets/colors.module.scss";
import { useTheme } from "@mui/material/styles";

const getSettings = (paymentType, mode, showCvv) =>
  paymentType === "card"
    ? {
        payment: {
          types: ["card"],
          showTitle: false,
        },
        styles: {
          body: {
            color: mode === "light" ? colors.blue300 : colors.blue550,
          },
          ".card .fieldset": {
            "margin-bottom": "10px",
          },
          ".card .cc .cc-icon": {
            height: "60%",
            "padding-left": "2px",
            color: mode === "dark" && colors.light_blue500,
          },
          ".card .cc input": {
            "background-color": mode === "dark" && colors.grey850,
            color: mode === "dark" && colors.grey600,
            border: mode === "dark" && "1px solid" + colors.grey500,
          },
          ".card input.invalid": {
            color: "#ef5a00 !important",
            border: "solid 1px #ef5a00 !important",
          },
          ".card .cc input:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".card .exp input": {
            "background-color": mode === "dark" && colors.grey850,
            color: mode === "dark" && colors.grey600,
            border: mode === "dark" && "1px solid" + colors.grey500,
          },
          ".card .exp input:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".card .cvv": {
            display: showCvv ? "block" : "none",
          },
          ".card .cvv input": {
            "background-color": mode === "dark" && colors.grey850,
            color: mode === "dark" && colors.grey600,
            border: mode === "dark" && "1px solid" + colors.grey500,
          },
          ".card .cvv input:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".cc .fieldsetrow": {
            display: "flex",
            "flex-wrap": "wrap",
          },
          ".card .fieldsetrow .cc": {
            flex: "1 0 40%",
            "padding-right": "5px !important",
            order: "1",
          },
          ".card .fieldsetrow .exp": {
            flex: "1 0 40%",
            "padding-right": "5px !important",
            order: "2",
          },
          ".card .fieldsetrow .cvv": {
            flex: "1 0 40%",
            "padding-right": "5px !important",
            order: "3",
          },
          ".card .fieldset::before": {
            "line-height": "1em",
            padding: "0 0 7px 1px",
            display: "inline-block",
            "font-size": "16px",
            color: mode === "light" ? colors.grey900 : colors.grey600,
          },
          ".card .cc::before": {
            content: '"Card Number *"',
            "font-size": "14px",
          },
          ".card .exp::before": {
            content: '"Exp *"',
            "font-size": "14px",
          },
          ".card .cvv::before": {
            content: '"CVV"',
            "font-size": "14px",
          },
        },
      }
    : {
        payment: {
          types: ["ach"],
          ach: {
            sec_code: "ppd",
            secCodeList: ["web", "ppd", "ccd", "tel"],
            showSecCode: true,
          },
          showTitle: false,
        },
        styles: {
          ".ach .fieldset": {
            "margin-bottom": "10px",
          },
          ".ach .fieldsetrow": {
            display: "flex",
            "flex-wrap": "wrap",
          },
          ".ach .fieldsetgroup": { width: "100%" },
          ".ach .fieldsetrow .account": {
            flex: "1 0 40%",
            "padding-right": "5px !important",
            order: "1",
          },
          ".ach .fieldsetrow .routing": {
            flex: "1 0 40%",
            order: "2",
          },
          ".ach .fieldsetrow .type": {
            flex: "1 0 40%",
            "padding-right": "5px !important",
            order: "3",
          },
          ".ach .fieldsetrow .sec-code": {
            flex: "1 0 40%",
            order: "4",
          },
          ".ach .account input": {
            "background-color": mode === "dark" && colors.grey850,
            color: mode === "dark" && colors.grey600,
            border: mode === "dark" && "1px solid" + colors.grey500,
          },
          ".ach .account input:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".ach .routing input": {
            "background-color": mode === "dark" && colors.grey850,
            color: mode === "dark" && colors.grey600,
            border: mode === "dark" && "1px solid" + colors.grey500,
          },
          ".ach input.invalid": {
            color: "#ef5a00 !important",
            border: "solid 1px #ef5a00 !important",
          },
          ".ach .fieldset::before": {
            "line-height": "1em",
            padding: "0 0 7px 1px",
            display: "inline-block",
            "font-size": "16px",
            color: mode === "light" ? colors.grey900 : colors.grey600,
          },
          ".ach .routing input:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".ach .type select": {
            "background-color": mode === "dark" && colors.grey850,

            padding: "5px 5px 5px 10px",
          },
          ".ach .type::before": {
            content: '"Account Type"',
            "font-size": "14px",
          },
          ".ach .type select:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".ach .sec-code select": {
            "background-color": mode === "dark" && colors.grey850,

            padding: "5px 5px 5px 10px",
          },
          ".ach .sec-code select:focus": {
            border:
              mode === "light"
                ? `2px solid ${colors.blue550}`
                : `2px solid ${colors.blue300}`,
          },
          ".ach .sec-code::before": {
            content: '"SEC Code"',
            "font-size": "14px",
          },
          ".ach .account::before": {
            content: '"Account Number *"',
            "font-size": "14px",
          },
          ".ach .routing::before": {
            content: '"Routing Number *"',
            "font-size": "14px",
          },
          ".ach .account input::placeholder,.ach .routing input::placeholder": {
            color: "white",
          },
        },
      };

export const useTokenizer = ({
  paymentType,
  container,
  apikey,
  onLoad,
  onSubmit,
  onAchChange,
  onMagStripeSwipe,
  onValidCard,
  showCvv = true,
}) => {
  const [tokenizer, setTokenizer] = useState();

  const theme = useTheme();
  useEffect(() => {
    if (apikey && !tokenizer) {
      setTokenizer(
        new Tokenizer({
          apikey: apikey,
          url: process.env.REACT_APP_FP_BASE_URL,
          container: document.querySelector(container),
          onLoad: () => onLoad && onLoad(),
          submission: (resp) => onSubmit && onSubmit(resp),
          achOnChange: (data) => onAchChange && onAchChange(data),
          magStripeSwipe: (data) => onMagStripeSwipe && onMagStripeSwipe(data),
          validCard: (card) => onValidCard && onValidCard(card),
          settings: getSettings(paymentType, theme.palette.mode, showCvv),
        }),
      );
    }
  }, [
    apikey,
    container,
    onAchChange,
    onMagStripeSwipe,
    onLoad,
    onSubmit,
    paymentType,
    tokenizer,
  ]);

  return tokenizer;
};

useTokenizer.propTypes = {
  paymentType: PropTypes.oneOf(["card", "ach"]).isRequired,
  container: PropTypes.string.isRequired,
  apikey: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func,
  onAchChange: PropTypes.func,
  onMagStripeSwipe: PropTypes.func,
  onValidCard: PropTypes.func,
};

export default useTokenizer;
