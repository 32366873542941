import { sassFilterOperators } from "../../Search/searchHelpers";
const generalGroup = "General";
const billingGroup = "Billing";
const shippingGroup = "Shipping";

const transactionSearchFilterFields_array = [
  /* Future Story
  {
    group: generalGroup,
    displayName: "Global Search",
    fieldName: "search_in_everything",
    operators: ["like", "is not like"],
    fieldType: "text",
    selected: false,
  },
  */
  /* General */
  {
    displayName: generalGroup,
    fieldType: "group",
  },
  {
    group: generalGroup,
    displayName: "Amount",
    fieldName: "amount",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "currency",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Amount Authorized",
    fieldName: "amountAuthorized",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "currency",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Amount Captured",
    fieldName: "amountCaptured",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "currency",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Amount Settled",
    fieldName: "amountSettled",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "currency",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Captured Date",
    fieldName: "capturedDateTime",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
  // {
  //   group: generalGroup,
  //   displayName: "CC Digest",
  //   fieldName: "cc_digest",
  //   operators: [filterOperators.is, filterOperators.isLike],
  //   fieldType: "text",
  //   selected: false,
  // },
  // {
  //   group: generalGroup,
  //   displayName: "Created Date",
  //   fieldName: "created_at.duration",
  //   operators: [filterOperators.since],
  //   fieldType: "date_since",
  //   selected: false,
  // },
  {
    group: generalGroup,
    displayName: "Created Date Range",
    fieldName: "createdDateTime",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: true,
    savedValues: {
      operator: "Between",
      value:
        new Date(Date.now() - 86400000).toISOString().split("T")[0] + //calculates to yesterday
        " to " +
        new Date().toISOString().split("T")[0],
    },
  },
  {
    group: generalGroup,
    displayName: "Credit Card First 6",
    fieldName: "creditCardFirst6",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "numeric",
    selected: false,
    maxLength: 6,
  },
  {
    group: generalGroup,
    displayName: "Credit Card Last 4",
    fieldName: "creditCardLast4",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "numeric",
    selected: false,
    maxLength: 4,
  },
  {
    group: generalGroup,
    displayName: "Customer Id",
    fieldName: "customerId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Invoice Id",
    fieldName: "invoiceId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "IP Address",
    fieldName: "ipAddress",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  // {
  //   group: generalGroup,
  //   displayName: "Order Id",
  //   fieldName: "order_id",
  //   operators: [filterOperators.is, filterOperators.notEquals],
  //   fieldType: "text",
  //   selected: false,
  // },
  // {
  //   group: generalGroup,
  //   displayName: "Payment Type",
  //   fieldName: "payment_type",
  //   operators: [filterOperators.is, filterOperators.notEquals],
  //   fieldType: "payment_type",
  //   selected: false,
  // },
  {
    group: generalGroup,
    displayName: "PO Number",
    fieldName: "poNumber",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Id",
    fieldName: "processorId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Name",
    fieldName: "processorName",
    operators: [
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Referenced Transaction ID",
    fieldName: "referenceTransactionId",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Settled Date",
    //found data with status not settled
    fieldName: "settledDateTime",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Settlement Batch Id",
    fieldName: "settlementBatchId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Status",
    fieldName: "status",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "transaction_status",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Tax Amount",
    fieldName: "taxAmount",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "currency",
    selected: false,
  },
  // {
  //   group: generalGroup,
  //   displayName: "Terminal Description",
  //   fieldName: "terminal_description",
  //   operators: [
  //     filterOperators.is,
  //     filterOperators.notEquals,
  //     filterOperators.isLike,
  //   ],
  //   fieldType: "text",
  //   selected: false,
  // },
  // {
  //   group: generalGroup,
  //   displayName: "Terminal Id",
  //   fieldName: "terminal_id",
  //   operators: [filterOperators.is, filterOperators.notEquals],
  //   fieldType: "text",
  //   selected: false,
  // },
  {
    group: generalGroup,
    displayName: "Transaction Id",
    fieldName: "transactionId",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Transaction Source",
    fieldName: "source",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "transaction_source",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Type",
    fieldName: "type",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "transaction_type",
    selected: false,
  },
  // {
  //   group: generalGroup,
  //   displayName: "Updated Date",
  //   fieldName: "updated_at",
  //   operators: [filterOperators.between],
  //   fieldType: "date_range",
  //   selected: false,
  // },
  {
    group: generalGroup,
    displayName: "User Account",
    fieldName: "userId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Username",
    fieldName: "userName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  /* Billing */
  {
    displayName: billingGroup,
    fieldType: "group",
  },
  {
    group: billingGroup,
    displayName: "Billing Address Id",
    fieldName: "billingAddressId",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Address Line 1",
    fieldName: "billingAddressLine1",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Address Line 2",
    fieldName: "billingAddressLine2",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing City",
    fieldName: "billingCity",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Company",
    fieldName: "billingCompany",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Country",
    fieldName: "billingCountry",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "country",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Email",
    fieldName: "billingEmail",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Fax",
    fieldName: "billingFax",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "phone",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing First Name",
    fieldName: "billingFirstName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Last Name",
    fieldName: "billingLastName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Phone",
    fieldName: "billingPhone",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "phone",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing Postal Code",
    fieldName: "billingPostalCode",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: billingGroup,
    displayName: "Billing State",
    fieldName: "billingState",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "state",
    selected: false,
  },
  /* Shipping */
  {
    displayName: shippingGroup,
    fieldType: "group",
  },
  {
    group: shippingGroup,
    displayName: "Shipping Address Id",
    fieldName: "shippingAddressId",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Address Line 1",
    fieldName: "shippingAddressLine1",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Address Line 2",
    fieldName: "shippingAddressLine2",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping City",
    fieldName: "shippingCity",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Company",
    fieldName: "shippingCompany",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Country",
    fieldName: "shippingCountry",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "country",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Email",
    fieldName: "shippingEmail",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Fax",
    fieldName: "shippingFax",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "phone",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping First Name",
    fieldName: "shippingFirstName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Last Name",
    fieldName: "shippingLastName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Phone",
    fieldName: "shippingPhone",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "phone",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping Postal Code",
    fieldName: "shippingPostalCode",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
      sassFilterOperators.isLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: shippingGroup,
    displayName: "Shipping State",
    fieldName: "shippingState",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "state",
    selected: false,
  },
];

export const transactionSearchFilterFields = () => {
  return transactionSearchFilterFields_array;
};

export const quickActionButtons = [
  {
    fieldName: "createdDateTime",
    search: {
      operator: "between",
      value:
        new Date(new Date().setDate(new Date().getDate() - 1))
          .toISOString()
          .split("T")[0] +
        " to " +
        new Date().toISOString().split("T")[0],
    },
    title: "Today's Transactions",
  },
  {
    fieldName: "createdDateTime",
    search: {
      operator: "between",
      value:
        new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0] +
        " to " +
        new Date().toISOString().split("T")[0],
    },
    title: "Last 30 Days",
  },
  {
    fieldName: "status",
    search: {
      operator: "Equal",
      value: "Settled",
    },
    title: "Settled",
  },
  {
    fieldName: "status",
    search: {
      operator: "Equal",
      value: "PendingSettlement",
    },
    title: "Pending Settlement",
  },
];
