import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../ReactHookForm/Select";
import Switch from "../../../ReactHookForm/Switch";
import { useFormContext } from "react-hook-form";
import { validationRules } from "../../../../constants/validationRules";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { useLocation } from "react-router";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import { partnerRoutes } from "../../../../constants/routes";
const UserCreate = ({ roleDetails, createUser, onError, methods }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const location = useLocation();
  const history = useHistory();
  const handleChange = () => {};
  return (
    <div className="create-detail-wrapper system-user">
      <div className="create-header">
        <h2>User Info & Permissions</h2>
      </div>
      <div className="create-details-body">
        <div className="form-elements">
          <RegisteredTextField
            id="email"
            name={"email"}
            label="Email"
            fullWidth
            autoComplete="email"
            errors={errors}
            rules={{
              pattern: validationRules.email,
              maxLength: validationRules.maxLength100,
              required: true,
            }}
          />
          <div className="create-name-fields">
            {" "}
            <RegisteredTextField
              id="first_name"
              name={"first_name"}
              label="First Name"
              fullWidth
              errors={errors}
              rules={{
                pattern: regexPatterns.name,
                required: true,
                maxLength: validationRules.maxLength100,
              }}
            />
            <RegisteredTextField
              id="last_name"
              name={"last_name"}
              label="Last Name"
              errors={errors}
              fullWidth
              rules={{
                pattern: regexPatterns.name,
                required: true,
                maxLength: validationRules.maxLength100,
              }}
            />
          </div>
          {roleDetails?.length > 0 && (
            <Select
              control={control}
              name="role"
              fullWidth
              id="role"
              defaultValue={
                roleDetails.find((r) => {
                  return r.code === location?.state?.copyUser?.role;
                })?.roleId || roleDetails[0].roleId
              }
              label="Role"
              onChange={handleChange}
            >
              {roleDetails.map((role, i) => {
                return (
                  <MenuItem key={i} value={role.roleId}>
                    {role.description}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          <Grid className="system-user-btn-row desktop">
            <Button
              variant="contained"
              color="neutrals"
              size="small"
              onClick={() => history.push(partnerRoutes.manage.users)}
              sx={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(createUser, onError)}
            >
              Submit
            </Button>
          </Grid>
        </div>
        <div className="create-permissions-wrapper">
          <Switch
            control={control}
            label="Create Gateway"
            labelPlacement="start"
            name="create_gateway"
            defaultValue={
              location?.state?.copyUser?.permissions?.find((p) => {
                return p.code === "CG";
              }) || false
            }
          />
          <Switch
            control={control}
            label="Edit Gateway"
            labelPlacement="start"
            name="edit_gateway"
            defaultValue={
              location?.state?.copyUser?.permissions?.find((p) => {
                return p.code === "EG";
              }) || false
            }
          />
          <Switch
            control={control}
            label="Edit Gateway Status"
            labelPlacement="start"
            name="edit_gateway_status"
            defaultValue={
              location?.state?.copyUser?.permissions?.find((p) => {
                return p.code === "EGS";
              }) || false
            }
          />
        </div>
      </div>
      <Grid className="system-user-btn-row mobile">
        <Button
          variant="contained"
          color="neutrals"
          onClick={() => history.push(partnerRoutes.manage.users)}
          sx={{ sm: { marginBottom: "10px" } }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!methods.formState.isValid}
          onClick={methods.handleSubmit(createUser, onError)}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

UserCreate.propTypes = {
  roleDetails: PropTypes.array,
  createUser: PropTypes.func,
  onError: PropTypes.func,
  methods: PropTypes.object,
};

export default UserCreate;
