import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { WEBHOOK_TYPES_ENUMS } from "../../constants/global";
import Tooltip from "@mui/material/Tooltip";
import WebhooksEditOrCreate from "./WebhooksEditOrCreate";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationCreate from "./ApplicationCreate";

const ListCard = ({
  type,
  data,
  webhooksData,
  setCreateWebhook,
  createWebhook,
  createApp,
  setCreateApp,
  displayMessages,
  setIsAlertOpen,
  setAlertDialogProps,
  editCard,
  setEditCard,
  manageApp,
  setManageApp,
  callPutWebhook,
  callDeleteApplication,
  callCreateApplication,
  callCreateSecret,
  callDeleteSecret,
}) => {
  const [transactionCount, setTransactionCount] = useState(0);
  const [settlementCount, setSettlementCount] = useState(0);
  const [invoiceCount, setinvoiceCount] = useState(0);
  const [copyClick, setCopyClick] = useState(false);

  useEffect(() => {
    if (type === "webhooks") {
      setTransactionCount(
        data?.filter((obj) => Object.keys(obj)[0] === "Transaction").length ||
          0,
      );
      setSettlementCount(
        data?.filter((obj) => Object.keys(obj)[0] === "Settlement").length || 0,
      );
      setinvoiceCount(
        data?.filter((obj) => Object.keys(obj)[0] === "Invoice").length || 0,
      );
    }
  }, [data]);

  const handleEditWebhook = (i, obj) => {
    setEditCard({
      index: i,
      type: Object.keys(obj)[0],
      url: Object.values(obj)[0],
    });
  };

  const handleEditApplication = (i) => {
    setManageApp({ index: i });
  };

  useEffect(() => {
    if (createWebhook && editCard !== null) {
      setEditCard(null);
    }
  }, [createWebhook]);

  useEffect(() => {
    if (createWebhook && editCard !== null) {
      setCreateWebhook(false);
    }
  }, [editCard]);

  const CardObject = ({
    type,
    data,
    i,
    displayMessages,
    callPutWebhook,
    setAlertDialogProps,
    transactionCount,
    settlementCount,
    invoiceCount,
    copyClick,
    setCopyClick,
  }) => {
    const { ...methods } = useForm();
    const { control } = methods;

    const typeValue = useWatch({
      control,
      name: "type",
      defaultValue: editCard !== null && data ? Object.keys(data)[0] : "",
    });
    const [urlValue, setUrlValue] = useState(
      editCard !== null && data ? Object.values(data)[0] : null,
    );
    const [webhookType, setWebhookType] = useState(null);
    const [webhookFormError, setWebhookFormError] = useState(true);
    const [applicationFormError, setApplicationFormError] = useState(true);
    const [applicationName, setApplicationName] = useState("");
    const [typeError, setTypeError] = useState(true);
    const [urlError, setUrlError] = useState(true);
    const [roleType, setRoleType] = useState(null);

    useEffect(async () => {
      if (applicationName.length > 0) {
        let noErrors = await methods.trigger("applicationName");
        if (noErrors && roleType !== null) {
          setApplicationFormError(false);
        } else {
          setApplicationFormError(true);
        }
      }
    }, [roleType, applicationName]);

    useEffect(async () => {
      if (urlValue !== null) {
        let noErrors = await methods.trigger("URL");
        if (noErrors) {
          setUrlError(false);
        } else {
          setUrlError(true);
        }
      }
    }, [urlValue]);

    useEffect(() => {
      if (
        (webhookType === "Transaction" &&
          transactionCount === 5 &&
          (!data || (data && Object.keys(data)[0] !== "Transaction"))) ||
        (webhookType === "Settlement" &&
          settlementCount === 5 &&
          (!data || (data && Object.keys(data)[0] !== "Settlement"))) ||
        (webhookType === "Invoice" &&
          invoiceCount === 5 &&
          (!data || (data && Object.keys(data)[0] !== "Invoice")))
      ) {
        setTypeError(true);
      } else {
        setTypeError(false);
      }
    }, [typeValue]);

    useEffect(() => {
      if (typeError || urlError || typeValue.length === 0) {
        setWebhookFormError(true);
      } else {
        setWebhookFormError(false);
      }
    }, [typeError, urlError, typeValue]);

    const onSubmit = () => {
      if (type === "webhooks") {
        let methodsTypeUrlList =
          webhooksData?.filter(
            (g) =>
              g?.name?.toLowerCase() ===
              methods.getValues()?.type?.toLowerCase(),
          )[0]?.fields[0]?.value || [];

        //create
        if (editCard === null) {
          let payload = {
            categoryCode: "webhooks",
            fieldEntryValues: [
              {
                fieldCode: WEBHOOK_TYPES_ENUMS[methods.getValues()?.type],
                fieldValue: [...methodsTypeUrlList, methods.getValues()?.URL],
              },
            ],
          };

          callPutWebhook("create", payload);
        } else {
          //edit
          let payload = {
            categoryCode: "webhooks",
            fieldEntryValues: [],
          };

          if (editCard.type !== methods.getValues()?.type) {
            let oldTypeUrls =
              webhooksData?.filter(
                (g) => g?.name?.toLowerCase() === editCard?.type?.toLowerCase(),
              )[0]?.fields[0]?.value || [];
            const urlIndex = oldTypeUrls.indexOf(editCard?.url);
            if (urlIndex > -1) {
              oldTypeUrls.splice(urlIndex, 1);
            }

            payload.fieldEntryValues = [
              {
                fieldCode: WEBHOOK_TYPES_ENUMS[editCard.type],
                fieldValue: oldTypeUrls,
              },
              {
                fieldCode: WEBHOOK_TYPES_ENUMS[methods.getValues()?.type],
                fieldValue: [...methodsTypeUrlList, methods.getValues()?.URL],
              },
            ];
          } else {
            let urlIndex = methodsTypeUrlList.indexOf(editCard?.url);
            methodsTypeUrlList.splice(urlIndex, 1);

            methodsTypeUrlList.push(methods.getValues()?.URL);

            payload.fieldEntryValues = [
              {
                fieldCode: WEBHOOK_TYPES_ENUMS[editCard.type],
                fieldValue: methodsTypeUrlList,
              },
            ];
          }
          callPutWebhook("edit", payload);
        }
      } else {
        let payload = {
          displayName: methods.getValues("applicationName"),
          roleId: methods.getValues("role"),
        };
        callCreateApplication(payload);
      }
    };

    const handleClickDelete = (data) => {
      setAlertDialogProps({
        alertLevel: "info",
        alertMessages:
          type === "application"
            ? [
                "Are you sure you want to delete this application? Deleting it will break any integrations you have configured. ",
              ]
            : [
                " Are you sure you want to delete this webhook? Upon deleting, you will no longer receive data to the URL you configured.",
              ],
        actionButtons: [
          {
            text: "Yes, Delete ",
            color: "primary",
            onclick: () => {
              if (type === "application") {
                callDeleteApplication(data?.appRegistrationId);
              } else {
                handleDeleteWebhook(data);
              }
              setIsAlertOpen(false);
            },
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: () => {
          setIsAlertOpen(false);
        },
      });
      setIsAlertOpen(true);
    };

    const handleDeleteWebhook = (obj) => {
      let typeUrlList = webhooksData.filter(
        (g) => g?.name?.toLowerCase() === Object.keys(obj)[0]?.toLowerCase(),
      )[0]?.fields[0]?.value;
      let urlIndex = typeUrlList.indexOf(Object.values(obj)[0]);
      typeUrlList.splice(urlIndex, 1);

      let payload = {
        categoryCode: "webhooks",
        fieldEntryValues: [
          {
            fieldCode: WEBHOOK_TYPES_ENUMS[Object.keys(obj)[0]],
            fieldValue: typeUrlList,
          },
        ],
      };
      callPutWebhook("delete", payload);
    };

    const onError = () => {
      displayMessages(["Please correct required form values"]);
    };

    return (
      <FormProvider {...methods}>
        {!(
          data &&
          type === "application" &&
          manageApp &&
          manageApp?.index !== i
        ) && (
          <Card
            key={i}
            className={
              type === "application" && (createApp || manageApp)
                ? "application-edit-create"
                : ""
            }
          >
            <CardHeader
              action={
                data ? (
                  type === "webhooks" && editCard?.index === i ? (
                    <div className="btn-row">
                      <Button
                        variant="contained"
                        sx={{ marginRight: "10px" }}
                        className="secondary-medium-button"
                        onClick={() => {
                          setEditCard(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="primary-medium-button"
                        disabled={webhookFormError}
                        onClick={methods.handleSubmit(onSubmit, onError)}
                      >
                        Update
                      </Button>
                    </div>
                  ) : type === "application" && manageApp?.index === i ? (
                    <Button
                      className="delete-button"
                      variant="outlined"
                      onClick={() => {
                        handleClickDelete(data);
                      }}
                    >
                      Delete Application
                    </Button>
                  ) : type === "application" ? (
                    <>
                      <Button
                        className="delete-button"
                        style={{ marginRight: "16px" }}
                        variant="outlined"
                        onClick={() => {
                          handleClickDelete(data);
                        }}
                      >
                        Delete Application
                      </Button>
                      <Button
                        id={i}
                        onClick={() => {
                          handleEditApplication(i);
                        }}
                        variant="contained"
                        color="secondary"
                        className="primary-medium-button"
                      >
                        Manage
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="delete-button"
                        variant="outlined"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          handleClickDelete(data);
                        }}
                      >
                        Delete Webhook
                      </Button>
                      <Button
                        id={i}
                        onClick={() => {
                          handleEditWebhook(i, data);
                        }}
                        variant="outlined"
                        className="secondary-medium-button"
                        disabled={createWebhook}
                      >
                        Edit
                        <DriveFileRenameOutlineIcon />
                      </Button>
                    </>
                  )
                ) : (
                  type === "webhooks" && (
                    <div className="btn-row">
                      <Button
                        variant="contained"
                        className="secondary-medium-button"
                        sx={{ marginRight: "10px" }}
                        onClick={() => {
                          setCreateWebhook(false);
                          methods.setValue("URL", "", "type", null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="primary-medium-button"
                        disabled={webhookFormError}
                        onClick={methods.handleSubmit(onSubmit, onError)}
                      >
                        Create
                      </Button>
                    </div>
                  )
                )
              }
              title={
                data ? (
                  type === "application" ? (
                    manageApp?.index === i ? (
                      <IconButton
                        aria-label="Back to List"
                        className="back-arrow"
                        sx={{ fontSize: "1rem" }}
                        onClick={() => {
                          setManageApp(false);
                        }}
                      >
                        <ChevronLeftIcon sx={{ fontSize: "1.75rem" }} />
                        <span>Back To List</span>
                      </IconButton>
                    ) : (
                      data?.microsoftDisplayName
                    )
                  ) : editCard?.index === i ? (
                    <Button
                      className="delete-button"
                      variant="outlined"
                      style={{ padding: "6.5px 16px" }}
                      onClick={() => {
                        handleClickDelete(data);
                      }}
                    >
                      Delete Webhook
                    </Button>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="webhook-url">
                        {Object.values(data)[0]}
                      </div>
                      <Tooltip
                        title={
                          copyClick === i ? "Copied!" : "Copy Url to clipboard"
                        }
                      >
                        <IconButton>
                          <FileCopyIcon
                            aria-label="copy-url"
                            style={{
                              cursor: "pointer",
                              color: "#189AD0",
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                Object.values(data)[0],
                              );
                              setCopyClick(i);
                            }}
                          ></FileCopyIcon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                ) : type === "application" ? (
                  <IconButton
                    aria-label="Back to List"
                    className="back-arrow"
                    sx={{ fontSize: "1rem" }}
                    onClick={() => setCreateApp(false)}
                  >
                    <ChevronLeftIcon sx={{ fontSize: "1.75rem" }} />
                    <span>Back To List</span>
                  </IconButton>
                ) : (
                  <div className="create-webhook-title">Create New Webhook</div>
                )
              }
            ></CardHeader>

            <CardContent>
              {data ? (
                type === "webhooks" ? (
                  editCard?.index !== i ? (
                    <Typography variant="body2" color="text.secondary">
                      {Object.keys(data)[0]}
                    </Typography>
                  ) : (
                    <Grid container flexDirection={"column"} rowSpacing={3}>
                      {type === "webhooks" && (
                        <WebhooksEditOrCreate
                          data={data}
                          editCard={editCard}
                          webhookType={webhookType}
                          setWebhookType={setWebhookType}
                          typeError={typeError}
                          setUrlValue={setUrlValue}
                        />
                      )}
                    </Grid>
                  )
                ) : type === "application" ? (
                  manageApp?.index !== i ? (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        Client ID: {data?.microsoftAppId}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Role: {data?.role?.description}
                      </Typography>
                    </>
                  ) : (
                    <ApplicationDetails
                      data={data}
                      setAlertDialogProps={setAlertDialogProps}
                      setIsAlertOpen={setIsAlertOpen}
                      callCreateSecret={callCreateSecret}
                      callDeleteSecret={callDeleteSecret}
                    />
                  )
                ) : (
                  <></>
                )
              ) : type === "webhooks" ? (
                <Grid container flexDirection={"column"} rowSpacing={3}>
                  <WebhooksEditOrCreate
                    data={data}
                    editCard={editCard}
                    webhookType={webhookType}
                    setWebhookType={setWebhookType}
                    typeError={typeError}
                    setUrlValue={setUrlValue}
                  />
                </Grid>
              ) : (
                <ApplicationCreate
                  setCreateApp={setCreateApp}
                  roleType={roleType}
                  setRoleType={setRoleType}
                  onSubmit={onSubmit}
                  onError={onError}
                  applicationFormError={applicationFormError}
                  setApplicationName={setApplicationName}
                />
              )}
            </CardContent>
          </Card>
        )}
      </FormProvider>
    );
  };
  return (
    <div className="list-card-container">
      {(createWebhook || createApp) && (
        <CardObject
          type={type}
          displayMessages={displayMessages}
          setIsAlertOpen={setIsAlertOpen}
          callPutWebhook={callPutWebhook}
          transactionCount={transactionCount}
          settlementCount={settlementCount}
          invoiceCount={invoiceCount}
        />
      )}
      {!createApp &&
        data &&
        data.map((d, i) => {
          return (
            <CardObject
              key={i}
              type={type}
              data={d}
              i={i}
              displayMessages={displayMessages}
              setAlertDialogProps={setAlertDialogProps}
              transactionCount={transactionCount}
              settlementCount={settlementCount}
              invoiceCount={invoiceCount}
              copyClick={copyClick}
              setCopyClick={setCopyClick}
              callPutWebhook={callPutWebhook}
              callDeleteApplication={callDeleteApplication}
            />
          );
        })}
    </div>
  );
};

ListCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  webhooksData: PropTypes.object,
  i: PropTypes.number,
  setCreateWebhook: PropTypes.func,
  createWebhook: PropTypes.bool,
  createApp: PropTypes.bool,
  setCreateApp: PropTypes.func,
  displayMessages: PropTypes.func,
  setIsAlertOpen: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
  transactionCount: PropTypes.number,
  settlementCount: PropTypes.number,
  invoiceCount: PropTypes.number,
  editCard: PropTypes.number,
  setEditCard: PropTypes.func,
  manageApp: PropTypes.obj,
  setManageApp: PropTypes.func,
  copyClick: PropTypes.boolean,
  setCopyClick: PropTypes.func,
  callPutWebhook: PropTypes.func,
  callDeleteApplication: PropTypes.func,
  callCreateApplication: PropTypes.func,
  callCreateSecret: PropTypes.func,
  callDeleteSecret: PropTypes.func,
};

export default ListCard;
