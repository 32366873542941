import { useFormContext } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";

import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import PhoneNumber from "../../../Contact/PhoneNumber";
import Switch from "../../../ReactHookForm/Switch";
import { convertPropertyToName } from "../../../../utils/stringHelpers";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { validationRules } from "../../../../constants/validationRules";
import MailIcon from "@mui/icons-material/Mail";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../../constants/routes";
import { Grid } from "@mui/material";
import {
  USER_ROLES,
  USER_STATUS,
  iqProVer,
} from "../../../../constants/global";
import Tooltip from "@mui/material/Tooltip";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
import { useContext } from "react";

function ManageUsersDetail({
  permissions,
  userData,
  resendEmail,
  roles,
  viewState,
  changeView,
  editGatewayUser,
}) {
  const tokenConfig = useContext(TokenizerConfigContext);
  const [statuses, setStatuses] = useState([]);
  const { control, errors, setValue } = useFormContext();
  const history = useHistory();

  useEffect(() => {
    if (userData) {
      setAvailableStatus(userData.user.status);
      setValue("user_status", userData.user.status);
    }
  }, [userData]);

  const handlePermissions = (property) => {
    let found = false;
    userData.gatewayUserPermissions.forEach((p) => {
      if (p.code === property.code) {
        found = true;
      }
    });
    return found;
  };

  const setAvailableStatus = (state) => {
    if (state === USER_STATUS.pending) {
      setStatuses([USER_STATUS.pending, USER_STATUS.disabled]);
    } else {
      setStatuses([USER_STATUS.disabled, USER_STATUS.active]);
    }
  };

  const gatewayUserStatus = ["Active", "Disabled"];

  return (
    <div className="gateway-user-detail">
      <div className="user-details-wrapper">
        <div className="details-section">
          <div className="gateway-user-header">
            <h2>User Info</h2>
            <IconButton
              title={"Edit"}
              variant="container"
              sx={{ padding: 0.5 }}
              onClick={() => {
                changeView();
              }}
              color={"warning"}
              fontSize="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="form-row">
            <RegisteredTextField
              id="email"
              name="email"
              label="Email"
              defaultValue={userData?.user.email}
              fullWidth
              viewOnly={true}
            />
          </div>
          <Grid container>
            <Grid
              sx={{
                marginBottom: "10px",
                marginRight: { sm: "10px" },
              }}
              item
              xs={12}
              sm={3}
            >
              <RegisteredTextField
                id="firstname"
                name="firstname"
                label="First Name"
                errors={errors}
                defaultValue={userData?.user.firstName}
                fullWidth
                rules={{
                  pattern: regexPatterns.name,
                  required: true,
                  maxLength: validationRules.maxLength100,
                }}
                viewOnly={viewState}
              />
            </Grid>
            <Grid
              sx={{
                marginBottom: "10px",
                marginRight: { sm: "10px" },
              }}
              item
              xs={12}
              sm={3}
            >
              <RegisteredTextField
                id="lastname"
                name="lastname"
                label="Last Name"
                defaultValue={userData?.user.lastName}
                fullWidth
                errors={errors}
                rules={{
                  pattern: regexPatterns.name,
                  required: true,
                  maxLength: validationRules.maxLength100,
                }}
                viewOnly={viewState}
              />
            </Grid>{" "}
            <Grid
              sx={{
                marginBottom: "10px",
                marginRight: { sm: "10px" },
              }}
              item
              xs={12}
              sm={3}
            >
              <PhoneNumber
                name="phone"
                id="phone"
                label="Phone"
                errors={errors}
                defaultValue={userData?.phone}
                required={true}
                viewOnly={viewState}
              />
            </Grid>
          </Grid>
          {statuses.length > 0 && (
            <div className="form-row">
              <Grid
                item
                sx={{
                  marginBottom: "10px",
                }}
                xs={12}
                sm={6}
              >
                <Select
                  control={control}
                  name="gateway_user_status"
                  label="Gateway User Status"
                  fullWidth
                  errors={errors}
                  rules={{ required: true }}
                  viewOnly={viewState}
                  defaultValue={userData.gatewayUserStatus}
                  data-cy="gateway-user-status-select"
                >
                  {gatewayUserStatus.map((status, i) => {
                    return (
                      <MenuItem key={i} value={status}>
                        <span style={{ textTransform: "capitalize" }}>
                          {status}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </div>
          )}
          <div className="form-row">
            <Grid item xs={12} sm={6}>
              <Select
                control={control}
                name="role"
                fullWidth
                id="role"
                viewOnly={viewState}
                defaultValue={userData?.role.roleId}
                label="Role"
              >
                {roles
                  .filter(
                    (role) =>
                      role.code === USER_ROLES.GATEWAY_STANDARD ||
                      role.code === USER_ROLES.GATEWAY_ADMIN,
                  )
                  .map((role, i) => {
                    return (
                      <MenuItem key={i} value={role.roleId}>
                        {role.code === USER_ROLES.GATEWAY_STANDARD
                          ? "Standard"
                          : "Admin"}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          </div>
        </div>
        {permissions && (
          <div className="permissions-section">
            <h3>Permissions</h3>
            <div
              className="toggle-container"
              data-cy="gateway-user-permissions"
            >
              <ul>
                {permissions?.map((property, i) => {
                  if (
                    property?.code?.toLowerCase() !== "vault_read" &&
                    property?.code?.toLowerCase() !== "manage_users" &&
                    property?.code?.toLowerCase().slice(0, 3) !== "pro" &&
                    !(
                      (property?.code?.toLowerCase() ===
                        "manage_cust_payable_token" ||
                        property?.code?.toLowerCase() ===
                          "view_cust_payable_token") &&
                      (tokenConfig.achTokenizer === iqProVer.v1 ||
                        tokenConfig.cardTokenizer === iqProVer.v1)
                    )
                  ) {
                    return (
                      <li key={i}>
                        <Switch
                          control={control}
                          label={convertPropertyToName(
                            property?.code?.toLowerCase(),
                          )}
                          labelPlacement="start"
                          name={"permissions." + property?.code?.toLowerCase()}
                          defaultValue={handlePermissions(property)}
                          disabled={viewState}
                        />
                      </li>
                    );
                  }
                })}
              </ul>
              <ul>
                {permissions?.map((property, i) => {
                  if (property?.code?.toLowerCase().slice(0, 3) === "pro") {
                    return (
                      <li key={i}>
                        <Switch
                          control={control}
                          label={convertPropertyToName(
                            property?.code?.toLowerCase(),
                          )}
                          labelPlacement="start"
                          name={"permissions." + property?.code?.toLowerCase()}
                          defaultValue={handlePermissions(property)}
                          disabled={viewState}
                        />
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}
        <div className="button-row">
          <Tooltip title="Copy over permissions and role to new user.">
            <Button
              className="copy-btn"
              variant="contained"
              onClick={() =>
                history.push({
                  pathname: merchantRoutes.manage.users_create,
                  state: {
                    copiedPermissions: {
                      permissions: userData.gatewayUserPermissions,
                      role: userData.role.roleId,
                    },
                  },
                })
              }
              color="secondary"
              data-cy="sys-copy-user-button"
            >
              <ContentCopyIcon />
              Copy User Permissions
            </Button>
          </Tooltip>
          {userData.user.status === USER_STATUS.pending && (
            <Button
              variant="contained"
              onClick={() => resendEmail(userData.user.userId)}
              color="secondary"
              data-cy="resend-welcome-button"
            >
              <MailIcon />
              Resend Welcome Email
            </Button>
          )}
          {!viewState && (
            <Grid className="form-buttons" container sx={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                color="neutrals"
                size="small"
                onClick={() => history.push(merchantRoutes.manage.users)}
                sx={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                data-cy="gateway-view-submit"
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => editGatewayUser()}
              >
                Submit
              </Button>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

ManageUsersDetail.propTypes = {
  permissions: PropTypes.array,
  userData: PropTypes.object,
  resendEmail: PropTypes.func,
  roles: PropTypes.array,
  viewState: PropTypes.bool,
  changeView: PropTypes.func,
  editGatewayUser: PropTypes.func,
};

export default ManageUsersDetail;
