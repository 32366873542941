import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Drawer from "./Drawer";
import DescriptionIcon from "@mui/icons-material/Description";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import GavelIcon from "@mui/icons-material/Gavel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoopIcon from "@mui/icons-material/Loop";
import BackupIcon from "@mui/icons-material/Backup";
import { withRouter } from "react-router";
import { merchantRoutes, partnerRoutes } from "../../constants/routes";
import { generalError } from "../../constants/routes";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GroupIcon from "@mui/icons-material/Group";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Settings from "@mui/icons-material/Settings";
import { isValidRole, isGatewayUser, isSystemUser } from "../../utils/user";
import { USER_ROLES } from "../../constants/global";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { GatewayContext } from "../../contexts/GatewayContext";
import {
  TerminalIcon,
  ReportingIcon,
  InvoiceIcon,
  CustomerIcon,
  ManageIcon,
  HelpIcon,
  HubIcon,
} from "./DrawerIcons/GatewayIcons";
import { SystemIcon, SystemManageIcon } from "./DrawerIcons/SystemIcons";

const getCategories = (userRole) => {
  let categories = [];
  const { userSettings } = useContext(UserSettingsContext);
  const gateway = useContext(GatewayContext);

  if (isGatewayUser(userSettings)) {
    categories = [
      {
        id: "Dashboard",
        icon: <HomeIcon />,
        route: merchantRoutes.dashboard,
        isVisible: false,
      },
      {
        id: "Terminal",
        icon: <TerminalIcon />,
        route: merchantRoutes.virtual_terminal,
        isVisible: true,
      },
      {
        id: "Reporting",
        icon: <ReportingIcon />,
        isVisible: true,
        children: [
          {
            id: "Transactions",
            route: merchantRoutes.reporting.transactions,
            isVisible: true,
          },

          {
            id: "Settlements",
            route: merchantRoutes.reporting.settlements,
            isVisible:
              userSettings?.gatewayUserPermissions["VIEW_SETTLEMENT_BATCHES"],
          },
          {
            id: "Billing Reports",
            route: merchantRoutes.reporting.billing,
            isVisible: false,
          },
        ],
      },
      {
        id: "Customers",
        icon: <CustomerIcon />,
        route: merchantRoutes.customer.base,
        isVisible: true,
      },
      {
        id: "Invoices",
        icon: <InvoiceIcon />,
        isVisible:
          gateway.gatewaySettings
            .find((p) => p.code === "ALLOW_INVOICING")
            ?.value.toLowerCase() === "true" &&
          Object.entries(userSettings.gatewayUserPermissions).some(
            ([permission, hasPermission]) =>
              hasPermission && permission.toUpperCase().includes("INVOICE"),
          ),
        route: merchantRoutes.invoices.base,
        // children: [
        //   {
        //     id: "Create",
        //     route: merchantRoutes.invoices.create,
        //     isVisible: false,
        //   },
        //   {
        //     id: "Search",
        //     route: merchantRoutes.invoices.search,
        //     isVisible: false,
        //   },
        // ],
      },
      {
        id: "Shopping",
        icon: <ShoppingCartIcon />,
        isVisible: false,
        children: [
          {
            id: "Carts",
            route: merchantRoutes.shopping.carts,
            isVisible: false,
          },
          {
            id: "Products",
            route: merchantRoutes.shopping.products,
            isVisible: false,
          },
          {
            id: "Documentation",
            route: merchantRoutes.shopping.documentation,
            isVisible: false,
          },
        ],
      },
      {
        id: "Recurring Billing",
        icon: <LoopIcon />,
        isVisible: false,
        children: [
          {
            id: "Plans",
            route: merchantRoutes.recurring_billing.plans,
            isVisible: false,
          },
          {
            id: "Addons",
            route: merchantRoutes.recurring_billing.addons,
            isVisible: false,
          },
          {
            id: "Discounts",
            route: merchantRoutes.recurring_billing.discounts,
            isVisible: false,
          },
          {
            id: "Subscriptions",
            route: merchantRoutes.recurring_billing.subscriptions,
            isVisible: false,
          },
        ],
      },
      {
        id: "File Batch",
        icon: <BackupIcon />,
        isVisible: false,
        children: [
          {
            id: "Upload History",
            route: merchantRoutes.file_batch.upload_history,
            isVisible: false,
          },
          {
            id: "Documentation",
            route: merchantRoutes.file_batch.documentation,
            isVisible: false,
          },
        ],
      },
      {
        id: "Manage",
        icon: <ManageIcon />,
        isVisible: userRole === USER_ROLES.GATEWAY_ADMIN,
        children: [
          {
            id: "Terminal",
            route: merchantRoutes.manage.terminal,
            isVisible: false,
          },
          {
            id: "Users",
            route: merchantRoutes.manage.users,
            isVisible: true,
          },
          {
            id: "IqProtect",
            route: merchantRoutes.manage.iqprotect,
            isVisible: false,
          },
          {
            id: "Custom Fields",
            route: merchantRoutes.manage.custom_fields.search,
            isVisible: true,
          },
          {
            id: "CardBans",
            route: merchantRoutes.manage.cardbans,
            isVisible: false,
          },
          {
            id: "Settings",
            route: merchantRoutes.manage.settings,
            isVisible: true,
          },
        ],
      },
      {
        id: "Developer Hub",
        icon: <HubIcon />,
        isVisible: userRole === USER_ROLES.GATEWAY_ADMIN,
        children: [
          {
            id: "Applications",
            route: merchantRoutes.developer_hub.applications,
            isVisible: true,
          },
          {
            id: "Webhooks",
            route: merchantRoutes.developer_hub.webhooks,
            isVisible: true,
          },
        ],
      },
      {
        id: "Help",
        icon: <HelpIcon />,
        isVisible: true,
        children: [
          {
            id: "Contact Us",
            route: merchantRoutes.help.contact_us,
            isVisible: true,
          },
          {
            id: "Terms of Service",
            route: merchantRoutes.help.terms_of_service,
            isVisible: true,
          },
        ],
      },
    ];
  } else if (isSystemUser(userRole)) {
    categories = [
      {
        id: "Main",
        children: [
          {
            id: "Dashboard",
            icon: <HomeIcon />,
            route: partnerRoutes.dashboard,
          },
        ],
      },
      {
        id: "Gateways",
        icon: <SystemIcon />,
        route: partnerRoutes.gateway,
        isVisible: true,
      },
      {
        id: "Types of Merchants",
        children: [
          {
            id: "Partners",
            icon: <GroupIcon />,
            route: partnerRoutes.partners,
          },

          {
            id: "Merchants",
            icon: <StorefrontIcon />,
            route: partnerRoutes.merchants,
          },
        ],
      },
      {
        id: "Billing",
        children: [
          {
            id: "Reports",
            icon: <AssessmentIcon />,
            route: partnerRoutes.billing.reports,
          },
          {
            id: "Fee Schedule",
            icon: <ScheduleIcon />,
            route: partnerRoutes.billing.fee_schedule,
          },
          {
            id: "Commissions",
            icon: <AttachMoneyIcon />,
            route: partnerRoutes.billing.commissions,
          },
        ],
      },
      {
        id: "Sales",
        children: [
          {
            id: "Calculator",
            icon: <ReceiptIcon />,
            route: partnerRoutes.sales.calculator,
          },
        ],
      },
      {
        id: "Payment History",
        children: [
          {
            id: "Transactions",
            icon: <LocalAtmIcon />,
            route: partnerRoutes.transactions,
          },
        ],
      },
      {
        id: "Manage",
        isVisible: true,
        icon: <SystemManageIcon />,
        children: [
          {
            id: "Settings",
            icon: <Settings />,
            route: partnerRoutes.manage.settings,
            isVisible: false,
          },
          {
            id: "Users",
            icon: <GroupAddIcon />,
            route: partnerRoutes.manage.users,
            isVisible: true,
          },
          {
            id: "Terms of Service",
            icon: <GroupAddIcon />,
            route: partnerRoutes.help.terms_of_service,
            isVisible: userRole === USER_ROLES.SUPER_USER,
          },
          {
            id: "Rules",
            icon: <GavelIcon />,
            route: partnerRoutes.manage.rules,
          },
        ],
      },
      {
        id: "Help",
        icon: <HelpIcon />,
        isVisible: true,
        children: [
          {
            id: "Developer Hub",
            icon: <DescriptionIcon />,
            route: partnerRoutes.help.developer_hub,
          },
          {
            id: "Contact Us",
            icon: <ContactSupportIcon />,
            route: partnerRoutes.help.contact_us,
          },
          {
            id: "Terms of Service",
            route: partnerRoutes.help.current_tos,
            isVisible: true,
          },
        ],
      },
    ];
  }
  return categories;
};

const DrawerContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { history, staticContext, userRole, handleLogout, ...rest } = props;

  useEffect(() => {
    if (!isValidRole(userRole)) {
      history.push(generalError);
    }
  }, [userRole]);

  const handleClick = (route) => {
    history.push(route);
  };

  return (
    <Drawer
      handleClick={handleClick}
      handleLogout={handleLogout}
      categories={getCategories(userRole)}
      {...rest}
    />
  );
};

DrawerContainer.propTypes = {
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
  location: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withRouter(DrawerContainer);
