import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ContentComponent from "../../../Content/Content";
import { basysEndpoints, sassEndpoints } from "../../../../constants/endpoints";
import { useSnackbar } from "../../../../hooks";
import ManageUserDetail from "./ManageUserDetails";
import { FormProvider, useForm } from "react-hook-form";
import { merchantRoutes } from "../../../../constants/routes";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { RolesAndPermissionContext } from "../../../../contexts/RolesAndPermissionContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useHistory, useLocation } from "react-router";
import { stringFormat } from "../../../../utils/stringHelpers";

const ManageUserDetailsContainer = () => {
  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { rolesAndPermissions } = useContext(RolesAndPermissionContext);
  const { ...methods } = useForm();
  const [userData, setUserData] = useState(null);
  const [loadUser, setLoadUser] = useState(false);

  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [edited, setEdited] = useState(false);

  const [viewEditMode, setViewEditMode] = useState(!location?.state?.editMode);
  useEffect(() => {
    getUserData(id);
  }, []);

  useEffect(() => {
    if (edited) {
      getUserData(id, edited);
    }
  }, [edited]);

  const getUserData = (id, edited) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [userSettings.gatewayId]) +
      "/" +
      id +
      "?isSystemUserId=false";
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 204) {
          showAlertDialogError("Failed to get gateway details");
        }
        setUserData(response.data.data);
        if (edited) {
          let newUserSettings = structuredClone(userSettings);
          newUserSettings.phone = response.data.data.phone;
          newUserSettings.user.firstName = response.data.data.firstName;
          newUserSettings.user.LastName = response.data.data.LastName;
          newUserSettings.user.gatewayUserStatus =
            response.data.data.gatewayUserStatus;
          newUserSettings.user.role = response.data.data.role;
          let permissionsObject =
            response.data.data.gatewayUserPermissions.reduce(function (
              perms,
              p,
            ) {
              perms[p.code] = true;
              return perms;
            }, {});
          newUserSettings.gatewayUserPermissions = permissionsObject;
          setUserSettings(newUserSettings);
        }
        setEdited(false);
        setLoadUser(true);
      })
      .catch(function (error) {
        showAlertDialogError("Failed to get gateway details");
        setShowBackdrop(false);
        console.log("Failed to get gateway details", error);
      });
  };

  const editGatewayUser = async () => {
    let triggerList = ["firstname", "lastname", "gateway_user_status", "phone"];

    //Is true when there are no errors
    let errorVer = await methods.trigger(triggerList);
    if (Object.keys(methods.formState.errors).length === 0 && errorVer) {
      const payload = assemblePayload();

      let url =
        stringFormat(sassEndpoints.gatewayUsers.user, [
          userSettings.gatewayId,
        ]) +
        "/" +
        id +
        "?isSystemUserId=false";
      setShowBackdrop(true);
      axios
        .put(url, payload)
        .then(function () {
          showSnackbar("Success");
          setViewEditMode(true);
          if (id === userSettings.gatewayUserId) {
            setEdited(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          showAlertDialogError(error?.response?.data);
        })
        .finally(function () {
          setShowBackdrop(false);
        });
    } else {
      showAlertDialogError([
        "Please correct validation errors highlighted in red",
      ]);
    }
  };

  const resendEmail = (userId) => {
    let url = `${basysEndpoints.users}/${userId}/invite`;
    axios
      .get(url)
      .then(() => {
        showSnackbar("Invite resent");
      })
      .catch((err) => {
        showAlertDialogError("Failed to resend invite", err);
      });
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const USER_STATUS_CODES = {
    InvitePending: 0,
    Pending_TOS: 1,
    Active: 2,
    Disabled: 3,
  };

  const handlePayloadPermissions = (permissionsList) => {
    let permissionCodes = [];
    Object.keys(permissionsList).forEach((name) => {
      if (permissionsList[name]) {
        let foundP = rolesAndPermissions.permissions.find((p) => {
          return p.code.toLowerCase() === name;
        });
        if (foundP) {
          permissionCodes.push(foundP.permissionId);
        }
      }
    });
    return permissionCodes;
  };

  const assemblePayload = () => {
    const payload = {
      gatewayUserStatus: methods.getValues().gateway_user_status,
      firstName: methods.getValues().firstname,
      lastName: methods.getValues().lastname,
      userStatus: USER_STATUS_CODES[methods.getValues().user_status],
      roleId: methods.getValues().role,
      phone: methods.getValues().phone,
      permissionIds: handlePayloadPermissions(methods.getValues().permissions),
    };
    return payload;
  };

  const toggleView = () => {
    setViewEditMode(!viewEditMode);
  };

  return (
    <FormProvider {...methods}>
      <form className="gateway-manage-users">
        <ContentComponent
          spinnerOpen={showBackdrop}
          useFixedWidth={false}
          title="Manage User"
          headerContent={
            <div>
              <Tooltip title="Return to List">
                <IconButton
                  variant="container"
                  color="secondary"
                  fontSize="small"
                  sx={{ fontSize: "16px", marginLeft: "-15px" }}
                  onClick={() => history.push(merchantRoutes.manage.users)}
                >
                  <ChevronLeftIcon />
                  <span>Back To List</span>
                </IconButton>
              </Tooltip>
            </div>
          }
          bodyContent={
            <>
              <div>
                {" "}
                {loadUser && rolesAndPermissions.roles && (
                  <div>
                    <ManageUserDetail
                      permissions={rolesAndPermissions.permissions}
                      userData={userData}
                      resendEmail={resendEmail}
                      roles={rolesAndPermissions.roles}
                      viewState={viewEditMode}
                      changeView={toggleView}
                      editGatewayUser={editGatewayUser}
                    />
                  </div>
                )}
              </div>
            </>
          }
          snackbarOpen={snackbarOpen}
          snackbarProps={snackbarProps}
          alertDialogOpen={alertDialogOpen}
          alertDialogProps={alertDialogProps}
        />
      </form>
    </FormProvider>
  );
};

export default ManageUserDetailsContainer;
