import React, { useState, useEffect } from "react";
import { roundToEven } from "./transactionHelpers";
import { formatTransactionCurrency } from "../../../utils/stringHelpers";
import { DialogContent, Typography, Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import { useFormContext, useWatch } from "react-hook-form";

const IncreaseAuthForm = ({ transactionData, setError }) => {
  const [tax, setTax] = useState(0);
  const [surcharge, setSurcharge] = useState(0);
  const [paymentAdjustment, setPaymentAdjustment] = useState(0);
  const [error, setErrorState] = useState("");

  const methods = useFormContext();
  const originalAmount = parseFloat(transactionData.amountAuthorized) || 0;

  const increase = useWatch({
    control: methods.control,
    name: "increaseAmount",
    defaultValue: 0,
  });

  const total = useWatch({
    control: methods.control,
    name: "totalAmount",
    defaultValue: originalAmount * 100,
  });

  useEffect(() => {
    let increaseAmount = increase / 100 || 0;
    let totalAmount = total / 100 || 0;

    const newTotalAmount = originalAmount + increaseAmount;

    if (transactionData.tax) {
      setTax(
        roundToEven((transactionData.tax / originalAmount) * newTotalAmount),
      );
    }
    if (transactionData.surcharge) {
      setSurcharge(
        roundToEven(
          (transactionData.surcharge / originalAmount) * newTotalAmount,
        ),
      );
    }
    if (transactionData.paymentAdjustment) {
      setPaymentAdjustment(
        roundToEven(
          (transactionData.paymentAdjustment / originalAmount) * newTotalAmount,
        ),
      );
    }

    if (increaseAmount <= 0 && totalAmount <= originalAmount) {
      setErrorState("Amount to Increase must be greater than zero.");
      setError("Amount to Increase must be greater than zero.");
    } else {
      setErrorState("");
      setError("");
    }
  }, [increase, total, transactionData, setError, originalAmount]);

  useEffect(() => {
    const totalAmount = originalAmount + increase / 100;
    methods.setValue("totalAmount", (totalAmount * 100).toFixed(0));
  }, [increase, originalAmount, methods]);

  useEffect(() => {
    const increaseAmount = total / 100 - originalAmount;
    methods.setValue("increaseAmount", (increaseAmount * 100).toFixed(0));
  }, [total, originalAmount, methods]);

  const handleKeyDown = (e) => {
    let currentValue = methods.getValues("increaseAmount");
    let parsedValue = Number.parseFloat(currentValue);

    if (e.key === "ArrowUp" && parsedValue >= 0.0) {
      currentValue = parsedValue + 100;
    }

    if (e.key === "ArrowDown" && parsedValue > 0.0) {
      currentValue = parsedValue - 100;
    }

    methods.setValue("increaseAmount", currentValue);
  };

  const handleChange = (e) => {
    let currentValue = methods.getValues("totalAmount");
    let parsedValue = Number.parseFloat(currentValue);

    if (e.key === "ArrowUp" && parsedValue >= 0.0) {
      currentValue = parsedValue + 100;
    }

    if (
      e.key === "ArrowDown" &&
      parsedValue > transactionData.amountAuthorized * 100
    ) {
      currentValue = parsedValue - 100;
    }

    methods.setValue("totalAmount", currentValue);
  };

  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" padding="15px 15px" gap="10px">
        <Box display="flex" width="100%">
          <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
            <Typography variant="body1" align="right">
              Original Amount:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              {formatTransactionCurrency(originalAmount)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pr={2}
          >
            <Typography variant="body1" fontWeight="bold" align="right">
              Amount to increase:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-start" ml={1}>
            <FormattedTextField
              type="auth"
              name="increaseAmount"
              onKeyDown={handleKeyDown}
              highlightError={increase <= 0}
            />
          </Box>
        </Box>
        <Divider sx={{ margin: "10px 0", backgroundColor: "#ccc" }} />
        {transactionData.tax > 0 && (
          <Box display="flex" width="100%">
            <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
              <Typography variant="body1" align="right">
                Tax:
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Typography variant="body1">
                {formatTransactionCurrency(tax)}
              </Typography>
            </Box>
          </Box>
        )}
        {transactionData.surcharge > 0 && (
          <Box display="flex" width="100%">
            <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
              <Typography variant="body1" align="right">
                Surcharge:
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Typography variant="body1">
                {formatTransactionCurrency(surcharge)}
              </Typography>
            </Box>
          </Box>
        )}
        {transactionData.paymentAdjustment > 0 && (
          <Box display="flex" width="100%">
            <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
              <Typography variant="body1" align="right">
                Payment Adjustment:
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Typography variant="body1">
                {formatTransactionCurrency(paymentAdjustment)}
              </Typography>
            </Box>
          </Box>
        )}
        <Box display="flex" width="100%">
          <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
            <Typography variant="h6" align="right">
              Total Amount:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormattedTextField
              type="auth"
              name="totalAmount"
              onKeyDown={handleChange}
              highlightError={total <= originalAmount * 100}
              defaultValue={originalAmount * 100}
            />
          </Box>
        </Box>
        {error && (
          <Typography
            variant="body2"
            color="error"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </DialogContent>
  );
};

IncreaseAuthForm.propTypes = {
  transactionData: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default IncreaseAuthForm;
