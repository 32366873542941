/* eslint-disable prettier/prettier */
import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Link,
  Collapse,
  Button,
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import Select from "../../../ReactHookForm/Select";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import SelectCustomerDrawer from "./SelectCustomerDrawer";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useIsInitialRender } from "../../../../hooks";
import { validationRules } from "../../../../constants/validationRules";
import TabbedContactForm from "./TabbedContactForm";
import { DEFAULT_REQUIRED_FIELDS } from "../../../../constants/global";

const InvoiceStepOne = ({
  countries,
  onPageChange,
  copyBillingAddress,
  setCopyBillingAddress,
  vaultCustomer,
  setVaultCustomer,
  showVaultCustomer,
  customer,
  onCustomerSelect,
  invoice,
  mode,
  onEdit,
  onEditEnter,
  onEditFail,
}) => {
  const methods = useForm();
  const isInitialRender = useIsInitialRender();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeTab: 0,
      requireBillingInfo: true,
      requireShippingInfo: invoice?.requireShippingInfo || false,
      searchCustomer: false,
      displayCustomerWarning: false,
      addAdditionalRecipients:
        (invoice?.invoiceNotification &&
          (invoice?.invoiceNotifications[0]?.cc.length > 0 ||
            invoice?.invoiceNotifications[0]?.bcc.length > 0)) ||
        false,
      requiredFields: {
        billing: {
          ...DEFAULT_REQUIRED_FIELDS,
          email: invoice?.invoiceNotifications
            ? invoice?.invoiceNotifications[0]?.invoiceNotificationMethodId ===
              1
            : true,
          country: true,
          state: true,
        },
        shipping: DEFAULT_REQUIRED_FIELDS,
      },
      defaultAddresses: invoice?.invoiceAddresses
        ? invoice?.invoiceAddresses
        : customer?.addresses,
    },
  );
  const invoiceNotificationMethodId = useWatch({
    control: methods.control,
    name: "invoiceNotification.invoiceNotificationMethodId",
    defaultValue: invoice?.invoiceNotifications
      ? invoice?.invoiceNotifications[0]
        ? invoice.invoiceNotifications[0]?.invoiceNotificationMethodId
        : 0
      : 1,
  });
  const billingAddress = invoice?.invoiceAddresses?.find((a) => a?.isBilling);
  const shippingAddress = invoice?.invoiceAddresses?.find((a) => a?.isShipping);

  useEffect(() => {
    if (!isInitialRender) methods.trigger();
  }, [invoiceNotificationMethodId]);

  useEffect(() => {
    if (invoice) {
      methods.setValue("billing", billingAddress);
      methods.setValue("shipping", shippingAddress);
      setCopyBillingAddress(addressesAreEqual(billingAddress, shippingAddress));
      setState({ defaultAddresses: invoice?.invoiceAddresses });
    }
  }, [invoice]);

  useEffect(() => {
    if (customer && !invoice && mode === "create") {
      const customerBilling = customer?.addresses?.find((a) => a?.isBilling);
      const customerShipping = customer?.addresses?.find((a) => a?.isShipping);
      methods.setValue("billing", customerBilling);
      methods.setValue("shipping", customerShipping);
      setCopyBillingAddress(
        addressesAreEqual(customerBilling, customerShipping),
      );
      setState({ defaultAddresses: customer?.addresses });
    }
  }, [customer]);

  const handleAlternateAddressChange = (type, addressId) => {
    resetFields(type);

    // For some reason the fields won't update unless I set them like this
    Object.keys(state.requiredFields.billing).forEach((key) => {
      methods.setValue(
        `${type}.${key}`,
        customer?.addresses?.find((a) => a.customerAddressId === addressId)?.[
          key
        ],
        { shouldDirty: false },
      );
    });

    setCopyBillingAddress(
      addressesAreEqual(
        methods.getValues().billing,
        methods.getValues().shipping,
      ),
    );
  };

  const handleFormChange = (type) => {
    if (type === "shipping" && methods.getFieldState("shipping").isDirty) {
      if (copyBillingAddress && mode !== "edit")
        handleCopyChange(null, false, false);
      if (
        (mode === "create" && customer) ||
        (mode === "edit" &&
          customer &&
          !addressesAreEqual(
            methods.getValues("shipping"),
            customer.addresses.find((a) => a.isShipping),
          ))
      )
        setState({ displayCustomerWarning: true });
    } else if (type === "billing") {
      if (copyBillingAddress) handleCopyChange(null, true);
      if (
        (mode === "create" &&
          customer &&
          methods.getFieldState("billing").isDirty) ||
        (mode === "edit" &&
          customer &&
          !addressesAreEqual(
            methods.getValues("billing"),
            customer.addresses.find((a) => a?.isBilling),
          ))
      )
        setState({ displayCustomerWarning: true });
    }
  };

  const handleTabChange = (_event, activeTab) => {
    setState({ activeTab });
  };

  const handleCopyChange = (_event, value, reset = true) => {
    const { billing } = methods.getValues();

    if (reset) resetFields("shipping", true);

    if (value)
      Object.keys(state.requiredFields.billing).forEach((key) => {
        methods.setValue(`shipping.${key}`, billing?.[key], {
          shouldDirty: mode === "edit",
        });
      });
    setCopyBillingAddress(value);

    if (onEdit && value)
      onEdit(
        methods.getValues(),
        methods.formState.dirtyFields,
        state.requireBillingInfo,
        state.requireShippingInfo,
      );
  };

  const handleAddAdditionalRecipientsChange = () => {
    setState({ addAdditionalRecipients: !state.addAdditionalRecipients });
    methods.setValue("invoiceNotification.cc", "", {
      shouldDirty: mode === "edit",
    });
    methods.setValue("invoiceNotification.bcc", "", {
      shouldDirty: mode === "edit",
    });
  };

  const handleNotificationMethodChange = (notificationMethodId) => {
    let { requiredFields } = state;

    if (notificationMethodId === 1) {
      requiredFields.billing.email = true;
    } else {
      requiredFields.billing.email = false;
    }

    setState({ requiredFields });
  };

  const setSearchCustomer = (searchCustomer) => {
    setState({ searchCustomer });
  };

  const handleCustomerChange = async (customerId) => {
    setCopyBillingAddress(false);

    const selectedCustomer = await onCustomerSelect(customerId);

    const defaultBillingAddress =
      selectedCustomer?.addresses?.find((a) => a?.isBilling) ||
      selectedCustomer?.addresses[0];
    const defaultShippingAddress = selectedCustomer?.addresses?.find(
      (a) => a?.isShipping,
    );

    resetFields("billing");
    resetFields("shipping");

    // For some reason the fields won't update unless I set them like this
    Object.keys(state.requiredFields.billing).forEach((key) => {
      methods.setValue(`billing.${key}`, defaultBillingAddress?.[key], {
        shouldDirty: selectedCustomer && mode === "edit",
      });
      methods.setValue(`shipping.${key}`, defaultShippingAddress?.[key], {
        shouldDirty: selectedCustomer && mode === "edit",
      });
    });

    if (selectedCustomer) {
      setState({
        defaultAddresses: [defaultBillingAddress, defaultShippingAddress],
      });
      setCopyBillingAddress(
        addressesAreEqual(defaultBillingAddress, defaultShippingAddress),
      );
    } else {
      setState({
        defaultAddresses: mode === "create" ? invoice?.invoiceAddresses : null,
      });
      setCopyBillingAddress(false);
    }
    setVaultCustomer(false);
    setState({ searchCustomer: false, displayCustomerWarning: false });
    if (mode === "edit") handleEdit(methods.getValues());
  };

  // For some reason the fields won't update unless I reset them like this
  const resetFields = (type, useDefaultValues = false) => {
    const isBilling = type === "billing";
    Object.keys(state.requiredFields.billing).forEach((key) => {
      methods.resetField(`${type}.${key}`, {
        defaultValue: useDefaultValues
          ? state.defaultAddresses?.find(
              (a) => a?.isBilling === isBilling && a?.isShipping === !isBilling,
            )?.[key]
          : undefined,
      });
    });

    if (!methods.getValues(type)?.country?.length)
      methods.resetField(`${type}.country`, {
        defaultValue: "US",
      });
  };

  const addressesAreEqual = (address1, address2) => {
    return Object.keys(state.requiredFields.billing).every((key) => {
      return (address1?.[key] || "") === (address2?.[key] || "");
    });
  };

  const mapAddress = (address, isBilling) => {
    if (!address) return null;

    // Only save address info if at least one value was changed
    let finalAddress = Object.entries(address).reduce((a, [key, value]) => {
      if (key !== "country" && value?.length) {
        if (!a) a = { isBilling, isShipping: !isBilling };
        a[key] = value;
      }

      return a;
    }, null);

    // Only save country if it was changed or if other values were provided
    if (
      finalAddress ||
      methods.getFieldState(`${isBilling ? "billing" : "shipping"}.country`)
        .isDirty ||
      (!isBilling && copyBillingAddress) ||
      invoice?.invoiceAddresses.find((a) => a?.isBilling === isBilling)?.country
    ) {
      if (!finalAddress) finalAddress = { isBilling, isShipping: !isBilling };
      finalAddress.country = address.country;
    }

    return finalAddress;
  };

  const handlePageChange = ({ invoiceNotification, billing, shipping }) => {
    // Automatic form validation doesn't catch errors in inactive tab
    if (
      invoiceNotification.invoiceNotificationMethodId === 1 &&
      !billing.email?.length
    ) {
      setState({ activeTab: 0 });
      methods.trigger("billing");

      return;
    }

    const { requireBillingInfo, requireShippingInfo } = state;
    const invoiceAddresses = [];
    const billingAddress = mapAddress(billing, true);
    const shippingAddress = mapAddress(shipping, false);

    if (billingAddress) invoiceAddresses.push(billingAddress);
    if (shippingAddress) invoiceAddresses.push(shippingAddress);

    const invoice = {
      customerId: customer?.customerId,
      requireBillingInfo,
      requireShippingInfo,
      invoiceNotifications:
        invoiceNotification.invoiceNotificationMethodId !== 0
          ? [
              {
                invoiceNotificationMethodId:
                  invoiceNotification.invoiceNotificationMethodId,
                mainRecipient: billing.email,
                cc:
                  invoiceNotification.cc !== ""
                    ? invoiceNotification.cc
                        .split(",")
                        .map((email) => email.trim())
                    : [],
                bcc:
                  invoiceNotification.bcc !== ""
                    ? invoiceNotification.bcc
                        .split(",")
                        .map((email) => email.trim())
                    : [],
              },
            ]
          : [],
      invoiceAddresses,
    };

    onPageChange(invoice);
  };

  const handleEdit = (values) => {
    if (onEdit && Object.keys(methods.formState.dirtyFields).length > 0) {
      if (
        values.invoiceNotification.invoiceNotificationMethodId === 1 &&
        !values.billing.email?.length
      ) {
        setState({ activeTab: 0 });
        methods.trigger("billing");

        return;
      }

      onEdit(
        values,
        methods.formState.dirtyFields,
        state.requireBillingInfo,
        state.requireShippingInfo,
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => event.preventDefault()}
        onFocus={onEditEnter}
        onBlur={
          onEdit ? () => methods.handleSubmit(handleEdit, onEditFail)() : null
        }
      >
        {mode !== "view" && (
          <Grid container spacing={1} className="invoice-select-customer">
            <Button
              variant="contained"
              startIcon={<PersonSearchIcon />}
              onClick={() => setSearchCustomer(true)}
              data-cy="select-customer"
            >
              Select a Customer
            </Button>
          </Grid>
        )}

        <Grid container spacing={1}>
          <TabbedContactForm
            defaultValues={state.defaultAddresses}
            activeTab={state.activeTab}
            copyBillingAddress={copyBillingAddress}
            countries={countries}
            mode={mode}
            customer={customer}
            displayCustomerWarning={state.displayCustomerWarning}
            onChange={handleFormChange}
            onCopyChange={handleCopyChange}
            onTabChange={handleTabChange}
            onCustomerChange={handleCustomerChange}
            onAlternateAddressChange={handleAlternateAddressChange}
            requiredFields={state.requiredFields}
          />
          <Grid item container className="invoice-additional-info">
            <Grid item xs={12} className="invoice-checkboxes">
              {!customer && showVaultCustomer && (
                <FormControlLabel
                  checked={vaultCustomer}
                  onChange={(e, vaultCustomer) =>
                    setVaultCustomer(vaultCustomer)
                  }
                  control={<Checkbox color="secondary" />}
                  disabled={mode === "view"}
                  label="Save To Customer Vault"
                  className="invoice-checkbox"
                />
              )}
              {/* <Tooltip
                title={
                  disableRequireBillingInfo
                    ? "Billing details are required on payment due to this gateway's surcharge settings"
                    : ""
                }
                placement="top"
              >
                <FormControlLabel
                  checked={state.requireBillingInfo}
                  onChange={(e, requireBillingInfo) =>
                    setState({ requireBillingInfo })
                  }
                  control={<Checkbox color="secondary" />}
                  label="Require Billing Details On Payment"
                  className="invoice-checkbox"
                  disabled={disableRequireBillingInfo || mode === "view"}
                />
              </Tooltip> */}
              {/* <FormControlLabel
                checked={state.requireShippingInfo}
                onChange={(e, requireShippingInfo) =>
                  setState({ requireShippingInfo })
                }
                control={<Checkbox color="secondary" />}
                disabled={mode === "view"}
                label="Require Shipping Details On Payment"
                className="invoice-checkbox"
              /> */}
            </Grid>
            <Grid
              container
              rowSpacing={1}
              spacing={1}
              className="invoice-communication-container"
            >
              <Grid item xs={12}>
                <p className="invoice-communication-menu-label">
                  Send invoice via:
                </p>
              </Grid>
              <Grid item sm={4} md={3}>
                <Select
                  control={methods.control}
                  name="invoiceNotification.invoiceNotificationMethodId"
                  fullWidth
                  defaultValue={invoiceNotificationMethodId}
                  viewOnly={mode === "view"}
                  onChange={handleNotificationMethodChange}
                  className="invoice-communication-menu"
                  rules={{ valueAsNumber: true }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={1}>Email</MenuItem>
                </Select>
              </Grid>
              {invoiceNotificationMethodId === 1 && (
                <Grid
                  item
                  sm={8}
                  md={9}
                  className="add-additional-recipients-button-container"
                >
                  <Link
                    color="secondary"
                    component="button"
                    disabled={mode === "view"}
                    underline={mode !== "view" ? "always" : "none"}
                    variant="body1"
                    className="add-additional-recipients-button"
                    onClick={handleAddAdditionalRecipientsChange}
                    data-cy="cc-bcc-button"
                  >
                    {state.addAdditionalRecipients
                      ? "Clear CC/BCC"
                      : "Add CC/BCC"}
                  </Link>
                </Grid>
              )}
              <Grid item xs={0} md={3} />
              <Grid
                item
                xs={12}
                md={6}
                className="additional-recipients-container"
              >
                <Collapse
                  in={
                    invoiceNotificationMethodId === 1 &&
                    state.addAdditionalRecipients
                  }
                >
                  <Grid item xs={12} className="additional-recipients">
                    <RegisteredTextField
                      id="cc"
                      name="invoiceNotification.cc"
                      label="CC additional email addresses"
                      defaultValue={
                        invoice?.invoiceNotifications &&
                        invoice?.invoiceNotifications[0]?.cc.join(", ")
                      }
                      placeholder={
                        mode !== "view"
                          ? "sample@example.com, sample@example.com"
                          : null
                      }
                      viewOnly={mode === "view"}
                      fullWidth
                      autoComplete="off"
                      rules={{
                        pattern: validationRules.multipleEmail,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="additional-recipients">
                    <RegisteredTextField
                      id="bcc"
                      name="invoiceNotification.bcc"
                      label="BCC additional email addresses"
                      defaultValue={
                        invoice?.invoiceNotifications &&
                        invoice?.invoiceNotifications[0]?.bcc.join(", ")
                      }
                      placeholder={
                        mode !== "view"
                          ? "sample@example.com, sample@example.com"
                          : null
                      }
                      viewOnly={mode === "view"}
                      fullWidth
                      autoComplete="off"
                      rules={{
                        pattern: validationRules.multipleEmail,
                      }}
                    />
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
          {mode === "create" && (
            <Grid item xs={12} className="next-page-button">
              <Button
                variant="contained"
                color="secondary"
                onClick={methods.handleSubmit(handlePageChange)}
                data-cy="next-step"
              >
                Next Step
              </Button>
            </Grid>
          )}
        </Grid>
        <SelectCustomerDrawer
          open={state.searchCustomer}
          setOpenDrawer={setSearchCustomer}
          onCustomerSelect={handleCustomerChange}
        />
      </form>
    </FormProvider>
  );
};

InvoiceStepOne.propTypes = {
  countries: PropTypes.array,
  onPageChange: PropTypes.func,
  copyBillingAddress: PropTypes.bool,
  setCopyBillingAddress: PropTypes.func,
  vaultCustomer: PropTypes.bool,
  setVaultCustomer: PropTypes.func,
  showVaultCustomer: PropTypes.bool,
  disableRequireBillingInfo: PropTypes.bool,
  invoice: PropTypes.object,
  customer: PropTypes.object,
  onCustomerSelect: PropTypes.func,
  mode: PropTypes.oneOf(["create", "view", "edit"]),
  onEdit: PropTypes.func,
  onEditEnter: PropTypes.func,
  onEditFail: PropTypes.func,
};

InvoiceStepOne.defaultProps = {
  mode: "create",
};

export default InvoiceStepOne;
