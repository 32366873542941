import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useFormContext, useWatch } from "react-hook-form";
import { isRequired } from "../ReactHookForm/formHelpers";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { getProperty } from "../../utils/objectHelpers";
import "./contact.scss";

const AutoCompleteStates = ({
  options,
  viewOnly,
  rules,
  name,
  defaultValue,
}) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({
    control,
    name: name,
    defaultValue: defaultValue || null,
  });
  const error = getProperty(name, errors);

  const validateField = (fieldName) => {
    if (rules) {
      trigger(fieldName);
    }
  };

  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (
        element.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        element.code.toLowerCase().includes(state.inputValue.toLowerCase())
      )
        newOptions.push(element);
    });
    return newOptions;
  };

  return (
    <Controller
      control={control}
      name={name}
      error={error !== undefined}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          className={
            viewOnly ? "contact-autocomplete disabled" : "contact-autocomplete"
          }
          options={options}
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            return (
              option?.name ||
              options?.find((state) => state?.code === option)?.name ||
              ""
            );
          }}
          isOptionEqualToValue={(option, value) => {
            return (
              option?.code === value ||
              option?.name === value ||
              JSON.stringify(option) === JSON.stringify(value)
            );
          }}
          onChange={(e, values) => field.onChange(values?.code || "")}
          value={value}
          disabled={viewOnly}
          renderInput={(params) => (
            <TextField
              variant={viewOnly ? "standard" : "outlined"}
              {...params}
              label="State"
              InputLabelProps={value || viewOnly ? { shrink: true } : undefined}
              onBlur={() => {
                validateField(name);
              }}
              required={isRequired(rules)}
              error={error !== undefined}
              data-cy={"contact-state"}
            />
          )}
        />
      )}
    />
  );
};

export default AutoCompleteStates;

AutoCompleteStates.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  viewOnly: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
};

AutoCompleteStates.defaultProps = {
  viewOnly: false,
  defaultValue: "",
};
