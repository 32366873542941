import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./tabs.scss";

function TabPanel(props) {
  const { children, value, index, hidePanels, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {hidePanels ? (
        <Box hidden={value !== index} sx={{ mt: 2 }}>
          {children}
        </Box>
      ) : (
        value === index && (
          <Box
            sx={{ mt: 2 }}
            inputProps={{
              "data-cy": "payment_tab",
            }}
          >
            {children}{" "}
          </Box>
        )
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  hidePanels: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  hidePanels: false,
};

function commonTabProps(label, index) {
  return {
    key: `tab-${index}`,
    id: `${label}-tab-${index}`,
    "aria-controls": `${label}-tabpanel-${index}`,
    iconPosition: "start",
  };
}

const StyledTabs = ({
  label,
  tabs,
  defaultTabIndex,
  hidePanels,
  onChange,
  actionButton,
  stickyTabs,
  disabled,
  updateValue,
  vtClass,
}) => {
  const [value, setValue] = React.useState(defaultTabIndex);
  const handleChange = (_e, newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  React.useEffect(() => {
    if (updateValue?.toString()) {
      setValue(updateValue);
    }
  }, [updateValue]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        className={stickyTabs ? "stickyTabs" : ""}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={`${label} tabs`}
          textColor="secondary"
          indicatorColor="secondary"
          className={stickyTabs ? "sticky-tabs-background" : ""}
          sx={{
            height: 60,
            marginTop: -2,
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              className={
                vtClass !== null && vtClass !== undefined ? vtClass : ""
              }
              key={index}
              disabled={disabled?.includes(tab.value)}
              {...tab.props}
              {...commonTabProps(label, index)}
            />
          ))}
          {actionButton && actionButton}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={`panel-${index}`}
          value={value}
          index={index}
          hidePanels={hidePanels}
        >
          {tab.panel}
        </TabPanel>
      ))}
    </Box>
  );
};

StyledTabs.propTypes = {
  label: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.object.isRequired,
      panel: PropTypes.object.isRequired,
    }),
  ),
  disabled: PropTypes.array,
  updateValue: PropTypes.number,
  defaultTabIndex: PropTypes.number,
  hidePanels: PropTypes.bool,
  onChange: PropTypes.func,
  actionButton: PropTypes.object,
  stickyTabs: PropTypes.bool,
  vtClass: PropTypes.string,
};

StyledTabs.defaultProps = {
  defaultTabIndex: 0,
  hidePanels: false,
  stickyTabs: false,
};

export default StyledTabs;
