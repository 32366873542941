import React, { useEffect, useState, useRef, useContext } from "react";
import CVCreate from "./CVCreate";
import ContentComponent from "../../Content/Content";
import { IconButton } from "@mui/material";
import axios from "axios";
import { sassEndpoints } from "../../../constants/endpoints";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import "./cvCreate.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import { Typography } from "@mui/material";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { createCustomerEvent } from "../../../services/mixpanel/merchant/customerVaultEvents";
import { stringFormat } from "../../../utils/stringHelpers";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import ACHTokenizerComponent from "../../Tokenizer/ACHTokenizerCmp";
import TokenExComponent from "../../Tokenizer/TokenExCmp";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { iqProVer } from "../../../constants/global";

export const paymentTabDetails = [
  {
    props: { label: "CARD", icon: <CreditCardIcon /> },
    paymentOption: "card",
  },
  {
    props: { label: "ACH", icon: <AccountBalanceOutlinedIcon /> },
    paymentOption: "ach",
  },
  {
    props: { label: "No Payment Method", icon: <DoDisturbIcon /> },
    paymentOption: "none",
  },
];
const CVCreateContainer = () => {
  const [gatewayConfig, setGatewayConfig] = useState();
  const [gatewayInfo, setGatewayInfo] = useState();
  const { userSettings } = useContext(UserSettingsContext);
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const tokenizerApi = userSettings.publicKey;
  const { ...methods } = useForm();

  const customerForm = useRef();
  const tokenizer = useRef();
  const history = useHistory();

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [currentTab, setCurrentTab] = useState();
  const [dirtyForm, setDirtyForm] = useState({
    billing: false,
    shipping: false,
  });
  const [tokenizerRes, setTokenizerRes] = useState(undefined);
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [secCode, setSECCode] = useState("PPD");
  const [achSubmit, setAchSubmit] = useState(false);
  const [achToken, setAchToken] = useState(null);
  const countries = useContext(CountriesContext);

  useEffect(() => {
    if (achToken && !tokenizerRes) {
      setAchSubmit(false);
      handleTokenizerSubmission({ status: "success", token: achToken });
    }
  }, [achToken]);

  const handleAchChange = (data) => {
    if (tokenizerSettings.achTokenizer === iqProVer.v2) {
      setSECCode(data);
    } else if (tokenizerSettings.achTokenizer === iqProVer.v1) {
      setSECCode(data.sec_code);
    }
  };

  useEffect(() => {
    loadGatewayConfig();
    loadGatewayInfo();
  }, []);

  useEffect(() => {
    if (typeof tokenizerRes !== "undefined") {
      const mpPayload = transformMPPayload();
      const apiPayload = transformPayload(tokenizerRes?.token);
      createCustomer(apiPayload, mpPayload);
    }
  }, [tokenizerRes]);

  useEffect(async () => {
    let url = stringFormat(sassEndpoints.customFields.category, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (res) {
        let groupNames = res.data?.data
          ?.filter((x) => x.customer === true)
          ?.map((group) => group.name);
        let customFields = res.data?.data
          ?.filter((x) => x.customer === true)
          ?.map((fields) => fields.customFields);
        setCustomFieldGroups(groupNames);
        setChosenCustomFields(customFields);
      })
      .catch(function () {
        showAlertDialogError("Failed to get custom fields");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  }, []);

  const loadGatewayConfig = async () => {
    let url = stringFormat(sassEndpoints.gateways.configuration, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          setGatewayConfig(response.data.data);
        }
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails.join(", "));
        console.log("Failed to get gateway configuration", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const loadGatewayInfo = async () => {
    let url = stringFormat(sassEndpoints.gateways.gateway, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          setGatewayInfo(response.data.data);
        }
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails.join(", "));
        console.log("Failed to get gateway details", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const createCustomer = (payload, mpPayload) => {
    let url = stringFormat(sassEndpoints.customers.customer, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (res) {
        showSnackBar("Customer Created");
        const userId = res?.data?.data?.customerId;
        mpPayload.customer_id = userId;
        createCustomerEvent(mpPayload).finally(() => {
          history.push(`${merchantRoutes.customer.base}/${userId}`);
        });
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails
            ? error?.response?.data?.statusDetails
            : error?.response?.data || "Failed to create customer.",
        );
        setTokenizerRes(undefined);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };
  const setupTabs = () => {
    let customerPanels = [];
    if (
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_card",
      ) !== "hidden"
    ) {
      customerPanels.push({
        props: paymentTabDetails[0].props,
        value: "card",
        panel:
          tokenizerSettings.cardTokenizer === iqProVer.v1 ? (
            <div id="tokenizer-card"></div>
          ) : tokenizerSettings.cardTokenizer === iqProVer.v2 ? (
            <div style={{ maxWidth: "60%", marginBottom: "40px" }}>
              <TokenExComponent
                tokenizerRef={tokenizer}
                handleSubmit={handleSaasToken}
                tokenConfig={tokenizerSettings?.iframeConfiguration}
                cvvState={"hidden"}
              />
            </div>
          ) : (
            <></>
          ),
      });
    }
    if (
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_ach",
      ) !== "hidden"
    ) {
      customerPanels.push({
        props: paymentTabDetails[1].props,
        value: "ach",
        panel:
          tokenizerSettings.achTokenizer === iqProVer.v1 ? (
            <div id="tokenizer-ach"></div>
          ) : tokenizerSettings.achTokenizer === iqProVer.v2 ? (
            <div style={{ maxWidth: "60%", marginBottom: "40px" }}>
              {currentTab === "ach" && (
                <ACHTokenizerComponent
                  handleAchChange={handleAchChange}
                  achSubmit={achSubmit}
                  setAchSubmit={setAchSubmit}
                  achToken={achToken}
                  setAchToken={setAchToken}
                  showAlertDialogError={showAlertDialogError}
                />
              )}
            </div>
          ) : (
            <></>
          ),
      });
    }
    customerPanels.push({
      props: paymentTabDetails[2].props,
      value: "none",
      panel: (
        <Typography color="textSecondary">
          No Payment Method Selected.
        </Typography>
      ),
    });
    return customerPanels;
  };

  const handleFormOutput = (tokenizer) => {
    setTokenizerRes(tokenizer);
  };

  const transformMPPayload = () => {
    const form = methods.getValues();
    return {
      payment_method:
        currentTab === "none" ? "No Payment Method" : currentTab.toUpperCase(),
      name: form?.name,
      description: form?.description,
      shipping: form.shipping_address,
      billing: form.billing_address,
    };
  };
  const handleTokenizerSubmission = (response) => {
    if (response.status === "success") {
      handleFormOutput(response);
    } else {
      handleInvalidToken();
    }
  };

  const handleCustomField = () => {
    let customFieldArray = [];
    let chosenGroup = methods.getValues().customer_field_group_select;

    methods
      .getValues()
      .custom_fields[chosenGroup].forEach((fieldValue, index) => {
        if (fieldValue !== null && fieldValue.length > 0) {
          customFieldArray.push({
            customFieldId: index,
            customFieldValue:
              //field value here is required to be type [string]
              typeof fieldValue === "object" ? fieldValue : [fieldValue],
          });
        }
      });
    return customFieldArray;
  };

  const transformPayload = (token = null) => {
    let addresses = [];

    if (dirtyForm.billing) {
      addresses.push(mapAddress(methods.getValues().billing_address, true));
    }
    if (dirtyForm.shipping) {
      addresses.push(mapAddress(methods.getValues().shipping_address, false));
    }
    let paymentType = token
      ? {
          [currentTab]: {
            token: token?.token || token,
            ...(tokenizerRes?.expirationDate && {
              expirationDate: tokenizerRes.expirationDate,
              maskedCard: tokenizerRes.maskedCard,
            }),
          },
        }
      : null;
    let payload = {
      payment: paymentType,
      addresses: addresses,

      name: methods.getValues().name,
      description: methods.getValues().description,
    };
    if (methods.getValues().customer_field_group_select) {
      payload.customFields = handleCustomField();
    }
    return payload;
  };

  const mapAddress = (address, isBilling) => {
    return {
      addressLine1: address?.address_line_1 || "",
      addressLine2: address?.address_line_2 || "",
      city: address?.city || "",
      state: address?.state || "",
      postalCode: address?.postal_code || "",
      country: address?.country || "",
      firstName: address?.first_name || "",
      lastName: address?.last_name || "",
      email: address?.email || "",
      phone: address?.phone || "",
      fax: address?.fax || "",
      company: address?.company || "",
      isShipping: !isBilling,
      isBilling: isBilling,
    };
  };

  const goBack = () => {
    history.push(merchantRoutes.customer.search);
  };

  const onError = () => {
    showAlertDialogError(
      "Please correct validation errors highlighted in red.",
    );
  };

  const handleInvalidToken = () => {
    showAlertDialogError(
      "Please correct validation errors highlighted in red.",
    );
  };
  const handleSaasToken = (data, exp) => {
    setTokenizerRes({
      token: data.token,
      expirationDate: exp,
      maskedCard: data.firstSix + "******" + data.lastFour,
    });
  };
  const submitNewCustomer = () => {
    if (
      tokenizerSettings.cardTokenizer === iqProVer.v2 &&
      currentTab === "card"
    ) {
      tokenizer?.current?.validate();
    } else {
      customerForm.current.createCustomer();
    }
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleFormTouched = (type, isDirty) => {
    dirtyForm[type] = isDirty;
    setDirtyForm(dirtyForm);
  };

  return (
    <ContentComponent
      spinnerOpen={showBackdrop}
      useFixedWidth={false}
      title="Create Customer"
      headerContent={
        <IconButton
          aria-label="Back to List"
          className="back-arrow"
          sx={{ fontSize: "1rem" }}
          onClick={() => goBack()}
        >
          <ChevronLeftIcon sx={{ fontSize: "1.75rem" }} />
          <span>Back To List</span>
        </IconButton>
      }
      bodyContent={
        <FormProvider {...methods}>
          <form>
            <div className="create-customer-wrapper">
              {gatewayConfig && gatewayInfo && (
                <CVCreate
                  gatewayConfig={gatewayConfig}
                  gatewayInfo={gatewayInfo}
                  tokenizerApiKey={tokenizerApi}
                  formOutput={handleFormOutput}
                  handleFormTouched={handleFormTouched}
                  clickEvent={customerForm}
                  countries={countries}
                  paymentPanels={setupTabs()}
                  invalidToken={handleInvalidToken}
                  handleTabChange={handleTabChange}
                  submitNewCustomer={submitNewCustomer}
                  onError={onError}
                  methods={methods}
                  goBack={goBack}
                  customFieldGroups={customFieldGroups}
                  chosenCustomFields={chosenCustomFields}
                  secCode={secCode}
                  handleAchChange={handleAchChange}
                  setAchSubmit={setAchSubmit}
                  handleTokenizerSubmission={handleTokenizerSubmission}
                />
              )}
            </div>
          </form>
        </FormProvider>
      }
      alertDialogOpen={alertDialogOpen}
      alertDialogProps={alertDialogProps}
      snackbarOpen={snackbarOpen}
      snackbarProps={snackbarProps}
    />
  );
};

export default CVCreateContainer;
