import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Grid,
  MenuItem,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { validationRules } from "../../../../constants/validationRules";
import { useFormContext } from "react-hook-form";
import Select from "../../../ReactHookForm/Select";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import AutoCompleteStates from "../../../Contact/AutoCompleteStates";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { State } from "country-state-city";
const RemittanceAddressDrawer = ({
  open,
  setOpenDrawer,
  countries,
  remittanceAddress,
  setRemitAddress,
}) => {
  let country = useRef(remittanceAddress?.country || "US");
  const {
    control,
    reset,
    formState: { isValid, isDirty },
    ...methods
  } = useFormContext();
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (open) {
      loadStates(
        remittanceAddress?.country || "US",
        remittanceAddress?.state || methods.getValues("state"),
      ).then(() => {
        // Validate form in case gateway was created before new validation rules
        methods.trigger();
      });
    }
  }, [open]);

  const handleCountryChange = (newCountry, state) => {
    if (country === newCountry) return;
    methods.setValue("state", null);
    country = newCountry;
    newCountry === "" ? setStates([]) : loadStates(newCountry, state);
  };

  const loadStates = (country, state) => {
    setStates(
      State.getStatesOfCountry(country).map((s) => {
        s.code = s.isoCode;
        return s;
      }),
    );
    if (state) methods.setValue("state", state);

    return Promise.resolve();
  };

  // Only save address info if at least one value was changed
  const mapAddress = (address) => {
    return Object.entries(address).reduce(
      (a, [key, value]) => {
        if (value?.length) a[key] = value;

        return a;
      },
      { isRemittance: true },
    );
  };

  const handleSubmit = (values) => {
    setRemitAddress(mapAddress(values));
    setOpenDrawer(false);
    reset();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpenDrawer(false)}
      PaperProps={{
        className: "remittance-drawer",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6" align="left" className="remittance-heading">
            Update Pay to Address
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <IconButton
            aria-label="close"
            className="remittance-close-button"
            onClick={() => setOpenDrawer(false)}
          >
            <CancelOutlinedIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={1}
        className="remittance-form"
      >
        <Grid item xs={12}>
          <RegisteredTextField
            id="company"
            inputProps={{
              "data-cy": "vt_company",
            }}
            name="company"
            defaultValue={remittanceAddress.company}
            label="Company name"
            fullWidth
            autoComplete="organization-title"
            rules={{
              maxLength: validationRules.maxLength50,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RegisteredTextField
            id="address1"
            inputProps={{
              "data-cy": "vt_address1",
            }}
            name="addressLine1"
            defaultValue={remittanceAddress.addressLine1}
            label="Address line 1"
            fullWidth
            autoComplete="address-line1"
            rules={{
              maxLength: validationRules.maxLength100,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RegisteredTextField
            id="address2"
            inputProps={{
              "data-cy": "vt_address2",
            }}
            name="addressLine2"
            defaultValue={remittanceAddress.addressLine2}
            label="Address line 2"
            fullWidth
            autoComplete="address-line2"
            rules={{
              maxLength: validationRules.maxLength100,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            control={control}
            name="country"
            label="Country"
            onChange={handleCountryChange}
            defaultValue={remittanceAddress.country || "US"}
            fullWidth
            rules={{
              maxLength: validationRules.maxLength40,
            }}
            data-cy="contact-country"
          >
            <MenuItem key="country-default" value="">
              Select Country
            </MenuItem>
            {countries.map((country) => (
              <MenuItem
                key={`remittance-option-value-${country.code}`}
                value={country.code}
              >
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <RegisteredTextField
            id="city"
            inputProps={{
              "data-cy": "vt_city",
            }}
            name="city"
            defaultValue={remittanceAddress.city}
            label="City"
            fullWidth
            rules={{
              maxLength: validationRules.maxLength40,
              pattern: validationRules.city,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteStates
            defaultValue={remittanceAddress.state}
            options={states}
            name="state"
            rules={{
              maxLength: validationRules.maxLength3,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RegisteredTextField
            id="postal_code"
            inputProps={{
              "data-cy": "vt_postal_code",
            }}
            name="postalCode"
            defaultValue={remittanceAddress.postalCode}
            label="Postal Code"
            fullWidth
            autoComplete="postal-code"
            rules={{
              maxLength: validationRules.maxLength10,
            }}
          />
        </Grid>
        <Grid item container spacing={2} className="remittance-buttons">
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDrawer(false)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={!isValid || !isDirty}
              data-cy="update-header-address"
              onClick={methods.handleSubmit(handleSubmit)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

RemittanceAddressDrawer.propTypes = {
  countries: PropTypes.array,
  open: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  setRemitAddress: PropTypes.func,
  remittanceAddress: PropTypes.object,
  displayMessages: PropTypes.func,
};

export default RemittanceAddressDrawer;
