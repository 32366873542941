import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomFieldsForm from "./CustomFieldsForm";
import { sassEndpoints } from "../../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { merchantRoutes } from "../../../../constants/routes";
import AlertDialog from "../../../Dialog/AlertDialog";
import ContentComponent from "../../../Content/Content";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useParams } from "react-router-dom";

const CustomFieldsFormContainer = () => {
  const [customFieldRetrieved, setCustomFieldRetrieved] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState([
    "Failed to create custom fields",
  ]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [categoryPatchResponse, setCategoryPatchResponse] = useState(null);
  const [insertResponse, setInsertResponse] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [firstPatchResponse, setFirstPatchResponse] = useState(null);
  const [fixedOrder, setFixedOrder] = useState(false);
  const [apiError, setError] = useState(false);

  let patchNeeded = false;

  useEffect(() => {
    if (
      insertResponse &&
      deleteResponse &&
      firstPatchResponse &&
      categoryPatchResponse
    ) {
      history.push(merchantRoutes.manage.custom_fields.search);
    }
  }, [
    insertResponse,
    deleteResponse,
    firstPatchResponse,
    categoryPatchResponse,
  ]);

  useEffect(() => {
    setShowBackdrop(false);
  }, [fixedOrder]);

  useEffect(() => {
    if (localStorage.getItem("error") !== null) {
      setError(true);
    }
    localStorage.removeItem("error");
  }, []);

  const history = useHistory();
  const { userSettings } = useContext(UserSettingsContext);
  const { id } = useParams();
  if (id) {
    useEffect(() => {
      setShowBackdrop(true);
      axios
        .get(
          stringFormat(sassEndpoints.customFields.category, [
            userSettings.gatewayId,
          ]),
        )
        .then((response) => {
          let foundCategory = response.data.data.find((field) => {
            return field.customFieldCategoryId === parseInt(id);
          });

          let orderNumbers = foundCategory.customFields.map(
            (obj) => obj.orderNumber,
          );
          let patchPayloadArray = [];
          orderNumbers.forEach((num, i) => {
            let patchPayloadObj = {
              op: "replace",

              value: { OrderNumber: i },

              path: foundCategory.customFields[i].customFieldId,
            };
            patchPayloadArray.push(patchPayloadObj);

            if (orderNumbers[0] !== 0) {
              patchNeeded = true;
            } else {
              if (i !== orderNumbers.length - 1) {
                if (num !== orderNumbers[i + 1] - 1) {
                  patchNeeded = true;
                }
              }
            }
          });
          if (patchNeeded) {
            let url =
              stringFormat(sassEndpoints.customFields.category, [
                userSettings.gatewayId,
              ]) + `/${id}/CustomField`;
            axios.patch(url, patchPayloadArray).then(() => {
              axios
                .get(
                  stringFormat(sassEndpoints.customFields.category, [
                    userSettings.gatewayId,
                  ]),
                )
                .then((response) => {
                  let foundCategory = response.data.data.find((field) => {
                    return field.customFieldCategoryId === parseInt(id);
                  });
                  foundCategory.customFields.forEach((field, i) => {
                    field.id = i;
                  });
                  setFixedOrder(true);
                  setCustomFieldRetrieved(foundCategory);
                });
            });
          } else {
            foundCategory.customFields.forEach((field, i) => {
              field.id = i;
            });
            setCustomFieldRetrieved(foundCategory);
          }
        })
        .catch((error) => {
          setAlertMessage(error.response.data.statusDetails);
          setAlertOpen(true);
        })
        .finally(function () {
          if (!patchNeeded) {
            setShowBackdrop(false);
          }
        });
    }, []);
  }

  const categoryPatchCall = async (payload) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.customFields.category, [
      userSettings.gatewayId,
    ]);
    payload[0].path = id;
    const response = axios
      .patch(url, payload)
      .then(() => {
        setCategoryPatchResponse(true);
        return true;
      })
      .catch(() => {
        localStorage.setItem("error", true);
        window.location.reload();
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return response;
  };

  const deleteApiCall = async (deletedFields) => {
    let promises = [];
    setShowBackdrop(true);
    deletedFields.forEach(async (field) => {
      const deleteUrl =
        stringFormat(sassEndpoints.customFields.customField, [
          userSettings.gatewayId,
        ]) + `/${field.customFieldId}`;
      promises.push(
        axios.delete(deleteUrl).catch(() => {
          localStorage.setItem("error", true);
          window.location.reload();
        }),
      );
    });

    Promise.all([promises])
      .then(() => {
        setDeleteResponse(true);
        return true;
      })
      .catch(() => {
        localStorage.setItem("error", true);
        window.location.reload();
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const postOptionCall = async (fieldId, addedOptionsArray) => {
    setShowBackdrop(true);
    const optionUrl = stringFormat(sassEndpoints.customFields.options, [
      userSettings.gatewayId,
      fieldId,
    ]);
    const response = axios
      .post(optionUrl, addedOptionsArray)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        setAlertMessage(error.response.data.statusDetails);
        setAlertOpen(true);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return response;
  };

  const insertApiCall = async (payload) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.customFields.customField, [
      userSettings.gatewayId,
    ]);
    const response = axios
      .post(url, payload)
      .then((response) => {
        setInsertResponse(true);
        return response.data.data;
      })
      .catch(() => {
        localStorage.setItem("error", true);
        window.location.reload();
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return response;
  };

  const patchApiCall = async (payload) => {
    setShowBackdrop(true);
    let url =
      stringFormat(sassEndpoints.customFields.category, [
        userSettings.gatewayId,
      ]) + `/${id}/CustomField`;
    const response = axios
      .patch(url, payload)
      .then(() => {
        setFirstPatchResponse(true);
        return true;
      })
      .catch(() => {
        localStorage.setItem("error", true);
        window.location.reload();
      })
      .finally(function () {
        setShowBackdrop(false);
      });
    return response;
  };

  return (
    <div className="create-custom-container">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Create Custom Fields"
        headerContent={
          <IconButton
            aria-label="Back to List"
            className="custom-fields-back-arrow"
            sx={{ fontSize: "1rem" }}
            onClick={() =>
              history.push(merchantRoutes.manage.custom_fields.search)
            }
          >
            <ChevronLeftIcon sx={{ fontSize: "1.75rem" }} />
            <span>Back To List</span>
          </IconButton>
        }
        bodyContent={
          <>
            {!showBackdrop && (
              <CustomFieldsForm
                deleteApiCall={deleteApiCall}
                insertApiCall={insertApiCall}
                patchApiCall={patchApiCall}
                postOptionCall={postOptionCall}
                categoryPatchCall={categoryPatchCall}
                customFieldRetrieved={id ? customFieldRetrieved : null}
                setDeleteResponse={setDeleteResponse}
                setInsertResponse={setInsertResponse}
                setFirstPatchResponse={setFirstPatchResponse}
                setCategoryPatchResponse={setCategoryPatchResponse}
                setAlertOpen={setAlertOpen}
                setAlertMessage={setAlertMessage}
                setShowBackdrop={setShowBackdrop}
                apiError={apiError}
              />
            )}

            <AlertDialog
              alertOpen={alertOpen}
              alertTitle="Error!"
              alertLevel="error"
              alertMessages={alertMessage}
              closeButtonText="Ok"
              closeButtonColor="primary"
              onCloseButtonClick={() => setAlertOpen(false)}
            />
          </>
        }
      />
    </div>
  );
};

CustomFieldsFormContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CustomFieldsFormContainer;
