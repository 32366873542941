import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { regexPatterns } from "../../../../constants/regexPatterns";
import PropTypes from "prop-types";
function GatewayProcessorsSimple({ editDetails, isEdit, configName }) {
  const methods = useFormContext();
  const [splitTarget, setSplitTarget] = useState(
    editDetails?.visa_mcc || editDetails?.mc_mcc ? "yes" : "no",
  );
  return (
    <div className="otc-form">
      {" "}
      <div className="otc-form-wrapper">
        <div className="otc-text-inputs">
          <RegisteredTextField
            id="otc-merchant_id"
            name={`setting.${configName}.merchant_id`}
            label="Merchant ID (minimum 12 digits)"
            fullWidth
            errors={methods.errors}
            defaultValue={editDetails?.merchantId || ""}
            viewOnly={!isEdit}
            rules={{
              required: true,
              minLength: { value: 12, message: "Requires 12 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="otc-group_id"
            name={`setting.${configName}.group_id`}
            label="Group ID (minimum 5 digits)"
            fullWidth
            errors={methods.errors}
            viewOnly={!isEdit}
            defaultValue={editDetails?.groupId || ""}
            rules={{
              required: true,
              minLength: { value: 5, message: "Requires 5 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="otc-terminal_id"
            name={`setting.${configName}.terminal_id`}
            label="Terminal ID (minimum 8 digits)"
            fullWidth
            errors={methods.errors}
            viewOnly={!isEdit}
            defaultValue={editDetails?.terminalId || ""}
            rules={{
              required: true,
              minLength: { value: 8, message: "Requires 8 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="did"
            name={`setting.${configName}.did`}
            label="DID (minimum 20 digits)"
            fullWidth
            viewOnly={!isEdit}
            errors={methods.errors}
            defaultValue={editDetails?.did || ""}
            rules={{
              minLength: { value: 20, message: "Requires 20 digits" },
              pattern: regexPatterns.numericSpace,
            }}
          />
        </div>
        <div className="split-section">
          <FormControl>
            <FormLabel id="auto-settle-radio-label">Split Target?</FormLabel>
            <RadioGroup
              aria-labelledby="auto-settle-radio-label"
              defaultValue={
                editDetails?.visaMcc || editDetails?.mcMcc ? "yes" : "no"
              }
              onChange={(e) => {
                setSplitTarget(e.target.value);
              }}
            >
              <FormControlLabel
                labelPlacement="end"
                value={"yes"}
                control={<Radio />}
                label="Yes"
                disabled={!isEdit}
              />
              <FormControlLabel
                labelPlacement="end"
                value={"no"}
                control={<Radio />}
                label="No"
                disabled={!isEdit}
              />
            </RadioGroup>
          </FormControl>

          {splitTarget === "yes" && (
            <div>
              <RegisteredTextField
                id="visa_mcc"
                name={`setting.${configName}.visa_mcc`}
                label="Visa MCC (4 digits)"
                fullWidth
                errors={methods.errors}
                viewOnly={!isEdit}
                defaultValue={editDetails?.visaMcc || ""}
                rules={{
                  minLength: { value: 4, message: "Requires 4 digits" },
                  maxLength: { value: 4, message: "Requires 4 digits" },
                  pattern: regexPatterns.numericSpace,
                }}
              />
              <RegisteredTextField
                id="mc_mcc"
                name={`setting.${configName}.mc_mcc`}
                label="MasterCard MCC (4 digits)"
                fullWidth
                errors={methods.errors}
                viewOnly={!isEdit}
                defaultValue={editDetails?.mcMcc || ""}
                rules={{
                  minLength: { value: 4, message: "Requires 4 digits" },
                  maxLength: { value: 4, message: "Requires 4 digits" },
                  pattern: regexPatterns.numericSpace,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="form-row">
        <Select
          control={methods.control}
          name={`setting.${configName}.classification`}
          fullWidth
          id="classification"
          label="Classification"
          viewOnly={!isEdit}
          defaultValue={editDetails?.classification.toLowerCase() || "ecomm"}
          sx={{ width: "25%" }}
          rules={{
            required: true,
          }}
        >
          <MenuItem value={"ecomm"}>Ecommerce</MenuItem>
          <MenuItem value={"moto"}>MOTO</MenuItem>
          <MenuItem value={"retail"}>Retail</MenuItem>
        </Select>
      </div>
    </div>
  );
}
GatewayProcessorsSimple.propTypes = {
  editDetails: PropTypes.object,
  isEdit: PropTypes.bool,
  configName: PropTypes.string,
};

export default GatewayProcessorsSimple;
