import PropTypes from "prop-types";
import { merchantRoutes } from "../../../constants/routes";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useHistory } from "react-router-dom";
import ActionButton from "../../Buttons/ActionButton";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { stringFormat } from "../../../utils/stringHelpers";
import { useContext } from "react";
import { GatewayContext } from "../../../contexts/GatewayContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptIcon from "@mui/icons-material/Receipt";

const CustomerSearchActions = ({
  customerSearchResult,
  userSettings,
  handleChangeAlertDialogProps,
  loadCustomerSearchResults,
  showSnackBar,
  showAlertDialogError,
  showType,
}) => {
  const gatewayInfo = useContext(GatewayContext);
  const gatewaySettings = gatewayInfo.gatewaySettings.reduce((settings, s) => {
    settings[s.code] = s.value.toLowerCase() === "true";
    return settings;
  }, {});
  const history = useHistory();
  const handleVirtualTerminalNavigation = (id) => {
    history.push({
      pathname: `${merchantRoutes.virtual_terminal}/token/${id}`,
    });
  };

  const goToCustomer = (id, type) => {
    history.push({
      pathname: `${merchantRoutes.customer.base}/${id}`,
      state: type,
    });
  };

  const refreshList = () => {
    handleClosePromptDialog();
    loadCustomerSearchResults();
  };

  const deleteCustomerData = async (id) => {
    let url = stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ]);

    axios
      .delete(url)
      .then(function () {
        showSnackBar("Deleted Customer");
        refreshList();
      })
      .catch(function (error) {
        console.log(error);
        showAlertDialogError("Failed to delete customer");
      })
      .finally(function () {});
  };

  const handleDeleteClick = (id) => {
    handleChangeAlertDialogProps(
      {
        alertTitle: "Are you sure?",
        alertLevel: "info",
        alertMessages: ["You are permanently deleting customer"],
        actionButtons: [
          {
            text: "Yes, Delete This Customer!",
            color: "primary",
            onclick: () => deleteCustomerData(id),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleClosePromptDialog = () => {
    handleChangeAlertDialogProps({}, false);
  };
  return (
    <>
      {showType === "list" ? (
        <ButtonGroup>
          <Tooltip title="View">
            <div>
              <ActionButton
                action="customer_view"
                userSettings={userSettings}
                data={customerSearchResult}
                onClick={() =>
                  goToCustomer(customerSearchResult.customerId, "view")
                }
              />
            </div>
          </Tooltip>

          {userSettings?.gatewayUserPermissions["VAULT_UPDATE"] && (
            <Tooltip title="Edit">
              <div>
                <ActionButton
                  action="customer_edit"
                  userSettings={userSettings}
                  data={customerSearchResult}
                  onClick={() =>
                    goToCustomer(customerSearchResult.customerId, "edit")
                  }
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Charge">
            <div>
              <ActionButton
                action="customer_charge"
                userSettings={userSettings}
                data={customerSearchResult}
                onClick={() =>
                  handleVirtualTerminalNavigation(
                    customerSearchResult.customerId,
                  )
                }
              />
            </div>
          </Tooltip>

          {userSettings?.gatewayUserPermissions["VAULT_DELETE"] && (
            <Tooltip title="Delete">
              <div>
                <ActionButton
                  action="customer_delete"
                  userSettings={userSettings}
                  data={customerSearchResult}
                  onClick={() =>
                    handleDeleteClick(customerSearchResult.customerId)
                  }
                />
              </div>
            </Tooltip>
          )}
          {gatewaySettings["ALLOW_INVOICING"] &&
            gatewaySettings["CUSTOMER_PAYABLE"] &&
            customerSearchResult.customerPayableToken &&
            (userSettings?.gatewayUserPermissions["VIEW_CUST_PAYABLE_TOKEN"] ||
              userSettings?.gatewayUserPermissions[
                "MANAGE_CUST_PAYABLE_TOKEN"
              ]) && (
              <Tooltip title="Copy Customer Payables URL">
                <div>
                  <ActionButton
                    action="customer_URL"
                    userSettings={userSettings}
                    data={customerSearchResult}
                    onClick={() => {
                      showSnackBar("Copied URL to clipboard!");
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_IQPRO_PAY_URL}/invoice/payable/${customerSearchResult.customerPayableToken}`,
                      );
                    }}
                  />
                </div>
              </Tooltip>
            )}
          {gatewaySettings["ALLOW_INVOICING"] &&
            userSettings.gatewayUserPermissions["CREATE_INVOICE"] && (
              <Tooltip title="Create Customer Invoice">
                <div>
                  <ActionButton
                    action="customer_invoice"
                    userSettings={userSettings}
                    data={customerSearchResult}
                    onClick={() => {
                      history.push({
                        pathname: merchantRoutes.invoices.create,
                        state: {
                          customerId: customerSearchResult.customerId,
                        },
                      });
                    }}
                  />
                </div>
              </Tooltip>
            )}
        </ButtonGroup>
      ) : (
        <>
          <MenuItem
            onClick={() =>
              goToCustomer(customerSearchResult.customerId, "view")
            }
          >
            <VisibilityIcon fontSize="small" color="secondary" />
            View Customer
          </MenuItem>

          {userSettings?.gatewayUserPermissions["VAULT_UPDATE"] && (
            <MenuItem
              onClick={() =>
                goToCustomer(customerSearchResult.customerId, "edit")
              }
            >
              <EditIcon fontSize="small" color="warning" />
              Edit Customer
            </MenuItem>
          )}

          <MenuItem
            onClick={() =>
              handleVirtualTerminalNavigation(customerSearchResult.customerId)
            }
          >
            <PointOfSaleIcon fontSize="small" color="secondary" />
            Charge Customer
          </MenuItem>

          {userSettings?.gatewayUserPermissions["VAULT_DELETE"] && (
            <MenuItem
              onClick={() => handleDeleteClick(customerSearchResult.customerId)}
            >
              <DeleteIcon fontSize="small" color="error" />
              Delete Customer
            </MenuItem>
          )}
          {gatewaySettings["ALLOW_INVOICING"] &&
            gatewaySettings["CUSTOMER_PAYABLE"] &&
            customerSearchResult.customerPayableToken &&
            (userSettings?.gatewayUserPermissions["VIEW_CUST_PAYABLE_TOKEN"] ||
              userSettings?.gatewayUserPermissions[
                "MANAGE_CUST_PAYABLE_TOKEN"
              ]) && (
              <MenuItem
                onClick={() => {
                  showSnackBar("Copied URL to clipboard!");
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_IQPRO_PAY_URL}/invoice/payable/${customerSearchResult.customerPayableToken}`,
                  );
                }}
              >
                <LinkIcon color="secondary" />
                Copy Customer Payables URL
              </MenuItem>
            )}
          {gatewaySettings["ALLOW_INVOICING"] &&
            userSettings.gatewayUserPermissions["CREATE_INVOICE"] && (
              <MenuItem
                onClick={() => {
                  history.push({
                    pathname: merchantRoutes.invoices.create,
                    state: {
                      customerId: customerSearchResult.customerId,
                    },
                  });
                }}
              >
                <ReceiptIcon color="secondary" />
                Create Invoice
              </MenuItem>
            )}
        </>
      )}
    </>
  );
};

CustomerSearchActions.propTypes = {
  customerSearchResult: PropTypes.object,
  userSettings: PropTypes.object,
  alertOpen: PropTypes.bool,
  showSnackBar: PropTypes.func,
  handleChangeAlertDialogProps: PropTypes.func,
  deleteCustomerData: PropTypes.func,
  loadCustomerSearchResults: PropTypes.func,
  displayMessages: PropTypes.array,
  showAlertDialogError: PropTypes.func,
  showType: PropTypes.string,
};

export default CustomerSearchActions;
