import React from "react";
import PropTypes from "prop-types";
import { useFormContext, useWatch } from "react-hook-form";
import {
  Grid,
  IconButton,
  MenuItem,
  Collapse,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { validationRules } from "../../../../constants/validationRules";
import FormattedTextField from "../../../ReactHookForm/FormattedTextField";
import { formatCurrency } from "../../../../utils/stringHelpers";
import { calculateLineItemTotal } from "../../../../utils/dineroCalculator";
import Select from "../../../ReactHookForm/Select";
import ControllerTextField from "../../../ReactHookForm/ControllerTextField";
import {
  convertToFloat,
  convertToInt,
} from "../../../../utils/dineroFormatters";

const LineItem = ({
  index,
  showDelete,
  viewOnly,
  onDelete,
  onBlur,
  unitsOfMeasure,
}) => {
  const methods = useFormContext();
  const item = useWatch({
    control: methods.control,
    name: `lineItems.${index}`,
  });

  const onSwitchChange = (event) => {
    methods.setValue(
      `lineItems.${index}.${event.target.name}`,
      event.target.checked,
    );
    onBlur();
  };

  return (
    <Grid
      item
      container
      className={`line-item-container ${viewOnly && "disabled"}`}
    >
      <Grid item container justifyContent="space-between">
        <Grid item className="line-item-info">
          <p>
            <strong>
              {item?.name}
              {item?.name && item?.description && ": "}
              {item?.description}
            </strong>
          </p>
        </Grid>
        {showDelete && !viewOnly && (
          <Grid item>
            <p className="line-item-delete-label">Delete This Line Item</p>
            <IconButton onClick={() => onDelete(index)}>
              <CancelOutlinedIcon color="secondary" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item marginBottom={0.5} paddingLeft={1}>
        <FormControlLabel
          label="Enable Advanced Fields"
          checked={item.advancedFieldsEnabled}
          onChange={onSwitchChange}
          control={
            <Switch
              name="advancedFieldsEnabled"
              color={!viewOnly ? "secondary" : "default"}
            />
          }
          disabled={viewOnly}
        />
      </Grid>
      <Grid item xs={11} container spacing={1}>
        <Grid item xs={12} sm={3}>
          <ControllerTextField
            control={methods.control}
            id="name"
            name={`lineItems.${index}.name`}
            label="Item Name"
            defaultValue={item.name}
            fullWidth
            autoComplete="off"
            disabled={viewOnly}
            errors={methods.formState.errors}
            rules={{
              maxLength: validationRules.maxLength50,
              required: true,
            }}
            transform={{
              input: (value) => value,
              output: (value) => value,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ControllerTextField
            control={methods.control}
            id="description"
            name={`lineItems.${index}.description`}
            label="Item Description"
            defaultValue={item.description}
            fullWidth
            autoComplete="off"
            disabled={viewOnly}
            errors={methods.formState.errors}
            rules={{
              maxLength: validationRules.maxLength100,
              required: true,
            }}
            transform={{
              input: (value) => value,
              output: (value) => value,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <ControllerTextField
            control={methods.control}
            id="quantity"
            name={`lineItems.${index}.quantity`}
            label="Quantity"
            defaultValue={item.quantity}
            inputProps={{ min: 1 }}
            onBlur={onBlur}
            fullWidth
            disabled={viewOnly}
            type="number"
            transform={{
              input: (value) => convertToInt(value, 1),
              output: (value) => convertToInt(value, 1),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormattedTextField
            id="unit-price"
            type="currency"
            name={`lineItems.${index}.unitPrice`}
            onBlur={onBlur}
            label="Unit Price"
            defaultValue={Math.round(item.unitPrice)}
            disabled={viewOnly}
            rules={{
              min: { value: 1, message: "Amount must be greater than $0.00" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormattedTextField
            id="discount"
            type="currency"
            name={`lineItems.${index}.discount`}
            onBlur={onBlur}
            label="Discount"
            defaultValue={item.discount}
            disabled={viewOnly}
            rules={{
              max: {
                value: calculateLineItemTotal(item),
                message: "Discount can not exceed line item total",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={11} sm={9}>
        <Collapse in={item.advancedFieldsEnabled}>
          <Grid item container spacing={1} marginTop={1}>
            <Grid item xs={12} sm={2}>
              <ControllerTextField
                control={methods.control}
                id="shipped-amount"
                name={`lineItems.${index}.shippedAmount`}
                label="Shipped Amount"
                defaultValue={item.shippedAmount}
                inputProps={{ min: 0, step: "0.001" }}
                fullWidth
                disabled={viewOnly}
                type="number"
                transform={{
                  input: (value) => String(convertToFloat(value)),
                  output: (value) => convertToFloat(value),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormattedTextField
                id="freight-amount"
                type="currency"
                name={`lineItems.${index}.freightAmount`}
                label="Freight Amount"
                defaultValue={item.freightAmount}
                disabled={viewOnly}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                control={methods.control}
                id="unit-of-measure"
                name={`lineItems.${index}.unitOfMeasureId`}
                label="Unit of Measure"
                fullWidth
                disabled={viewOnly}
                defaultValue={
                  item.unitOfMeasureId ||
                  unitsOfMeasure.find((uom) => uom.name === "Number")
                    .unitOfMeasureId
                }
                rules={{
                  valueAsNumber: true,
                }}
              >
                {unitsOfMeasure.map((uom) => (
                  <MenuItem
                    key={uom.unitOfMeasureId}
                    value={uom.unitOfMeasureId}
                  >
                    {uom.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item container className="line-item-tax-and-total-container">
        <Grid item container xs={12} sm={9}>
          <Grid item xs={12} paddingLeft={1}>
            <FormControlLabel
              label="Taxable"
              checked={item.isTaxable}
              onChange={onSwitchChange}
              control={
                <Switch
                  name="isTaxable"
                  color={!viewOnly ? "secondary" : "default"}
                />
              }
              disabled={viewOnly}
            />
          </Grid>
          <Grid item xs={11} sm={12}>
            <Collapse in={item.isTaxable}>
              <Grid item container spacing={1} marginTop={0.5}>
                <Grid item xs={12} sm={2} paddingLeft={0}>
                  <FormattedTextField
                    id="local-tax-percent"
                    type="percentage"
                    name={`lineItems.${index}.localTaxPercent`}
                    onBlur={onBlur}
                    disabled={viewOnly}
                    label="Local Tax Percent"
                    defaultValue={item.localTaxPercent || 0}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormattedTextField
                    id="national-tax-percent"
                    type="percentage"
                    name={`lineItems.${index}.nationalTaxPercent`}
                    onBlur={onBlur}
                    disabled={viewOnly}
                    label="National Tax Percent"
                    defaultValue={item.nationalTaxPercent || 0}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <Grid item xs={12} className="line-item-total-container">
          <p className="line-item-total">
            <strong>
              UNIT TOTAL: {formatCurrency(calculateLineItemTotal(item))}
            </strong>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

LineItem.propTypes = {
  index: PropTypes.number,
  lineItem: PropTypes.object,
  showDelete: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
};

export default LineItem;
