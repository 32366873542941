import React from "react";
import { useState, useCallback, useEffect } from "react";
import DragListItem from "../../../DragAndDrop/DragAndDrop";
import "../customFields.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "../../../ReactHookForm/Select";
import Switch from "../../../ReactHookForm/Switch";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Checkbox from "../../../ReactHookForm/Checkbox";
import { Typography, Stack, Grid, Button } from "@mui/material";
import { validationRules } from "../../../../constants/validationRules";
import { FormProvider, useForm } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import { regexPatterns } from "../../../../constants/regexPatterns";
import PropTypes from "prop-types";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory } from "react-router";
import LabelsDrawer from "../ManageCustomLabels";
import axios from "axios";
import { stringFormat } from "../../../../utils/stringHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import { useContext } from "react";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

const NestedCustomFields = ({
  fields,
  deleteApiCall,
  insertApiCall,
  patchApiCall,
  categoryPatchCall,
  setDeleteResponse,
  setInsertResponse,
  setFirstPatchResponse,
  setCategoryPatchResponse,
  setAlertOpen,
  setAlertMessage,
  setShowBackdrop,
  apiError,
}) => {
  const methods = useFormContext();
  const { userSettings } = useContext(UserSettingsContext);
  const history = useHistory();
  const customFieldTypes = [
    { name: "Text", value: 0 },
    { name: "Radio", value: 1 },
    { name: "Checklist", value: 2 },
    { name: "Select", value: 3 },
    { name: "Multiselect", value: 4 },
  ];
  const validationTypes = ["All Characters", "Numbers Only"];
  const valTypeEnums = { text: "System.String", num: "System.UInt32" };
  const checkboxOptions = [
    { name: "Virtual Terminal", value: 0, placement: "end" },
    { name: "Customers", value: 1, placement: "end" },
    { name: "Invoices", value: 2, placement: "end" },
    // { name: "Shopping Cart", value: 3 },
  ];

  const [customFields, setCustomFields] = useState(
    fields?.customFields ? fields.customFields : fields,
  );
  let defaultCheckboxValues = [];
  if (fields.transaction) {
    defaultCheckboxValues = [0];
  }
  if (fields.customer) {
    defaultCheckboxValues = [...defaultCheckboxValues, 1];
  }
  if (fields.invoice) {
    defaultCheckboxValues = [...defaultCheckboxValues, 2];
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [deleteIndex, setDeleteIndex] = useState(0);
  const [openLabelsModal, setOpenLabelsModal] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [totalFields, setTotalFields] = useState(
    fields.customFields ? fields.customFields.length - 1 : 0,
  );
  const [deletedRows, setDeletedRows] = useState([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const [addedFields, setAddedFields] = useState([]);
  const [fieldTypeValuesArray, setFieldTypeValuesArray] = useState([]);
  const [labelsArray, setLabelsArray] = useState(null);
  const [labelFields, setLabelFields] = useState([{ value: "", label: "" }]);
  const [selectedFieldType, setSelectedFieldType] = useState(
    customFieldTypes[0].value,
  );
  const [selectedValidationType, setSelectedValidationType] = useState(
    validationTypes[0],
  );
  const [noFields, setNoFields] = useState(false);
  const [labelError, setLabelError] = useState(false);

  useEffect(() => {
    if (labelsArray) {
      if (fieldTypeValuesArray.find((field) => field.id === selectedField.id)) {
        let copyArray = [...fieldTypeValuesArray];
        copyArray.find((field) => field.id === selectedField.id).values =
          labelsArray;
        setFieldTypeValuesArray(copyArray);
      } else {
        setFieldTypeValuesArray([
          ...fieldTypeValuesArray,
          { id: selectedField.id, values: labelsArray },
        ]);
      }
    }
  }, [labelsArray]);

  useEffect(() => {
    if (selectedField) {
      setOpenLabelsModal(true);
    }
  }, [selectedField]);

  const addRow = () => {
    if (fields.customFields) {
      setAddedFields([...addedFields, { id: totalFields + 1 }]);
    }
    setCustomFields([...customFields, { id: totalFields + 1 }]);
    setTotalFields(totalFields + 1);
    if (customFields.length === 0) {
      setNoFields(false);
    }
  };

  const onFieldChange = (type, id) => {
    if (type !== 0) {
      setSelectedField({
        id: id,
        type: customFieldTypes.find((t) => {
          return t.value === type;
        }).name,
      });
    }
  };

  const moveListItems = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = customFields[dragIndex];
      const hoverItem = customFields[hoverIndex];
      setCustomFields((customFields) => {
        const updatedItems = [...customFields];
        updatedItems[dragIndex] = hoverItem;
        updatedItems[hoverIndex] = dragItem;
        return updatedItems;
      });
    },
    [customFields],
  );

  const handleClick = (event) => {
    setDeleteIndex(event.currentTarget.id.match(/\d+/g).pop());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (fields.customFields) {
      let deletedObject =
        methods.getValues()[`custom_row_${parseInt(deleteIndex)}`];
      let foundOriginalObject = fields.customFields.find(
        (field) => parseInt(deleteIndex) === field.id,
      );
      if (foundOriginalObject) {
        deletedObject.customFieldId = fields.customFields.find(
          (field) => parseInt(deleteIndex) === field.id,
        ).customFieldId;
        setDeletedFields([...deletedFields, deletedObject]);
      }
    }
    if (customFields.length === 1) {
      setNoFields(true);
    }
    setDeletedRows([...deletedRows, `custom_row_${parseInt(deleteIndex)}`]);
    const result = customFields.filter(
      (field) => field.id !== parseInt(deleteIndex),
    );
    methods.unregister(`custom_row_${parseInt(deleteIndex)}`);
    setAnchorEl(null);
    setCustomFields(result);
  };

  const makeCreatePayload = (formValues) => {
    let checkboxValues = formValues.find((element) => {
      return Object.keys(element)[0] === "category_checkbox";
    });

    let categoryNameObject = formValues.find((element) => {
      return Object.keys(element)[0] === "category_name";
    });
    let payload = {
      name: categoryNameObject["category_name"],
      description: "",
      transaction: checkboxValues.category_checkbox.find(
        (value) => value === "0",
      )
        ? true
        : false,
      customer: checkboxValues.category_checkbox.find((value) => value === "1")
        ? true
        : false,
      invoice: checkboxValues.category_checkbox.find((value) => value === "2")
        ? true
        : false,
      customFields: formValues
        .filter((field) => Object.keys(field).length > 1)
        .map((field) => {
          let customFieldObject = {
            name: field.field_name,
            type: field.field_type_select,
            dataTypeId:
              field.validation_type_select === "All Characters"
                ? 1
                : field.validation_type_select === "Numbers Only"
                  ? 10
                  : 0,
            orderNumber: field.orderIndex,
            isRequired: field.required,
            customFieldOption: field.customFieldValue,
          };
          return customFieldObject;
        }),
    };
    return payload;
  };

  const makeEditPayload = (formValues) => {
    let changedFieldArray = [];
    let patchPayloadArray = [];
    let insertFieldArray = {
      customFieldCategoryId: fields.customFieldCategoryId,
      insertCustomField: [],
    };
    let newFieldOrderArray = [];

    let newAddedNumber = 0;
    let orderRequired = false;

    customFields.forEach((field, i) => {
      if (
        ("orderNumber" in field && field.orderNumber !== i) ||
        !("orderNumber" in field)
      ) {
        orderRequired = true;
        return;
      }
    });
    customFields.forEach((field, i) => {
      //if field is newly added, key is only "id"

      if (
        Object.keys(field).filter((key) => key !== "changedRequired").length ===
        1
      ) {
        let originalFieldLength = fields.customFields.filter(
          (field) => Object.keys(field).length !== 1,
        ).length;
        let insertedField = methods.getValues()[`custom_row_${field.id}`];
        let insertPayload = {
          name: insertedField.field_name,
          type: insertedField.field_type_select,
          dataTypeId:
            insertedField.validation_type_select === "All Characters"
              ? 1
              : insertedField.validation_type_select === "Numbers Only"
                ? 10
                : 0,
          orderNumber: originalFieldLength + newAddedNumber,
          isRequired: insertedField.required,
        };
        newAddedNumber++;

        if (
          parseInt(insertedField.field_type_select) !== 0 &&
          fieldTypeValuesArray.length > 0 &&
          fieldTypeValuesArray.find((obj) => {
            return obj.id === field.id;
          })
        ) {
          insertPayload.customFieldOption = fieldTypeValuesArray.find((obj) => {
            return obj.id === field.id;
          }).values;
        }
        insertFieldArray.insertCustomField.push(insertPayload);
        if (i !== insertPayload.orderNumber) {
          newFieldOrderArray.push({
            name: insertedField.field_name,
            orderNumber: i,
          });
        }
      } else {
        let changedField = {
          value: {},
        };
        if (
          field.name !==
          formValues.find((field) => field.orderIndex === i).field_name
        ) {
          changedField.value = {
            Name: formValues.find((field) => field.orderIndex === i).field_name,
          };
        }
        if (
          field.isRequired !==
          formValues.find((field) => field.orderIndex === i).required
        ) {
          changedField.value = {
            ...changedField.value,
            IsRequired: formValues.find((field) => field.orderIndex === i)
              .required,
          };
        }
        if (orderRequired) {
          changedField.value = {
            ...changedField.value,
            OrderNumber: i,
          };
        }
        if (Object.keys(changedField.value).length > 0) {
          changedField.customFieldId = fields.customFields.find(
            (field) => field.id === customFields[i].id,
          ).customFieldId;
          changedFieldArray.push(changedField);
        }
      }
    });

    //if there are fields that were modified, build patch payload with those fields
    if (changedFieldArray.length > 0) {
      let firstPatchPayloadArray = changedFieldArray.map((changedField) => {
        return {
          op: "replace",

          value: changedField.value,

          path: changedField.customFieldId,
        };
      });
      patchPayloadArray.push(firstPatchPayloadArray);
    } else {
      patchPayloadArray.push([]);
    }
    if (insertFieldArray.insertCustomField.length > 0) {
      patchPayloadArray.push(newFieldOrderArray);
    }
    return { insert: insertFieldArray, patch: patchPayloadArray };
  };

  const handleCreate = async () => {
    if (customFields.length < 1) {
      setAlertOpen(true);
      setAlertMessage(["You must have at least one field in each category"]);
    }
    let triggerList = ["category_name"];
    customFields.forEach((obj) => {
      triggerList.push(`custom_row_${obj.id}.field_name`);
    });
    let errorVer = await methods.trigger(triggerList);
    if (Object.keys(methods.formState.errors).length !== 0 || !errorVer) {
      setAlertOpen(true);
      setAlertMessage(["Please correct validation errors highlighted in red"]);
    }
    if (
      Object.keys(methods.formState.errors).length === 0 &&
      errorVer &&
      customFields.length > 0
    ) {
      let formValues = { ...methods.getValues() };
      let valuesArray = customFields.map((obj) => obj.id);

      let finalKeys = Object.keys(formValues).filter(
        (key) =>
          valuesArray.includes(parseInt(key[key.length - 1])) ||
          key.includes("category"),
      );

      let formArray = [];
      for (let key in formValues) {
        if (!finalKeys.includes(key) && !key.includes("category")) {
          delete formValues[key];
        } else {
          if (!key.includes("category")) {
            formValues[key].orderIndex = customFields.indexOf(
              customFields.find(
                (field) => field.id === parseInt(key[key.length - 1]),
              ),
            );
            if (
              fieldTypeValuesArray
                .map((field) => field.id)
                .includes(parseInt(key[key.length - 1]))
            ) {
              formValues[key].customFieldValue = fieldTypeValuesArray.find(
                (field) => field.id === parseInt(key[key.length - 1]),
              ).values;
            }
            formArray.push(formValues[key]);
          } else {
            formArray.push({ [key]: formValues[key] });
          }
        }
      }
      let returnedInsertIds = null;
      if (!fields.customFields) {
        let apiPayload = makeCreatePayload(formArray);
        setShowBackdrop(true);
        let url = stringFormat(sassEndpoints.customFields.category, [
          userSettings.gatewayId,
        ]);
        axios
          .post(url, apiPayload)
          .then(() => {
            history.push(merchantRoutes.manage.custom_fields.search);
          })
          .catch((error) => {
            setAlertMessage(error.response.data.statusDetails);
            setAlertOpen(true);
          })
          .finally(function () {
            setShowBackdrop(false);
          });
      } else {
        let formTransaction = false;
        let formCustomer = false;
        let formInvoice = false;
        let categoryPatch = [
          {
            op: "replace",
            value: {},
          },
        ];
        if (
          methods.getValues().category_checkbox.includes("0") ||
          methods.getValues().category_checkbox.includes(0)
        ) {
          formTransaction = true;
        }
        if (
          methods.getValues().category_checkbox.includes("1") ||
          methods.getValues().category_checkbox.includes(1)
        ) {
          formCustomer = true;
        }
        if (
          methods.getValues().category_checkbox.includes("2") ||
          methods.getValues().category_checkbox.includes(2)
        ) {
          formInvoice = true;
        }
        if (formTransaction !== fields.transaction) {
          categoryPatch[0].value = { Transaction: formTransaction };
        }
        if (formCustomer !== fields.customer) {
          categoryPatch[0].value = {
            ...categoryPatch[0].value,
            Customer: formCustomer,
          };
        }
        if (formInvoice !== fields.invoice) {
          categoryPatch[0].value = {
            ...categoryPatch[0].value,
            Invoice: formInvoice,
          };
        }
        if (Object.keys(categoryPatch[0].value).length > 0) {
          categoryPatchCall(categoryPatch);
        } else {
          setCategoryPatchResponse(true);
        }
        let editPayload = makeEditPayload(formArray);
        if (deletedFields.length > 0) {
          deleteApiCall(deletedFields);
        } else {
          setDeleteResponse(true);
        }
        if (
          editPayload.insert &&
          editPayload.insert.insertCustomField.length > 0
        ) {
          returnedInsertIds = await insertApiCall(editPayload.insert);
        } else {
          setInsertResponse(true);
        }
        if (editPayload.patch[0]?.length > 0) {
          let allFieldsArray = editPayload.patch[0];
          if (returnedInsertIds) {
            let secondPatchPayload = editPayload.patch[1].map((y) => {
              if (returnedInsertIds.find((z) => z.name === y.name)) {
                let newObject = {
                  op: "replace",
                  value: {
                    OrderNumber: y.orderNumber,
                  },
                  path: returnedInsertIds.find((z) => z.name === y.name)
                    .customFieldId,
                };
                return newObject;
              }
            });
            allFieldsArray = editPayload.patch[0].concat(secondPatchPayload);
          }

          patchApiCall(allFieldsArray);
        } else {
          setFirstPatchResponse(true);
        }
      }
    }
  };

  const handleSubmit = async (labels) => {
    // if not newly added custom field
    if (
      Object.keys(customFields.find((field) => field.id === selectedField.id))
        .length > 1
    ) {
      let deleteOption = [];
      let postOption = [];
      labels.forEach((option) => {
        if (option.customFieldOptionId) {
          if (option.isTouched) {
            deleteOption.push(option.customFieldOptionId);

            let updateOption = { ...option };
            delete updateOption.isTouched;
            delete updateOption.customFieldOptionId;
            postOption.push(updateOption);
          }
        } else {
          if (option.isTouched) {
            let newOption = { ...option };
            delete newOption.isTouched;
            postOption.push(newOption);
          }
        }
      });
      if (deleteOption.length !== 0 || postOption.length !== 0) {
        let apiFieldId = customFields.find(
          (field) => field.id === selectedField.id,
        ).customFieldId;
        let newLabelsObj = [...labels];

        if (postOption.length > 0) {
          const optionUrl = stringFormat(sassEndpoints.customFields.options, [
            userSettings.gatewayId,
            apiFieldId,
          ]);
          setShowBackdrop(true);
          axios
            .post(optionUrl, postOption)
            .then((response) => {
              let returnedOptionIds = response.data.data;

              newLabelsObj.forEach((obj) => {
                if (
                  returnedOptionIds.find((idObj) => idObj.label === obj.label)
                ) {
                  obj.customFieldOptionId = returnedOptionIds.find(
                    (idObj) => idObj.label === obj.label,
                  ).customFieldOptionId;
                }
              });
              if (deleteOption.length > 0) {
                setShowBackdrop(true);

                let promises = [];
                deleteOption.forEach(async (id) => {
                  const deleteUrl =
                    stringFormat(sassEndpoints.customFields.options, [
                      userSettings.gatewayId,
                      apiFieldId,
                    ]) + `/${id}`;
                  promises.push(
                    axios.delete(deleteUrl).catch((error) => {
                      setAlertMessage(error.response.data.statusDetails);
                      setAlertOpen(true);
                      setLabelError(true);
                      return error.response.data.statusDetails;
                    }),
                  );
                });

                axios
                  .all([promises])
                  .then(() => {
                    setLabelsArray(labels);
                    setOpenLabelsModal(false);
                  })

                  .catch((error) => {
                    setAlertMessage(error.response);
                    setAlertOpen(true);
                  })
                  .finally(() => {
                    setShowBackdrop(false);
                  });
              } else {
                setLabelsArray(labels);
                setOpenLabelsModal(false);
              }
              setLabelFields(newLabelsObj);
            })
            .catch((error) => {
              setAlertMessage(error.response.data.statusDetails);
              setAlertOpen(true);
            })
            .finally(() => {
              setShowBackdrop(false);
            });
        }
      } else {
        setLabelsArray(labels);
        setOpenLabelsModal(false);
      }
    } else {
      setLabelsArray(labels);
      setOpenLabelsModal(false);
    }
  };

  return (
    <div className="create-custom-field-container">
      {openLabelsModal && (
        <LabelsDrawer
          type={selectedField.type}
          open={openLabelsModal}
          closeModal={() => {
            setOpenLabelsModal(false);
            setSelectedField(null);
          }}
          handleSubmit={handleSubmit}
          selectedFieldId={
            fields.customFields
              ? customFields.find((field) => field.id === selectedField?.id)
                  ?.customFieldId
              : null
          }
          fields={labelFields}
          setErrorModal={setAlertOpen}
          setAlertMessage={setAlertMessage}
        />
      )}

      <div className="card-container category-shadow">
        <h2>Create Category</h2>
        {fields.name ? (
          <p>{fields.name}</p>
        ) : (
          <RegisteredTextField
            className="form-md"
            name={`category_name`}
            label="Category Name"
            sx={{ width: "60%" }}
            defaultValue={""}
            rules={{
              required: { value: true, message: "required" },
              pattern: {
                value: regexPatterns.nameSpecialChar,
                message:
                  "The category name may solely consist of alphanumeric characters and a select set of special characters: !@#$%^&*()_+=-[]{}|:;\"'<>,.?",
              },
              minLength: {
                value: 2,
                message: `The name should be a minimum of 2 characters in length.`,
              },
              maxLength: validationRules.maxLength100,
            }}
            inputProps={{ "data-cy": "cf-category-name" }}
          ></RegisteredTextField>
        )}
        <p className="category-mini-header">Use On:</p>
        <Checkbox
          name="category_checkbox"
          label=""
          control={methods.control}
          errors={methods.errors}
          defaultValues={
            Object.keys(fields).includes("transaction")
              ? defaultCheckboxValues
              : []
          }
          options={checkboxOptions}
        />
      </div>

      <div className="card-container custom-field-wrapper fields-shadow">
        <h2 className="header">Custom Fields</h2>
        {customFields?.length > 0 && (
          <>
            {customFields?.length > 1 ? (
              <p className="drag-text">Drag to reorder these items</p>
            ) : (
              <></>
            )}
            {apiError && (
              <div className="empty-warning">
                <ErrorOutlineIcon style={{ paddingRight: "5px" }} />
                One or more actions failed. Partial or no changes may have
                saved. Please confirm your changes and try again.
              </div>
            )}
            {labelError && (
              <div className="empty-warning">
                <ErrorOutlineIcon style={{ paddingRight: "5px" }} />
                Something went wrong while editing label values. Click save and
                reopen this category to see what has changed
              </div>
            )}
            <div
              className={
                customFields?.length > 1
                  ? "custom-dragdrop-header drag-header"
                  : "custom-dragdrop-header"
              }
            >
              {customFields?.length > 1 && <div></div>}
              <p style={{ width: "200px" }}>FIELD NAME</p>
              <div></div>
              <p style={{ width: "200px" }}>VALIDATION TYPE</p>
              <div></div>
              <p style={{ width: "200px" }}>FIELD TYPE</p>
              <div></div>
              <p>REQUIRED</p>
              <p>ACTIONS</p>
            </div>
            <div>
              <div className="drag-custom-field-wrapper">
                {customFields?.length > 1 ? (
                  <>
                    {customFields.map((item, index) => (
                      <>
                        {/* <div className="card-container custom-field-wrapper"> */}
                        <DragListItem
                          key={item.id}
                          index={index}
                          moveListItem={moveListItems}
                        >
                          <div className="multi-custom-field-div">
                            <div className="handle-block">
                              <DragIndicatorIcon />
                            </div>
                            {/* <div className="inner-header-mobile">
                              Custom Field */}
                            <MoreVertIcon
                              id={`positioned-button-${customFields[index].id}`}
                              aria-controls={
                                open ? "positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              className="mobile-options"
                              style={{ cursor: "pointer" }}
                              onClick={handleClick}
                            />
                            {/* </div> */}

                            <RegisteredTextField
                              className="form-md"
                              name={`custom_row_${customFields[index].id}.field_name`}
                              label="Enter Custom Field Name"
                              inputProps={{ "data-cy": `cfName${index}` }}
                              sx={{ width: "200px" }}
                              defaultValue={
                                fields.customFields
                                  ? item.id < fields.customFields?.length
                                    ? customFields[index].name
                                    : ""
                                  : ""
                              }
                              rules={{
                                required: {
                                  value: true,
                                  message: "required",
                                },
                                pattern: {
                                  value: regexPatterns.nameSpecialChar,
                                  message:
                                    "The name may solely consist of alphanumeric characters and a select set of special characters: !@#$%^&*()_+=-[]{}|:;\"'<>,.?",
                                },
                                validate: (value) => {
                                  let methodValues = {
                                    ...methods.getValues(),
                                  };
                                  let duplicatedName = false;
                                  let filteredKeys = Object.keys(
                                    methodValues,
                                  ).filter((name) => {
                                    return (
                                      name.includes("custom_row") &&
                                      name !==
                                        `custom_row_${customFields[index].id}` &&
                                      !deletedRows.includes(name)
                                    );
                                  });
                                  if (filteredKeys?.length > 0) {
                                    filteredKeys.forEach((key) => {
                                      if (
                                        methodValues[key].field_name === value
                                      ) {
                                        duplicatedName = true;
                                      }
                                    });
                                  }

                                  return (
                                    duplicatedName === false ||
                                    "Field names must be unique"
                                  );
                                },
                                minLength: {
                                  value: 2,
                                  message: `The name should be a minimum of 2 characters in length.`,
                                },
                                maxLength: validationRules.maxLength100,
                              }}
                            ></RegisteredTextField>
                            <div></div>
                            <Select
                              control={methods.control}
                              id="validation-type-select"
                              label="Validation Type"
                              disabled={
                                fields.customFields &&
                                customFields[index].dataType !== undefined
                              }
                              data-cy={`cf-field-validation${index}`}
                              name={`custom_row_${customFields[index].id}.validation_type_select`}
                              defaultValue={
                                customFields[index].dataType
                                  ? customFields[index].dataType ===
                                    valTypeEnums.text
                                    ? validationTypes[0]
                                    : validationTypes[1]
                                  : validationTypes[0] || selectedValidationType
                              }
                              sx={{ width: "200px" }}
                              required={true}
                              onChange={(e) => {
                                setSelectedValidationType(e);
                              }}
                            >
                              {validationTypes.map((groupName, i) => (
                                <MenuItem key={i} value={groupName}>
                                  {groupName}
                                </MenuItem>
                              ))}
                            </Select>

                            <Select
                              control={methods.control}
                              id="field-type-select"
                              label="Field Type"
                              disabled={
                                fields.customFields &&
                                customFields[index].type !== undefined
                              }
                              data-cy={`cf-field-type${index}`}
                              name={`custom_row_${customFields[index].id}.field_type_select`}
                              sx={{ width: "200px" }}
                              required={true}
                              defaultValue={
                                customFields[index].type
                                  ? customFieldTypes.find((type) => {
                                      return (
                                        type.name === customFields[index].type
                                      );
                                    }).value
                                  : customFieldTypes[0].value.toString() ||
                                    selectedFieldType.toString()
                              }
                              onChange={(e) => {
                                setLabelFields(
                                  fieldTypeValuesArray.find(
                                    (field) =>
                                      field.id === customFields[index].id,
                                  )
                                    ? fieldTypeValuesArray.find(
                                        (field) =>
                                          field.id === customFields[index].id,
                                      ).values
                                    : fields.customFields &&
                                        customFields[index].customFieldOption
                                          ?.length > 0
                                      ? customFields[index].customFieldOption
                                      : [{ value: "", label: "" }],
                                );
                                onFieldChange(e, item.id);
                                setSelectedFieldType(e);
                              }}
                            >
                              {customFieldTypes.map((groupName, i) => (
                                <MenuItem key={i} value={groupName.value}>
                                  {groupName.name}
                                </MenuItem>
                              ))}
                            </Select>

                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <div className="required-text">Required: </div>

                              <Typography>No</Typography>
                              <Switch
                                control={methods.control}
                                label="Yes"
                                name={`custom_row_${customFields[index].id}.required`}
                                color="secondary"
                                onChange={(e) => {
                                  customFields[index].changedRequired =
                                    e.target.checked;
                                }}
                                defaultValue={
                                  Object.keys(customFields[index]).includes(
                                    "changedRequired",
                                  )
                                    ? customFields[index].changedRequired
                                    : Object.keys(customFields[index]).includes(
                                          "isRequired",
                                        )
                                      ? customFields[index].isRequired
                                      : false
                                }
                                inputProps={{ "aria-label": "ant design" }}
                              />
                            </Stack>
                            <MoreVertIcon
                              id={`positioned-button-${customFields[index].id}`}
                              aria-controls={
                                open ? "positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              className="wide-screen-options"
                              style={{ cursor: "pointer" }}
                              onClick={handleClick}
                            />
                          </div>
                          {((fields.customFields &&
                            customFields[index].customFieldOption?.length > 0 &&
                            Object.keys(methods.getValues())?.length > 0) ||
                            fieldTypeValuesArray.find(
                              (obj) => obj.id === customFields[index].id,
                            )) && (
                            <Box className="custom-field-type-values-table">
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="desktop-empty-cells"></TableCell>
                                    <TableCell className="desktop-empty-cells"></TableCell>
                                    <TableCell className="desktop-empty-cells"></TableCell>
                                    <TableCell sx={{ width: "10%" }}>
                                      Label
                                    </TableCell>
                                    <TableCell sx={{ width: "10%" }}>
                                      Value
                                    </TableCell>
                                    <TableCell className="desktop-empty-cells"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {fieldTypeValuesArray.find((obj) => {
                                    return obj.id === customFields[index].id;
                                  })
                                    ? fieldTypeValuesArray
                                        .find(
                                          (obj) =>
                                            obj.id === customFields[index].id,
                                        )
                                        .values.map((fieldValue, i) => (
                                          <TableRow key={i}>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell>
                                              {fieldValue.label}
                                            </TableCell>
                                            <TableCell>
                                              {fieldValue.value}
                                            </TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                          </TableRow>
                                        ))
                                    : customFields[index].customFieldOption.map(
                                        (typeObject, i) => (
                                          <TableRow key={i}>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                            <TableCell>
                                              {typeObject.label}
                                            </TableCell>
                                            <TableCell>
                                              {typeObject.value}
                                            </TableCell>
                                            <TableCell className="desktop-empty-cells"></TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                </TableBody>
                              </Table>
                            </Box>
                          )}
                        </DragListItem>
                        {/* </div> */}
                      </>
                    ))}
                    <Menu
                      id="positioned-menu"
                      aria-labelledby="positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {/* IF FIELD TYPE IS TEXT, DISABLE */}
                      <MenuItem
                        onClick={() => {
                          let editable = true;
                          if (!fields.customFields) {
                            if (
                              Object.keys(methods.getValues())?.length === 0
                            ) {
                              methods.setFocus(
                                `custom_row_${parseInt(
                                  deleteIndex,
                                )}.field_name`,
                              );
                              editable = false;
                              handleClose();
                            } else if (
                              customFieldTypes.find((t) => {
                                return (
                                  t.value ===
                                  parseInt(
                                    methods.getValues()[
                                      `custom_row_${parseInt(deleteIndex)}`
                                    ]["field_type_select"],
                                  )
                                );
                              }).name === "Text"
                            ) {
                              methods.setFocus(
                                `custom_row_${parseInt(
                                  deleteIndex,
                                )}.field_name`,
                              );
                              editable = false;
                              handleClose();
                            }
                          } else {
                            if (
                              customFields.find(
                                (obj) => obj.id === parseInt(deleteIndex),
                              )?.type === "Text"
                            ) {
                              methods.setFocus(
                                `custom_row_${
                                  customFields.find((obj) => {
                                    return obj.id === parseInt(deleteIndex);
                                  }).id
                                }.field_name`,
                              );
                              editable = false;
                              handleClose();
                            } else if (
                              Object.keys(methods.getValues())?.length > 0 &&
                              methods.getValues()[
                                `custom_row_${parseInt(deleteIndex)}`
                              ].field_type_select &&
                              customFieldTypes.find((t) => {
                                return (
                                  t.value ===
                                  parseInt(
                                    methods.getValues()[
                                      `custom_row_${parseInt(deleteIndex)}`
                                    ]["field_type_select"],
                                  )
                                );
                              }).name === "Text"
                            ) {
                              methods.setFocus(
                                `custom_row_${parseInt(
                                  deleteIndex,
                                )}.field_name`,
                              );
                              editable = false;
                              handleClose();
                            }
                          }
                          if (editable) {
                            setLabelFields(
                              fieldTypeValuesArray?.length > 0
                                ? fieldTypeValuesArray.find((value) => {
                                    return value.id === parseInt(deleteIndex);
                                  })
                                  ? fieldTypeValuesArray.find(
                                      (value) =>
                                        value.id === parseInt(deleteIndex),
                                    ).values
                                  : fields.customFields &&
                                      customFields.find(
                                        (obj) =>
                                          obj.id === parseInt(deleteIndex),
                                      ).customFieldOption?.length > 0
                                    ? customFields.find(
                                        (obj) =>
                                          obj.id === parseInt(deleteIndex),
                                      ).customFieldOption
                                    : [{ label: "", value: "" }]
                                : fields.customFields &&
                                    customFields.find(
                                      (obj) => obj.id === parseInt(deleteIndex),
                                    ).customFieldOption?.length > 0
                                  ? customFields.find(
                                      (obj) => obj.id === parseInt(deleteIndex),
                                    ).customFieldOption
                                  : [{ label: "", value: "" }],
                            );
                            setSelectedField({
                              id: parseInt(deleteIndex),
                              type: customFieldTypes.find((t) => {
                                if (
                                  !fields.customFields ||
                                  Object.keys(
                                    customFields.find(
                                      (obj) => obj.id === parseInt(deleteIndex),
                                    ),
                                  )?.length === 1
                                ) {
                                  return (
                                    t.value ===
                                    parseInt(
                                      methods.getValues()[
                                        `custom_row_${parseInt(deleteIndex)}`
                                      ]["field_type_select"],
                                    )
                                  );
                                } else {
                                  //if this is a newly added field, field type select wont be undefined
                                  return (
                                    t.name ===
                                    customFields.find(
                                      (obj) => obj.id === parseInt(deleteIndex),
                                    ).type
                                  );
                                }
                              }).name,
                            });
                            setAnchorEl(null);
                          }
                        }}
                      >
                        <EditIcon style={{ marginRight: "10px" }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={deleteRow}>
                        <>
                          <HighlightOffIcon style={{ marginRight: "10px" }} />
                          Delete
                        </>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="single-div-shadow">
                        <div className="single-customfield-div">
                          <MoreVertIcon
                            id={`positioned-button-${customFields[0].id}`}
                            aria-controls={open ? "positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            className="mobile-options"
                            style={{ cursor: "pointer" }}
                            onClick={handleClick}
                          />
                          <RegisteredTextField
                            className="form-md"
                            name={`custom_row_${customFields[0].id}.field_name`}
                            label="Enter Custom Field Name"
                            sx={{ width: "200px" }}
                            defaultValue={
                              fields.customFields ? customFields[0].name : ""
                            }
                            rules={{
                              required: { value: true, message: "required" },
                              pattern: {
                                value: regexPatterns.nameSpecialChar,
                                message:
                                  "The name may solely consist of alphanumeric characters and a select set of special characters: !@#$%^&*()_+=-[]{}|:;\"'<>,.?",
                              },
                              validate: (value) => {
                                let methodValues = { ...methods.getValues() };
                                let duplicatedName = false;
                                let filteredKeys = Object.keys(
                                  methodValues,
                                ).filter((name) => {
                                  return (
                                    name.includes("custom_row") &&
                                    name !==
                                      `custom_row_${customFields[0].id}` &&
                                    !deletedRows.includes(name)
                                  );
                                });
                                if (filteredKeys?.length > 0) {
                                  filteredKeys.forEach((key) => {
                                    if (
                                      methodValues[key].field_name === value
                                    ) {
                                      duplicatedName = true;
                                    }
                                  });
                                }

                                return (
                                  duplicatedName === false ||
                                  "Field names must be unique"
                                );
                              },
                              minLength: {
                                value: 2,
                                message: `The name should be a minimum of 2 characters in length.`,
                              },
                              maxLength: validationRules.maxLength100,
                            }}
                          ></RegisteredTextField>
                          <div></div>
                          <Select
                            control={methods.control}
                            id="validation-type-select"
                            label="Validation Type"
                            name={`custom_row_${customFields[0].id}.validation_type_select`}
                            disabled={
                              fields.customFields &&
                              customFields[0].dataType !== undefined
                            }
                            defaultValue={
                              customFields[0].dataType
                                ? customFields[0].dataType === valTypeEnums.text
                                  ? validationTypes[0]
                                  : validationTypes[1]
                                : validationTypes[0] || selectedValidationType
                            }
                            sx={{ width: "200px" }}
                            required={true}
                            onChange={(e) => {
                              setSelectedValidationType(e);
                            }}
                          >
                            {validationTypes.map((groupName, i) => (
                              <MenuItem key={i} value={groupName}>
                                {groupName}
                              </MenuItem>
                            ))}
                          </Select>

                          <Select
                            control={methods.control}
                            id="field-type-select"
                            label="Field Type"
                            name={`custom_row_${customFields[0].id}.field_type_select`}
                            sx={{ width: "200px" }}
                            required={true}
                            disabled={
                              fields.customFields &&
                              customFields[0].type !== undefined
                            }
                            defaultValue={
                              customFields[0].type
                                ? customFieldTypes.find((type) => {
                                    return type.name === customFields[0].type;
                                  }).value
                                : customFieldTypes[0].value.toString() ||
                                  selectedFieldType.toString()
                            }
                            onChange={(e) => {
                              setLabelFields(
                                fieldTypeValuesArray.find((field) => {
                                  return field.id === customFields[0].id;
                                })
                                  ? fieldTypeValuesArray.find(
                                      (field) =>
                                        field.id === customFields[0].id,
                                    ).values
                                  : fields.customFields &&
                                      customFields[0].customFieldOption
                                        ?.length > 0
                                    ? customFields[0].customFieldOption
                                    : [{ value: "", label: "" }],
                              );
                              onFieldChange(e, customFields[0].id);
                              setSelectedFieldType(e);
                            }}
                          >
                            {customFieldTypes.map((groupName, i) => (
                              <MenuItem key={i} value={groupName.value}>
                                {groupName.name}
                              </MenuItem>
                            ))}
                          </Select>

                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                          >
                            <div className="required-text">Required: </div>
                            <Typography>No</Typography>
                            <Switch
                              control={methods.control}
                              label="Yes"
                              name={`custom_row_${customFields[0].id}.required`}
                              color="secondary"
                              onChange={(e) => {
                                customFields[0].changedRequired =
                                  e.target.checked;
                              }}
                              defaultValue={
                                Object.keys(customFields[0]).includes(
                                  "changedRequired",
                                )
                                  ? customFields[0].changedRequired
                                  : Object.keys(customFields[0]).includes(
                                        "isRequired",
                                      )
                                    ? customFields[0].isRequired
                                    : false
                              }
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Stack>
                          <MoreVertIcon
                            id={`positioned-button-${customFields[0].id}`}
                            aria-controls={open ? "positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            className="wide-screen-options"
                            style={{ cursor: "pointer" }}
                            onClick={handleClick}
                          />
                        </div>
                        {((fields.customFields &&
                          customFields[0].customFieldOption?.length > 0 &&
                          Object.keys(methods.getValues())?.length > 0) ||
                          fieldTypeValuesArray.find(
                            (obj) => obj.id === customFields[0].id,
                          )) && (
                          <Box className="custom-field-type-values-table">
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="desktop-empty-cells"></TableCell>
                                  <TableCell className="desktop-empty-cells"></TableCell>
                                  <TableCell className="desktop-empty-cells"></TableCell>
                                  <TableCell sx={{ width: "10%" }}>
                                    Label
                                  </TableCell>
                                  <TableCell sx={{ width: "10%" }}>
                                    Value
                                  </TableCell>
                                  <TableCell className="desktop-empty-cells"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {fieldTypeValuesArray?.length > 0
                                  ? fieldTypeValuesArray[0].values.map(
                                      (fieldValue, i) => (
                                        <TableRow key={i}>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell>
                                            {fieldValue.label}
                                          </TableCell>
                                          <TableCell>
                                            {fieldValue.value}
                                          </TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                        </TableRow>
                                      ),
                                    )
                                  : customFields[0].customFieldOption.map(
                                      (typeObject, i) => (
                                        <TableRow key={i}>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                          <TableCell>
                                            {typeObject.label}
                                          </TableCell>
                                          <TableCell>
                                            {typeObject.value}
                                          </TableCell>
                                          <TableCell className="desktop-empty-cells"></TableCell>
                                        </TableRow>
                                      ),
                                    )}
                              </TableBody>
                            </Table>
                          </Box>
                        )}
                      </div>
                    </div>
                    <Menu
                      id="positioned-menu"
                      aria-labelledby="positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {/* IF FIELD TYPE IS TEXT, DISABLE */}
                      <MenuItem
                        onClick={() => {
                          let editable = true;
                          if (!fields.customFields) {
                            if (
                              Object.keys(methods.getValues())?.length === 0
                            ) {
                              methods.setFocus(`custom_row_0.field_name`);
                              editable = false;
                              handleClose();
                            } else if (
                              customFieldTypes.find((t) => {
                                return (
                                  t.value ===
                                  parseInt(
                                    methods.getValues()[`custom_row_0`][
                                      "field_type_select"
                                    ],
                                  )
                                );
                              }).name === "Text"
                            ) {
                              methods.setFocus(`custom_row_0.field_name`);
                              editable = false;
                              handleClose();
                            }
                          } else {
                            if (fields.customFields[0].type === "Text") {
                              methods.setFocus(`custom_row_0.field_name`);
                              editable = false;
                              handleClose();
                            }
                          }

                          if (editable) {
                            setLabelFields(
                              fieldTypeValuesArray?.length > 0
                                ? fieldTypeValuesArray[0].values
                                : fields.customFields &&
                                    customFields[0].customFieldOption?.length >
                                      0
                                  ? customFields[0].customFieldOption
                                  : [{ label: "", value: "" }],
                            );
                            setSelectedField({
                              id: 0,
                              type: customFieldTypes.find((t) => {
                                if (
                                  !fields.customFields ||
                                  Object.keys(customFields[0])?.length === 1
                                ) {
                                  return (
                                    t.value ===
                                    parseInt(
                                      methods.getValues()[`custom_row_0`][
                                        "field_type_select"
                                      ],
                                    )
                                  );
                                } else {
                                  //if this is a newly added field, field type select wont be undefined
                                  return t.name === customFields[0].type;
                                }
                              }).name,
                            });
                            setAnchorEl(null);
                          }
                        }}
                      >
                        <EditIcon style={{ marginRight: "10px" }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={deleteRow}>
                        <>
                          <HighlightOffIcon style={{ marginRight: "10px" }} />
                          Delete
                        </>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {noFields && (
          <div className="empty-warning">
            <ErrorOutlineIcon style={{ paddingRight: "5px" }} />
            You must have at least one Custom Field per category. Click the Add
            a Custom Field button to add a new row.
          </div>
        )}
        <Button
          className="add-custom-field"
          onClick={() => addRow()}
          data-cy="cf-add-field"
        >
          + Add Custom Field
        </Button>
        <Grid className="create-dragdrop-buttons">
          <Button
            size="small"
            variant="contained"
            color="neutrals"
            sx={{ marginRight: "10px" }}
            onClick={() =>
              history.push(merchantRoutes.manage.custom_fields.search)
            }
            data-cy="cf_cancel_fields"
          >
            Cancel
          </Button>
          {fields.customFields ? (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                handleCreate();
              }}
              data-cy="cf_save_fields"
            >
              Save
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => handleCreate()}
              data-cy="cf_create_fields"
            >
              Create
            </Button>
          )}
        </Grid>
      </div>
    </div>
  );
};

NestedCustomFields.propTypes = {
  fields: PropTypes.any,
  deleteApiCall: PropTypes.func,
  insertApiCall: PropTypes.func,
  patchApiCall: PropTypes.func,
  postOptionCall: PropTypes.func,
  categoryPatchCall: PropTypes.func,
  setDeleteResponse: PropTypes.func,
  setInsertResponse: PropTypes.func,
  setFirstPatchResponse: PropTypes.func,
  setCategoryPatchResponse: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  apiError: PropTypes.bool,
};

const CustomFieldsForm = ({
  deleteApiCall,
  insertApiCall,
  patchApiCall,
  categoryPatchCall,
  customFieldRetrieved,
  setDeleteResponse,
  setInsertResponse,
  setFirstPatchResponse,
  setCategoryPatchResponse,
  setAlertOpen,
  setAlertMessage,
  apiError,
  // setShowBackdrop,
}) => {
  const methods = useForm();
  const customFields = [
    {
      id: 0,
    },
  ];
  const [showBackdrop, setShowBackdrop] = useState(false);

  return (
    <>
      <Backdrop
        open={showBackdrop}
        transitionDuration={0}
        sx={{ color: "white", zIndex: 9000, opacity: 0.5 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormProvider {...methods}>
        <form>
          <NestedCustomFields
            fields={
              customFieldRetrieved !== null
                ? customFieldRetrieved
                : customFields
            }
            deleteApiCall={deleteApiCall}
            insertApiCall={insertApiCall}
            patchApiCall={patchApiCall}
            categoryPatchCall={categoryPatchCall}
            setDeleteResponse={setDeleteResponse}
            setInsertResponse={setInsertResponse}
            setFirstPatchResponse={setFirstPatchResponse}
            setCategoryPatchResponse={setCategoryPatchResponse}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setShowBackdrop={setShowBackdrop}
            apiError={apiError}
          />
        </form>
      </FormProvider>
    </>
  );
};

CustomFieldsForm.propTypes = {
  fields: PropTypes.any,
  deleteApiCall: PropTypes.func,
  insertApiCall: PropTypes.func,
  patchApiCall: PropTypes.func,
  postOptionCall: PropTypes.func,
  categoryPatchCall: PropTypes.func,
  customFieldRetrieved: PropTypes.object,
  setDeleteResponse: PropTypes.func,
  setInsertResponse: PropTypes.func,
  setFirstPatchResponse: PropTypes.func,
  setCategoryPatchResponse: PropTypes.func,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  apiError: PropTypes.bool,
};

export default CustomFieldsForm;
