import { createTheme } from "@mui/material/styles";
import colors from "../assets/colors.module.scss";
import getPalette from "../assets/palettes";

const getTheme = (mode) => {
  const palette = getPalette(mode);
  let theme = createTheme({
    ...palette,
    typography: {
      h5: {
        fontWeight: colors.font_size_500,
        fontSize: colors.font_size_26,
        letterSpacing: colors.spacing_pt_5,
      },
      h1: {
        fontWeight: "550",
        fontSize: 24,
        color: mode === "light" ? colors.blue500 : colors.light_blue500,
        marginTop: 20,
        marginBottom: 15,
      },
      ...(mode === "light"
        ? {
            h2: {
              fontSize: 20,
              marginTop: 5,
              marginBottom: 5,
            },
          }
        : {
            h2: {
              fontSize: 20,
              marginTop: 5,
              marginBottom: 5,
              color: colors.grey600,
            },
          }),
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: colors.spacing_48,
      },
    },
  });
  theme = {
    ...theme,
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.neutrals.light,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: theme.palette.neutrals.grey600,
            "&.MuiTypography-subtitle1": {
              color: mode === "dark" && colors.grey600,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          ...(mode === "dark" && {
            root: {
              color: theme.palette.primary.headerText,
              "&.Mui-focused": {
                color: theme.palette.primary.headerText,
              },
            },
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          ...(mode === "dark" && {
            root: {
              color: theme.palette.primary.headerText,
              "&.Mui-checked": {
                color: theme.palette.tertiary.dark,
              },
            },
          }),
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            height: "100%",
            backgroundColor: mode === "dark" && theme.palette.neutrals.drawer,
            boxShadow:
              "0px 3px 1px 1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 20%), 3px 3px 3px 0px rgb(0 0 0 / 15%)",
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            boxShadow: "none !important",
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "1rem",
            height: "100%",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            borderRadius: "4px 4px 0 0 ",
            minHeight: "25px",
            paddingLeft: "1em",
            paddingTop: 8,
            paddingBottom: 8,
            "& .MuiAccordionSummary-content": { margin: 0 },
            "& .MuiAccordionSummary-expandIconWrapper": { color: "white" },
            "& .MuiTypography-root": {
              fontSize: 14,
            },
            ...(mode === "light"
              ? {
                  backgroundColor: theme.palette.primary.headerBlue,
                  color: theme.palette.neutrals.light,
                }
              : {
                  backgroundColor: theme.palette.primary.headerBlue,
                  color: theme.palette.primary.headerText,
                }),
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "100%",
            backgroundColor: mode === "dark" && theme.palette.neutrals.drawer,
            boxShadow:
              "0px 3px 1px 1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 20%), 3px 3px 3px 0px rgb(0 0 0 / 15%)",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            fontSize: 14,
            paddingLeft: "1em",
            paddingTop: 8,
            paddingBottom: 8,
            ...(mode === "light"
              ? {
                  backgroundColor: theme.palette.primary.headerBlue,
                  color: theme.palette.neutrals.light,
                }
              : {
                  backgroundColor: theme.palette.primary.headerBlue,
                  color: theme.palette.primary.headerText,
                }),
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              "&:hover": {
                backgroundColor:
                  mode === "dark" && theme.palette.secondary.hover,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "&.MuiButton-containedNeutrals": {
              border: "1px solid",
              borderColor: theme.palette.secondary.main,
              color:
                mode === "light"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.contrastText,
              backgroundColor:
                mode === "light"
                  ? theme.palette.neutrals.light
                  : theme.palette.primary.headerBlue,
              "&:hover": {
                backgroundColor:
                  mode === "light"
                    ? theme.palette.neutrals.light
                    : theme.palette.primary.headerBlue,
              },
            },
          },
          contained: {
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
            "&:hover": {
              boxShadow: "none",
              backgroundColor: mode === "dark" && theme.palette.secondary.hover,
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            ".MuiIconButton-colorSecondary": {
              color: mode === "dark" && theme.palette.tertiary.dark,
            },
            ".MuiIconButton-colorSecondary .MuiSvgIcon-root": {
              fontSize: "1.38rem",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.MuiCheckbox-root": {
              color: mode === "dark" && theme.palette.tertiary.dark,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            ".MuiToggleButton-root:hover": {
              background: colors.blue450,
              color: colors.system_white,
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            color:
              mode === "light"
                ? theme.palette.neutrals.light
                : theme.palette.secondary.contrastText,
            backgroundColor:
              mode === "light"
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            "&:hover": {
              backgroundColor:
                mode === "light"
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.hover,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            ".MuiSwitch-switchBase.Mui-checked": {
              color: mode === "dark" && theme.palette.tertiary.light,
            },
            ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
              backgroundColor: mode === "dark" && theme.palette.tertiary.light,
            },
            ".MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
              color: mode === "dark" && theme.palette.tertiary.main,
            },
            ".Mui-checked.Mui-disabled+.MuiSwitch-track": {
              backgroundColor: mode === "dark" && theme.palette.tertiary.main,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "&.sticky-tabs-background": {
              backgroundColor:
                mode === "light" ? theme.palette.neutrals.main : colors.grey800,
            },
            ".MuiButtonBase-root": {
              color: mode === "dark" && colors.grey600,
              "&.Mui-selected": {
                color:
                  mode === "dark"
                    ? theme.palette.tertiary.dark
                    : colors.blue300,
              },
              "&:hover": {
                color:
                  mode === "dark"
                    ? theme.palette.tertiary.dark
                    : colors.blue300,
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: mode === "dark" && theme.palette.tertiary.dark,
            },
          },

          indicator: {
            height: colors.spacing_3,
            borderTopLeftRadius: colors.spacing_3,
            borderTopRightRadius: colors.spacing_3,
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            "&.MuiTableCell-head": {
              backgroundColor: theme.palette.neutrals.tableHead,
            },
            borderBottomColor: "#e0e0e0bf",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: theme.palette.neutrals.tableHead,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            margin: "0 16px 0 0",
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up("md")]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
            "&.MuiIconButton-colorSecondary": {
              color: mode === "dark" && theme.palette.tertiary.dark,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: colors.spacing_4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: colors.grey_blue800,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: colors.blue300,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "auto",
            marginRight: theme.spacing(2),
            "& svg": {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: colors.spacing_32,
            height: colors.spacing_32,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 32,
            fontSize: 14,
            ...(mode === "dark"
              ? {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: colors.blue300,
                    },
                  },
                }
              : {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: colors.blue550,
                    },
                  },
                }),
          },
          input: {
            boxSizing: "border-box",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: theme.palette.neutrals.dark,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          ...(mode === "light"
            ? {
                root: {
                  marginTop: -10,
                  fontSize: 14,
                  WebkitTextFillColor: `${theme.palette.neutrals.dark} !important`,
                  "&.MuiInputLabel-shrink": {
                    marginTop: 0,
                  },
                },
              }
            : {
                root: {
                  marginTop: -10,
                  fontSize: 14,
                  color: theme.palette.primary.headerText,
                  WebkitTextFillColor: `${theme.palette.neutrals.dark} !important`,
                  "&.MuiInputLabel-shrink": {
                    marginTop: 0,
                    color: theme.palette.primary.headerText,
                  },
                },
              }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          ...(mode === "light"
            ? {
                root: {
                  "& .MuiInput-input": {
                    WebkitTextFillColor: `${theme.palette.neutrals.dark} !important`,
                  },
                },
              }
            : {
                root: {
                  "& .MuiInput-input": {
                    WebkitTextFillColor: `${theme.palette.neutrals.dark} !important`,
                  },
                },
              }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInput-underline:after": {
              borderColor: colors.blue300,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgb(154 154 154 / 70%)",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiGrid-root": {
              textAlign: "center",
            },
            "& .MuiFormControl-root": {
              width: "100%",
              maxWidth: 315,
            },
            "& .MuiDialogContentText-root": {
              width: "100%",
              maxWidth: 315,
            },
            "& .MuiPaper-root": {
              fontSize: 15,
              boxShadow:
                "3px 5px 5px 5px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 10%), 3px 3px 3px 0px rgb(0 0 0 / 15%)",
            },
            "& .MuiButton-root": {
              width: "100%",
              maxWidth: 315,
              "&.MuiButton-containedNeutrals": {
                border: "1px solid",
                borderColor: theme.palette.secondary.main,
                color:
                  mode === "light"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
                backgroundColor:
                  mode === "light"
                    ? theme.palette.neutrals.light
                    : theme.palette.primary.headerBlue,
                "&:hover": {
                  backgroundColor:
                    mode === "light"
                      ? theme.palette.neutrals.light
                      : theme.palette.primary.headerBlue,
                },
              },
              "&:hover": {
                boxShadow:
                  "0px 3px 1px 1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 3px 3px 3px 0px rgb(0 0 0 / 15%)",
                "&.MuiButton-containedPrimary": {
                  backgroundColor: theme.palette.secondary.main,
                },
              },
            },
          },
        },
      },
    },
  };
  return theme;
};

export default getTheme;
