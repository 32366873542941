import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ContentComponent from "../../Content/Content";
import ManageUsers from "./ManageUsersTable";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { stringFormat } from "../../../utils/stringHelpers";
import { useContext } from "react";
const ManageUsersContainer = () => {
  const history = useHistory();
  const [managedUsers, setManagedUsers] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const { userSettings } = useContext(UserSettingsContext);

  useEffect(() => {
    retrieveUserList();
  }, [resultOffset, resultsPerPage]);

  const retrieveUserList = async () => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [userSettings.gatewayId]) +
      `?offset=${resultOffset}&limit=${resultsPerPage}`;
    axios
      .get(url)
      .then(function (response) {
        if (response.data.length === 0) {
          setManagedUsers([]);
          setResultsTotal(0);
        } else if (response?.data?.data.results.length > 0) {
          setManagedUsers(
            response.data.data.results.filter((row) => {
              return { ...row.user, role: row.role };
            }),
          );
          setResultsTotal(response?.data?.data.rowCount);
        }
      })
      .catch(function () {
        showAlertDialogError("Failed to retrieve list of users");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const getUserData = (id) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [userSettings.gatewayId]) +
      "/" +
      id +
      "?isSystemUserId=false";
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 204) {
          showAlertDialogError("Failed to get gateway details");
        }
        history.push({
          pathname: merchantRoutes.manage.users_create,
          state: {
            copiedPermissions: {
              permissions: response.data.data.gatewayUserPermissions,
              role: response.data.data.role.roleId,
            },
          },
        });
      })
      .catch(function (error) {
        showAlertDialogError("Failed to get gateway details");
        setShowBackdrop(false);
        console.log("Failed to get gateway details", error);
      });
  };

  const copyUser = (row) => {
    setShowBackdrop(true);
    getUserData(row.gatewayUserId);
  };
  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };
  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };

  const deleteUser = (row) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [userSettings.gatewayId]) +
      "/" +
      row.gatewayUserId +
      "?isSystemUserId=false";
    axios
      .delete(url)
      .then(function () {
        showSnackBar("Deleted User");
        retrieveUserList();
      })
      .catch(function (error) {
        showAlertDialogError(error);
      });
  };

  const handleDeleteClick = (row) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting this user"],
      actionButtons: [
        {
          text: "Yes, delete user",
          color: "primary",
          onclick: () => {
            deleteUser(row);
            handleClosePromptDialog();
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: handleClosePromptDialog,
    });
    setAlertDialogOpen(true);
  };

  const handleClosePromptDialog = () => {
    setAlertDialogProps({});
    setAlertDialogOpen(false);
  };

  return (
    <div className="gateway-user-list">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="User List"
        headerContent={
          <div>
            {" "}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(merchantRoutes.manage.users_create);
              }}
              data-cy="gateway-create-user"
            >
              Create New User
            </Button>
          </div>
        }
        bodyContent={
          <div>
            <ManageUsers
              handleDeleteClick={handleDeleteClick}
              managedUsers={managedUsers}
              copyUser={(row) => copyUser(row)}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handleChange={handlePageChange}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
            />
          </div>
        }
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
      />
    </div>
  );
};

export default ManageUsersContainer;
