import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SearchTable from "../../Search/SearchTable";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { merchantRoutes } from "../../../constants/routes";
import {
  manageUserColumnHeaders,
  manageUserKeyValue,
} from "./manageUsersTableHelpers";
import SearchPaging from "../../Search/SearchPaging";

function ManageUsers({
  handleDeleteClick,
  managedUsers,
  copyUser,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
}) {
  const history = useHistory({});

  const getActionsComponent = (row) => {
    return (
      <ButtonGroup>
        <Tooltip title="View">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() =>
              history.push({
                pathname: `${merchantRoutes.manage.users}/${row.gatewayUserId}`,
              })
            }
            color={"secondary"}
            fontSize="small"
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() =>
              history.push({
                pathname: `${merchantRoutes.manage.users}/${row.gatewayUserId}`,
                state: {
                  editMode: true,
                },
              })
            }
            color={"warning"}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy User">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => copyUser(row)}
            color={"secondary"}
            fontSize="small"
          >
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => handleDeleteClick(row)}
            color={"error"}
            fontSize="small"
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    );
  };
  return (
    <div className="gateway-table-wrapper">
      <SearchTable
        searchResults={managedUsers}
        getEntityKeyValue={manageUserKeyValue}
        fieldList={manageUserColumnHeaders}
        getActionsComponent={getActionsComponent}
      />
      <SearchPaging
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handleChange}
        defaultPerPage={20}
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
      />
    </div>
  );
}

ManageUsers.propTypes = {
  managedUsers: PropTypes.array,
  resultsPerPage: PropTypes.number,
  resultsTotal: PropTypes.number,
  handleChange: PropTypes.func,
  copyUser: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
};

export default ManageUsers;
