import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext } from "react-hook-form";
import PhoneNumber from "./PhoneNumber";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import Select from "../ReactHookForm/Select";
import { validationRules } from "../../constants/validationRules";
import AutoCompleteStates from "./AutoCompleteStates";

const Contact = ({
  type,
  countries,
  states,
  onCountryChange,
  requiredFields,
  defaultValues,
  viewOnly,
  hiddenFields,
  setTouchedAddressForm,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid container rowSpacing={1.5} columnSpacing={1}>
      <Grid item xs={12} sm={6}>
        {!hiddenFields?.firstName && (
          <RegisteredTextField
            id={`${type}.firstName`}
            inputProps={{
              "data-cy": "first_name",
            }}
            name={`${type}.first_name`}
            label="First name"
            defaultValue={defaultValues?.first_name}
            fullWidth
            autoComplete="given-name"
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            rules={{
              maxLength: validationRules.maxLength50,
              required: requiredFields.firstName,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {!hiddenFields?.lastName && (
          <RegisteredTextField
            id={`${type}.lastName`}
            inputProps={{
              "data-cy": "last_name",
            }}
            name={`${type}.last_name`}
            label="Last name"
            defaultValue={defaultValues?.last_name}
            fullWidth
            autoComplete="family-name"
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            rules={{
              maxLength: validationRules.maxLength50,
              required: requiredFields.lastName,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {!hiddenFields?.company && (
          <RegisteredTextField
            id={`${type}.company`}
            inputProps={{
              "data-cy": "company",
            }}
            name={`${type}.company`}
            label="Company"
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            defaultValue={defaultValues?.company}
            fullWidth
            autoComplete={`${type} organization-title`}
            rules={{
              maxLength: validationRules.maxLength50,
              required: requiredFields.company,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        {!hiddenFields?.address1 && (
          <RegisteredTextField
            id={`${type}.address1`}
            inputProps={{
              "data-cy": "address1",
            }}
            name={`${type}.address_line_1`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="Address"
            defaultValue={defaultValues?.address_line_1}
            fullWidth
            autoComplete={`${type} address-line1`}
            rules={{
              maxLength: validationRules.maxLength100,
              required: requiredFields.address1,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {!hiddenFields?.address2 && (
          <RegisteredTextField
            id={`${type}.address2`}
            inputProps={{
              "data-cy": "address2",
            }}
            name={`${type}.address_line_2`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="Address line 2"
            defaultValue={defaultValues?.address_line_2}
            fullWidth
            autoComplete={`${type} address-line2`}
            rules={{
              maxLength: validationRules.maxLength100,
              required: requiredFields.address2,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {!hiddenFields?.country && (
          <Select
            control={control}
            name={`${type}.country`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="Country"
            onChange={onCountryChange}
            defaultValue={defaultValues?.country || "US"}
            fullWidth
            errors={errors}
            rules={{
              required: requiredFields.country,
              maxLength: validationRules.maxLength40,
            }}
            viewOnly={viewOnly}
            data-cy="contact-country"
          >
            <MenuItem key="country-default" value="">
              Select Country
            </MenuItem>
            {countries.map((country) => (
              <MenuItem
                key={`${type}-option-value-${country.code}`}
                value={country.code}
              >
                {country.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {!hiddenFields?.state && (
          <AutoCompleteStates
            defaultValue={defaultValues?.state}
            options={states}
            viewOnly={viewOnly}
            name={`${type}.state`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            rules={{
              required: requiredFields.state,
              maxLength: validationRules.maxLength3,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {!hiddenFields?.city && (
          <RegisteredTextField
            id={`${type}.city`}
            inputProps={{
              "data-cy": "city",
            }}
            name={`${type}.city`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="City"
            defaultValue={defaultValues?.city}
            fullWidth
            autoComplete={`${type} address-level2`}
            rules={{
              maxLength: validationRules.maxLength40,
              required: requiredFields.city,
              pattern: validationRules.city,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {!hiddenFields?.postalCode && (
          <RegisteredTextField
            id={`${type}.postal_code`}
            inputProps={{
              "data-cy": "postal_code",
            }}
            name={`${type}.postal_code`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="Postal Code"
            defaultValue={defaultValues?.postal_code}
            fullWidth
            autoComplete={`${type} postal-code`}
            rules={{
              required: requiredFields.postalCode,
              maxLength: validationRules.maxLength10,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {!hiddenFields?.email && (
          <RegisteredTextField
            id={`${type}.email`}
            inputProps={{
              "data-cy": "email",
            }}
            name={`${type}.email`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            label="Email"
            defaultValue={defaultValues?.email}
            fullWidth
            autoComplete={`${type} email`}
            rules={{
              pattern: validationRules.email,
              required: requiredFields.email,
              maxLength: validationRules.maxLength100,
            }}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {!hiddenFields?.phone && (
          <PhoneNumber
            name={`${type}.phone`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            id={`${type}.phone`}
            label="Phone"
            defaultValue={defaultValues?.phone}
            required={requiredFields.phone}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {!hiddenFields?.fax && (
          <PhoneNumber
            name={`${type}.fax`}
            onBlur={() => {
              if (setTouchedAddressForm) {
                setTouchedAddressForm(Math.random());
              }
            }}
            id={`${type}.fax`}
            label="Fax"
            defaultValue={defaultValues?.fax}
            required={requiredFields.fax}
            viewOnly={viewOnly}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Contact;

Contact.propTypes = {
  type: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.object,
  defaultValues: PropTypes.object,
  viewOnly: PropTypes.bool,
  hiddenFields: PropTypes.object,
  setTouchedAddressForm: PropTypes.func,
};

Contact.defaultProps = {
  viewOnly: false,
};
