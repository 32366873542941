import React, { useState, useContext, useEffect } from "react";
import GatewayTable from "./GatewayTable";
import {
  GATEWAY_STATUS,
  SYSTEM_PERMISSION_CODES,
} from "../../../constants/global";
import ContentComponent from "../../Content/Content";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import IconButton from "@mui/material/IconButton";
import { sassEndpoints } from "../../../constants/endpoints";
import { partnerRoutes } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./gateway.scss";
import SearchManager from "../../Search/SearchManager";
import { gatewaySearchFields } from "./gatewayTableHelpers";
import { userHasPermission } from "../../../utils/objectHelpers";

const GatewayContainer = () => {
  const [gateways, setGateways] = useState([]);

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});

  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [searchPayload, setSearchPayload] = useState(null);

  const [searchFields, setSearchFields] = useState(null);

  const [forceUpdateResults, setForceUpdateResults] = useState();

  const { userSettings } = useContext(UserSettingsContext);
  const history = useHistory();

  useEffect(() => {
    const copiedArr = deepCopyFunction(gatewaySearchFields);
    setSearchFields(copiedArr);
  }, []);

  const deepCopyFunction = (inObject) => {
    let outObject, value, key;
    if (typeof inObject !== "object" || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }
    outObject = Array.isArray(inObject) ? [] : {};
    for (key in inObject) {
      value = inObject[key];
      outObject[key] = deepCopyFunction(value);
    }
    return outObject;
  };

  useEffect(() => {
    if (searchPayload !== null) {
      retrieveGateways();
    }
  }, [searchPayload, resultsPerPage, forceUpdateResults]);

  const retrieveGateways = async () => {
    let url = sassEndpoints.gateways.search;
    let payload = searchPayload;
    if (payload.createdDate) {
      payload.createdDate.operator = "Between";
    }
    payload.limit = resultsPerPage;
    payload.offset = resultOffset;

    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        if (response?.status === 200) {
          setResultsTotal(response.data.data.rowCount);
          setGateways(response.data.data.results);
        } else {
          setResultsTotal(0);
          setGateways([]);
        }
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const getActionsComponent = (row) => {
    return (
      <IconButton
        disabled={
          row.status.toLowerCase() === GATEWAY_STATUS.IN_MIGRATION_QUEUE ||
          row.status.toLowerCase() === GATEWAY_STATUS.MIGRATING ||
          row.status.toLowerCase() === GATEWAY_STATUS.MIGRATION_FAILED
            ? true
            : false
        }
        variant="container"
        color="secondary"
        sx={{ padding: 0.5 }}
        onClick={() => {
          history.push({
            pathname: `${partnerRoutes.gateway}/view/${row?.gatewayId}`,
          });
        }}
        fontSize="small"
        data-cy="view"
      >
        <VisibilityOutlinedIcon />
      </IconButton>
    );
  };

  const searchGateway = (form) => {
    setResultOffset(0);
    setSearchPayload(form);
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
    setForceUpdateResults(Date.now());
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };
  return (
    <div className="gateway-list">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Gateway List"
        headerContent={
          <div>
            {searchFields?.length > 0 && (
              <div>
                {userHasPermission(
                  SYSTEM_PERMISSION_CODES.create,
                  userSettings,
                ) ? (
                  <SearchManager
                    searchButtonOnClick={searchGateway}
                    showCreateButton={true}
                    createButtonText="Create New Gateway"
                    createButtonOnClick={() =>
                      history.push(partnerRoutes.gateway_create)
                    }
                    showAlertDialogError={showAlertDialogError}
                    entitySearchFilterFields={searchFields}
                    savedSearches={false}
                    sectionName={"Gateway List"}
                    showSnackBar={showSnackBar}
                  />
                ) : (
                  <SearchManager
                    searchButtonOnClick={searchGateway}
                    showAlertDialogError={showAlertDialogError}
                    entitySearchFilterFields={searchFields}
                    savedSearches={false}
                    sectionName={"Gateway List"}
                    showSnackBar={showSnackBar}
                  />
                )}
              </div>
            )}
          </div>
        }
        bodyContent={
          <div>
            <GatewayTable
              data={gateways}
              getActionsComponent={getActionsComponent}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handleChange={handlePageChange}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
            />
          </div>
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};

export default GatewayContainer;
