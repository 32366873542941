import React from "react";
import PropTypes from "prop-types";
import { Grid, MenuItem, Button } from "@mui/material";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import Select from "../ReactHookForm/Select";
import { useFormContext } from "react-hook-form";
import { validationRules } from "../../constants/validationRules";

const ApplicationCreate = ({
  setCreateApp,
  roleType,
  setRoleType,
  onSubmit,
  onError,
  applicationFormError,
  setApplicationName,
}) => {
  const methods = useFormContext();

  return (
    <Grid
      container
      flexDirection={"column"}
      rowSpacing={3}
      className="create-app-grid"
    >
      <Grid item className="application-detail-bold">
        Create New Application
      </Grid>
      <Grid item>
        <Grid container flexDirection={"column"} className="create-edit-divs">
          {" "}
          <Grid item>Application Name</Grid>
          <Grid item>
            <RegisteredTextField
              name={`applicationName`}
              fullWidth
              onBlur={(e) => {
                methods.trigger("applicationName");
                setApplicationName(e.target.value);
              }}
              rules={{
                required: {
                  value: true,
                  message: "Specify the application's name",
                },
                pattern: {
                  value: /^[a-zA-Z0-9\-. ]+$/,
                  message:
                    "Only alphanumeric and the following special characters are valid: - .",
                },
                maxLength: validationRules.maxLength50,
              }}
            ></RegisteredTextField>
          </Grid>
          <Grid item style={{ paddingTop: "24px" }}>
            Role
          </Grid>
          <Grid item>
            <Select
              control={methods.control}
              name={"role"}
              value={roleType}
              label="Please Select Role"
              fullWidth
              onBlur={() => {
                methods.trigger("role");
              }}
              onChange={(e) => {
                setRoleType(e);
              }}
              rules={{
                required: {
                  value: true,
                  message: "Specify a role for the application",
                },
              }}
            >
              <MenuItem value={process.env.REACT_APP_INT_SERVICE_ID}>
                Integrated Service Account
              </MenuItem>
              <MenuItem value={process.env.REACT_APP_INT_GATEWAY_ID}>
                Integrated Gateway Standard
              </MenuItem>
            </Select>
            {
              <div style={{ fontSize: "12px" }}>
                {roleType === process.env.REACT_APP_INT_SERVICE_ID
                  ? "Selecting this role allows your application to process and view transactions of all types in the gateway, manage the customer vault, and batched settlements. As well as being able to view, create, edit, and delete all invoices and products in the gateway, and view and manage customer payable tokens."
                  : roleType === process.env.REACT_APP_INT_GATEWAY_ID
                    ? "Selecting this role allow your application to process all transaction types, manage the customer vault, and view invoices and batched settlements"
                    : ""}
              </div>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item>Secrets</Grid>
      <Grid item>
        <div className="create-edit-divs" style={{ fontWeight: "500" }}>
          An application must be created before secrets can be added.
        </div>
      </Grid>
      <Grid item>
        <div className="btn-row" style={{ float: "right" }}>
          <Button
            variant="contained"
            className="secondary-medium-button"
            sx={{ marginRight: "10px" }}
            onClick={() => {
              setCreateApp(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="primary-medium-button"
            disabled={applicationFormError}
            onClick={methods.handleSubmit(onSubmit, onError)}
          >
            Create
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
ApplicationCreate.propTypes = {
  setCreateApp: PropTypes.bool,
  roleType: PropTypes.string,
  setRoleType: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  applicationFormError: PropTypes.bool,
  setApplicationName: PropTypes.func,
};
export default ApplicationCreate;
