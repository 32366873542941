import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import MUISelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { getProperty } from "../../utils/objectHelpers";
import FormHelperText from "@mui/material/FormHelperText";
import { isRequired } from "./formHelpers";

export const Select = ({
  name,
  label,
  defaultValue,
  onChange,
  children,
  control,
  rules,
  options,
  returnType,
  disabled,
  viewOnly,
  v2,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = getProperty(name, errors);
  const viewOnlyProps = {};
  if (viewOnly) {
    viewOnlyProps.variant = "standard";
    viewOnlyProps.disabled = true;
    viewOnlyProps.IconComponent = "";
    viewOnlyProps.disableUnderline = true;
  }
  return (
    <>
      <FormControl
        required={isRequired(rules)}
        {...register(name, rules)}
        error={error !== undefined}
        {...props}
        disabled={viewOnly}
      >
        <InputLabel
          variant={viewOnlyProps?.variant}
          disabled={disabled}
          id={`${name}-select`}
        >
          {label}
        </InputLabel>
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          rules={rules}
          render={({ field }) => (
            <MUISelect
              {...(!v2 && { label: label })}
              disabled={disabled}
              onChange={(e) => {
                field.onChange(returnType === "array" ? [e.target.value] : e);
                onChange && onChange(e.target.value);
              }}
              defaultValue={defaultValue}
              value={field.value}
              {...viewOnlyProps}
            >
              {children
                ? children
                : options.map((option, index) => (
                    <MenuItem
                      key={`${name}-${option.name}-${index}`}
                      value={option.value}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
            </MUISelect>
          )}
        />
      </FormControl>
      <FormHelperText sx={{ ml: 0 }} error>
        {error && error.message}
      </FormHelperText>
    </>
  );
};

export default Select;

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  rules: PropTypes.object,
  options: PropTypes.array,
  returnType: PropTypes.string,
  disabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
  v2: PropTypes.bool,
};

Select.defaultProps = {
  defaultValue: "",
  returnType: "string",
  disabled: false,
  viewOnly: false,
  v2: false,
};
