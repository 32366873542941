import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import MUISwitch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export const Switch = ({
  name,
  label,
  defaultValue,
  control,
  labelPlacement,
  disabled = false,
  onChange,
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          control={
            <MUISwitch
              color="secondary"
              disabled={disabled}
              defaultChecked={defaultValue}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
            />
          }
          labelPlacement={labelPlacement}
          label={label}
        />
      )}
    />
  );
};

export default Switch;

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  control: PropTypes.object.isRequired,
  register: PropTypes.bool,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  defaultValue: false,
};
