import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import SearchTable from "../../../Search/SearchTable";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { getSaasKeyValue } from "./settlementTableHelpers";
import { saasResultsColumnHeaders } from "./settlementTableHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import { merchantRoutes } from "../../../../constants/routes";
import axios from "axios";
import SearchPaging from "../../../Search/SearchPaging";
import DownloadButton from "../../../Buttons/DownloadButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  keyInfoLookup,
  getTransactionKeyValue,
} from "../../ReportingTransactions/SearchResults";
import { getOutputFileName } from "../../ReportingTransactions/ReportingTransactionsContainer";
import Tooltip from "@mui/material/Tooltip";
import { transformToPDF } from "../../../../utils/pdfGenerator";
import logo from "../../../../assets/images/clear-background-basys-logo.png";
import "../../ReportingTransactions/reportingTransactions.scss";
import { stringFormat } from "../../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import AlertDialog from "../../../Dialog/AlertDialog";

const SaasResultsDetailsModal = ({ data }) => {
  const { userSettings } = useContext(UserSettingsContext);
  const [open, setOpen] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionResults, setTransactionResults] = useState([]);
  const [pdfTransactionResults, setPDFTransactionResults] = useState([]);
  const [resultsOffset, setResultsOffset] = useState(0);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const handleOpen = () => {
    setOpen(true);
    loadTransactionSearchResults(false);
  };
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const tableRef = useRef();

  useEffect(() => {
    if (open) {
      loadTransactionSearchResults(false);
    }
  }, [resultsOffset, resultsPerPage]);

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertMessages: alertMessage,
    });
    setAlertDialogOpen(true);
  };

  const loadTransactionSearchResults = async (isPDF) => {
    setIsLoading(!isPDF);
    let payload = {
      sortColumn: "CreatedDateTime",
      sortDirection: "DESC",
      settlementBatchId: { operator: "Equal", value: data.settlementId },
    };
    payload.limit = isPDF ? 100 : resultsPerPage;
    payload.offset = isPDF ? 0 : resultsOffset;
    await axios
      .post(
        stringFormat(sassEndpoints.transactions.search, [
          userSettings.gatewayId,
        ]),
        payload,
      )
      .then((response) => {
        if (isPDF) {
          setPDFTransactionResults(response.data.data.results);
          transformToPDF(tableRef, "Transactions.pdf", 1350);
        } else if (response?.data?.data?.results.length > 0) {
          setTransactionResults(response.data.data.results);
          setTotalResults(response.data.data.rowCount);
        }
      })
      .catch((error) => {
        showAlertDialogError(error.response.data.statusDetails);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  const handleTransSettlement = () => {
    history.push({
      pathname: `${merchantRoutes.reporting.transactions}`,
      state: { settlement_batch_id: data.settlementId },
    });
  };

  const downloadTransactionsCSV = async () => {
    let url = stringFormat(sassEndpoints.transactions.searchCSV, [
      userSettings.gatewayId,
    ]);
    let payload = {
      sortColumn: "CreatedDateTime",
      sortDirection: "DESC",
      settlementBatchId: { operator: "Equal", value: data.settlementId },
    };

    setIsLoading(true);
    await axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", getOutputFileName());
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(function (error) {
        showAlertDialogError(["Failed to download transactions output file"]);
        console.log("Failed to download transactions output file", error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };
  const handleResultsPerPageOnChange = (event) => {
    setResultsOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultsOffset(pageIndex * resultsPerPage);
  };

  const handleSelection = (event) => {
    event === "CSV"
      ? downloadTransactionsCSV()
      : loadTransactionSearchResults(true);
  };
  return (
    <div>
      <AlertDialog
        alertOpen={alertDialogOpen}
        alertTitle="Error!"
        alertLevel="error"
        alertMessages={alertDialogProps.alertMessages}
        closeButtonText="Ok"
        closeButtonColor="primary"
        onCloseButtonClick={() => setAlertDialogOpen(false)}
      />
      <Tooltip title="View Details">
        <SearchIcon className="settlements-open-icon" onClick={handleOpen} />
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Settlement Details"
        aria-describedby="View Settlement Details"
        className="settlements-modal-wrapper"
      >
        <Box
          className="box-style"
          sx={{
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="modal-header">
            <h2>Settlement Details</h2>
            <CloseIcon className="close-icon" onClick={handleClose} />
          </div>
          <div className="modal-button-nav">
            <button onClick={handleClose} className="transaction-modal-button">
              <ChevronLeftIcon />
              <span>Back To Settlements</span>
            </button>
            <button
              onClick={handleTransSettlement}
              className="transaction-modal-button"
            >
              <span>Transaction Search</span>
              <ChevronRightIcon />
            </button>
          </div>
          <div className="table-margin-bottom">
            <SearchTable
              searchResults={[data]}
              getEntityKeyValue={getSaasKeyValue}
              fieldList={saasResultsColumnHeaders.filter((col) => {
                return col.key !== "action";
              })}
            />
          </div>
          {transactionResults?.length > 0 && (
            <div className="csv-button" data-cy="download-button-modal-parent">
              <DownloadButton
                handleSelection={handleSelection}
                types={["CSV", "PDF"]}
              />
            </div>
          )}
          <div className="scroll-table">
            {isLoading ? (
              <div className="circular-spinner">
                <CircularProgress />
              </div>
            ) : (
              transactionResults?.length > 0 && (
                <>
                  <SearchTable
                    searchResults={transactionResults}
                    getEntityKeyValue={getTransactionKeyValue}
                    fieldList={keyInfoLookup}
                  />
                  <div className="transaction-pdf-table" ref={tableRef}>
                    <div className="header-row">
                      <div>
                        <img src={logo} />
                      </div>

                      <h2>Transactions</h2>
                    </div>
                    <SearchTable
                      searchResults={pdfTransactionResults}
                      getEntityKeyValue={getTransactionKeyValue}
                      fieldList={keyInfoLookup}
                    />
                  </div>
                </>
              )
            )}
          </div>
          {transactionResults?.length > 0 && (
            <div>
              <SearchPaging
                resultsPerPage={resultsPerPage}
                resultsTotal={totalResults}
                handleChange={handlePageChange}
                handleResultsPerPageOnChange={handleResultsPerPageOnChange}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
SaasResultsDetailsModal.defaultProps = { data: {} };
SaasResultsDetailsModal.propTypes = { data: PropTypes.object };
export default SaasResultsDetailsModal;
