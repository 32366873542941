import { Route, Switch, Redirect } from "react-router";
import { useContext, useEffect } from "react";
import {
  generalError,
  noProcessors,
  merchantRoutes,
} from "../../constants/routes";
import ContentContainer from "../Content/ContentContainer";
import Unauthorized from "./Unauthorized";
import CVCreateContainer from "../Merchant/CVCreate/CVCreateContainer";
import CVSearchContainer from "../Merchant/CVSearch/CustomerSearchContainer";
import CVCustomerContainer from "../Merchant/CVSearch/CVCustomer/CVCustomerContainer";
import MerchantHelpContactUsContainer from "../Merchant/HelpContactUs/HelpContactUsContainer";
import HelpTOSContainer from "../Help/HelpTOSContainer";
import InvoicesCreateContainer from "../Merchant/InvoicesCreate/InvoicesCreateContainer";
import CustomFieldsListContainer from "../Merchant/ManageCustomFields/ManageCustomFieldsTable/CustomFieldsListContainer";
import InvoicesSearchContainer from "../Merchant/InvoicesSearch/InvoicesSearchContainer";
import MerchantManageSettingsContainer from "../Merchant/ManageSettings/ManageSettingsContainer";
import ManageUsersContainer from "../Merchant/ManageUsers/ManageUsersContainer";
import ManageUsersCreateContainer from "../Merchant/ManageUsers/ManageUsersCreate/ManageUsersCreateContainer";
import ManageUserDetailsContainer from "../Merchant/ManageUsers/ManageUsersView/ManageUserDetailsContainer";
import SaasReportingSettlementsContainer from "../Merchant/ReportingSettlements/SaasReportingSettlementsContainer";
import ReportingTransactionsContainer from "../Merchant/ReportingTransactions/ReportingTransactionsContainer";
import ReportingTransactionDetailContainer from "../Merchant/ReportingTransactions/ReportingTransactionDetailContainer";
import VirtualTerminalContainer from "../Merchant/VirtualTerminal/VirtualTerminalContainer";
import { USER_ROLES } from "../../constants/global";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { RolesAndPermissionContext } from "../../contexts/RolesAndPermissionContext";
import { useHistory, useLocation } from "react-router";
import ErrorContainer from "../Error/ErrorContainer";
import { ROLES_AND_PERMISSIONS_DEPENDENCIES } from "../../constants/global";
import CustomFieldsFormContainer from "../Merchant/ManageCustomFields/ManageCustomFieldsForm/CustomFieldsFormContainer";
import NoProcessorError from "../Error/NoProcessorError";
import InvoicesEditContainer from "../Merchant/InvoicesEdit/InvoicesEditContainer";
import { GatewayContext } from "../../contexts/GatewayContext";
import ApplicationContainer from "../DeveloperHubTerminal.jsx/ApplicationContainer";
import WebhooksContainer from "../DeveloperHubTerminal.jsx/WebhooksContainer";
// import SearchListExample from "../Search/SearchList/Example";

export const MerchantSwitch = (error) => {
  const { userSettings } = useContext(UserSettingsContext);
  const gateway = useContext(GatewayContext);
  const { rolesAndPermissions } = useContext(RolesAndPermissionContext);
  const history = useHistory();
  const location = useLocation();
  const invoicingAllowed =
    gateway?.gatewaySettings
      .find((p) => p.code === "ALLOW_INVOICING")
      ?.value.toLowerCase() === "true";

  useEffect(() => {
    if (
      Object.values(ROLES_AND_PERMISSIONS_DEPENDENCIES).includes(
        location.pathname,
      )
    ) {
      if (!rolesAndPermissions.permissions || !rolesAndPermissions.roles) {
        history.push({
          pathname: `/error`,
          error: "Missing user settings, try returning later.",
          rolesMissing: !rolesAndPermissions.roles,
          permissionsMissing: !rolesAndPermissions.permissions,
        });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (error?.error?.message) {
      history.push({
        pathname: `/error`,
        error: error,
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/no-processors") {
      history.push({
        pathname: `/merchant/virtual-terminal`,
      });
    }
  }, []);

  return (
    <Switch>
      {/* dashboard */}
      <Route
        path={merchantRoutes.virtual_terminal_token}
        component={VirtualTerminalContainer}
      />
      <Route
        path={merchantRoutes.virtual_terminal}
        component={VirtualTerminalContainer}
      />
      {/* reporting */}
      <Route
        path={`${merchantRoutes.reporting.transaction_detail}/:id`}
        component={ReportingTransactionDetailContainer}
      />
      <Route
        path={merchantRoutes.reporting.transactions}
        component={ReportingTransactionsContainer}
      />
      <Route
        path={merchantRoutes.reporting.settlements}
        component={
          userSettings?.gatewayUserPermissions["VIEW_SETTLEMENT_BATCHES"]
            ? SaasReportingSettlementsContainer
            : Unauthorized
        }
      />
      {/* invoices */}
      <Route
        path={merchantRoutes.invoices.create}
        component={invoicingAllowed ? InvoicesCreateContainer : Unauthorized}
      />
      <Route
        path={merchantRoutes.invoices.search}
        component={invoicingAllowed ? InvoicesSearchContainer : Unauthorized}
      />
      <Route
        path={`${merchantRoutes.invoices.base}/:id`}
        component={invoicingAllowed ? InvoicesEditContainer : Unauthorized}
      />
      <Redirect
        exact
        from={merchantRoutes.invoices.base}
        to={merchantRoutes.invoices.search}
      />
      {/* customer vault */}
      <Route
        path={merchantRoutes.customer.create}
        component={CVCreateContainer}
      />
      <Route
        path={merchantRoutes.customer.search}
        component={CVSearchContainer}
      />
      <Route
        path={`${merchantRoutes.customer.base}/:id`}
        component={CVCustomerContainer}
      />
      <Redirect
        exact
        from={merchantRoutes.customer.base}
        to={merchantRoutes.customer.search}
      />
      {/* manage */}
      {USER_ROLES.GATEWAY_ADMIN === userSettings.role.code && (
        <Route
          exact
          path={merchantRoutes.manage.users_create}
          component={
            USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
              ? ManageUsersCreateContainer
              : Unauthorized
          }
        />
      )}
      {USER_ROLES.GATEWAY_ADMIN === userSettings.role.code && (
        <Route
          path={`${merchantRoutes.manage.users}/:id`}
          component={
            USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
              ? ManageUserDetailsContainer
              : Unauthorized
          }
        />
      )}
      {USER_ROLES.GATEWAY_ADMIN === userSettings.role.code && (
        <Route
          exact
          path={merchantRoutes.manage.users}
          component={
            USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
              ? ManageUsersContainer
              : Unauthorized
          }
        />
      )}
      {USER_ROLES.GATEWAY_ADMIN === userSettings.role.code && (
        <Route
          exact
          path={merchantRoutes.developer_hub.applications}
          component={
            USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
              ? ApplicationContainer
              : Unauthorized
          }
        />
      )}
      {USER_ROLES.GATEWAY_ADMIN === userSettings.role.code && (
        <Route
          exact
          path={merchantRoutes.developer_hub.webhooks}
          component={
            USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
              ? WebhooksContainer
              : Unauthorized
          }
        />
      )}
      <Route
        path={merchantRoutes.manage.custom_fields.search}
        exact
        component={
          USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
            ? CustomFieldsListContainer
            : Unauthorized
        }
      />
      <Route
        path={merchantRoutes.manage.custom_fields.create}
        component={
          USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
            ? CustomFieldsFormContainer
            : Unauthorized
        }
      />
      <Route
        path={`${merchantRoutes.manage.custom_fields.update}/:id`}
        exact
        component={
          USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
            ? CustomFieldsFormContainer
            : Unauthorized
        }
      />

      <Route
        path={merchantRoutes.manage.settings}
        component={
          USER_ROLES.GATEWAY_ADMIN === userSettings.role.code
            ? MerchantManageSettingsContainer
            : Unauthorized
        }
      />
      {/* help */}
      <Route
        path={merchantRoutes.help.contact_us}
        component={MerchantHelpContactUsContainer}
      />
      <Route
        path={merchantRoutes.help.terms_of_service}
        component={HelpTOSContainer}
      />
      <Route path={"/error"} component={ErrorContainer} />
      <Route path={noProcessors} component={NoProcessorError} />
      <Redirect exact from="/" to={merchantRoutes.virtual_terminal} />
      <Route path={generalError} component={Unauthorized} />
      <Redirect to={generalError} />
      <Route path="/content" component={ContentContainer} />
    </Switch>
  );
};

export default MerchantSwitch;
