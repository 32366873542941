import { useState } from "react";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/stringHelpers";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import TerminalMenu from "./TerminalMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

const TerminalDetails = ({
  activeTerminalObj,
  gatewayInfo,
  showError,
  showSnackbar,
  setShowBackdrop,
  setAlertDialogOpen,
  setAlertDialogProps,
  getTerminalData,
}) => {
  const [tablePage, setTablePage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (e, newPage) => {
    setTablePage(newPage);
  };
  return (
    <Grid container flexDirection={"column"} rowSpacing={"16px"}>
      <Grid item>
        <Grid
          container
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item sx={9} className="h5">
            {activeTerminalObj?.name}
          </Grid>
          <Grid item sx={3}>
            <Grid container>
              {" "}
              <Grid
                item
                className={
                  activeTerminalObj?.status?.toLowerCase() === "active"
                    ? "tag sm tag__success"
                    : activeTerminalObj?.status?.toLowerCase() === "pending"
                      ? "tag sm tag__warning"
                      : "tag sm tag__failed"
                }
              >
                {activeTerminalObj?.status?.toUpperCase()}
              </Grid>
              <Grid item style={{ marginLeft: "8px" }}>
                <MoreVertIcon
                  aria-controls={open ? "positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
                <TerminalMenu
                  open={open}
                  terminalObj={activeTerminalObj}
                  gatewayInfo={gatewayInfo}
                  setShowBackdrop={setShowBackdrop}
                  showError={showError}
                  anchorEl={anchorEl}
                  setAlertDialogOpen={setAlertDialogOpen}
                  setAlertDialogProps={setAlertDialogProps}
                  handleClose={handleClose}
                  showSnackbar={showSnackbar}
                  getTerminalData={getTerminalData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container flexDirection={"column"} className="card-obj">
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              className="caption"
              justifyContent={"space-between"}
            >
              <Grid item sm={2.4}>
                Terminal ID
              </Grid>
              <Grid item sm={2.4}>
                Serial Number
              </Grid>
              <Grid item sm={2.4}>
                Source ID
              </Grid>
              <Grid item sm={2.4}>
                Model Name
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              className="sub-2"
              justifyContent={"space-between"}
            >
              <Grid item sm={2.4}>
                {activeTerminalObj?.terminalId?.substring(
                  activeTerminalObj?.terminalId?.length - 6,
                )}
              </Grid>
              <Grid item sm={2.4}>
                {activeTerminalObj?.serialNumber}
              </Grid>
              <Grid item sm={2.4}>
                {activeTerminalObj?.sourceTerminalId}
              </Grid>
              <Grid item sm={2.4}>
                {activeTerminalObj?.terminalType?.model}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent={"space-between"}>
          <Grid item className="h5">
            Installed Firmware
          </Grid>
          <Grid item>
            {/* <Button className="btn--link sm">
                              Upgrade Firmware +
                            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {activeTerminalObj?.providerTerminal?.installedFirmware ? (
          <Grid container flexDirection={"column"} className="card-obj">
            <Grid item>
              {
                activeTerminalObj?.providerTerminal?.installedFirmware
                  ?.firmwareName
              }
            </Grid>
            {activeTerminalObj?.providerTerminal?.installedFirmware
              ?.installTime && (
              <Grid item>
                {
                  activeTerminalObj?.providerTerminal?.installedFirmware
                    ?.installTime
                }
              </Grid>
            )}
          </Grid>
        ) : (
          <div className="card-obj">
            Installed Firmware is currently unavailable. Please reload the page
            to try again.
          </div>
        )}
      </Grid>
      <Grid item>
        <Grid container justifyContent={"space-between"}>
          <Grid item className="h5">
            Installed Apps
          </Grid>
          <Grid item>
            {/* <Button className="btn--link sm">
                              Install App +
                            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {activeTerminalObj?.providerTerminal?.installedApks?.length > 0 ? (
        <Grid
          item
          style={{
            width: "100%",
            height: "300px",
          }}
          className="table app-table"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>App</TableCell>
                <TableCell>Package</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Installation Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeTerminalObj?.providerTerminal?.installedApks
                .slice(tablePage * 3, tablePage * 3 + 3)
                .map((app, i) => (
                  <TableRow key={i}>
                    <TableCell>{app.appName}</TableCell>
                    <TableCell>{app.packageName}</TableCell>
                    <TableCell>{app.versionName}</TableCell>
                    <TableCell>{formatDateTime(app.installTime)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[3]}
            component="div"
            count={activeTerminalObj?.providerTerminal?.installedApks?.length}
            rowsPerPage={3}
            page={tablePage}
            onPageChange={handlePageChange}
          />
        </Grid>
      ) : (
        <Grid item>
          <div className="card-obj">
            Installed Apps is currently unavailable. Please reload the page to
            try again.
          </div>
        </Grid>
      )}
    </Grid>
  );
};

TerminalDetails.propTypes = {
  activeTerminalObj: PropTypes.obj,
  gatewayInfo: PropTypes.obj,
  showError: PropTypes.func,
  showSnackbar: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  setAlertDialogOpen: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
  getTerminalData: PropTypes.func,
};
export default TerminalDetails;
