import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Link, Grid, MenuItem, Typography } from "@mui/material";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import LineItem from "./LineItem";
import { sassEndpoints } from "../../../../constants/endpoints";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import axios from "axios";
import { useAlertDialog } from "../../../../hooks";
import Dinero from "dinero.js";
import Select from "../../../ReactHookForm/Select";
import AlertDialog from "../../../Dialog/AlertDialog";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { merchantRoutes } from "../../../../constants/routes";
import { CONTACT_SUPPORT } from "../../../../constants/global";

const getDefaultLineItem = (gatewayConfig, unitsOfMeasure) => {
  const localTaxPercent = Math.round(
    parseFloat(
      getGatewayConfigFieldVal(
        gatewayConfig,
        "default_values",
        "Line Items",
        "local_tax_rate",
      ),
    ) * 1000,
  );
  const nationalTaxPercent = Math.round(
    parseFloat(
      getGatewayConfigFieldVal(
        gatewayConfig,
        "default_values",
        "Line Items",
        "national_tax_rate",
      ),
    ) * 1000,
  );
  const isTaxable =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "default_values",
      "Line Items",
      "taxable",
    )?.toLowerCase() === "true";
  const unitOfMeasure = gatewayConfig?.categories
    ?.find((c) => c.code === "default_values")
    ?.groups?.find((g) => g.id === 3)
    ?.fields?.find((f) => f.code === "unit_of_measure");
  const unitOfMeasureId = unitsOfMeasure.find(
    (uom) =>
      uom.name ===
      unitOfMeasure.options.find((o) => o.value === unitOfMeasure.value)?.label,
  )?.unitOfMeasureId;

  return {
    name: "",
    description: "",
    quantity: 1,
    unitPrice: 0,
    discount: 0,
    unitOfMeasureId,
    isTaxable,
    localTaxPercent,
    nationalTaxPercent,
    advancedFieldsEnabled: false,
    shippedAmount: null,
    freightAmount: null,
  };
};

const defaultFees = {
  subtotal: 0,
  discounts: 0,
  tax: 0,
  total: 0,
  remainingBalance: 0,
};

const InvoiceStepThree = ({
  invoice,
  onPageChange,
  onSubmit,
  unitsOfMeasure,
  mode,
  onEdit,
  onDelete,
  disableSubmit,
}) => {
  const { gatewayConfigSettings } = useContext(GatewayConfigContext);
  const defaultLineItem = getDefaultLineItem(
    gatewayConfigSettings,
    unitsOfMeasure,
  );
  const {
    userSettings: { gatewayId },
  } = useContext(UserSettingsContext);
  const history = useHistory();
  const { isAlertOpen, setIsAlertOpen, alertMessages } = useAlertDialog();
  const [fees, setFees] = useState(
    invoice.remainingBalance !== undefined
      ? {
          subtotal: invoice.subtotal,
          discounts: invoice.discounts,
          tax: invoice.tax,
          shipping: { amount: invoice.shipping },
          total:
            invoice.total !== undefined
              ? invoice.total
              : invoice.remainingBalance,
          remainingBalance: invoice.remainingBalance,
        }
      : defaultFees,
  );
  const [alertDialogProps, setAlertDialogProps] = useState({
    alertTitle: "Error",
    alertLevel: "error",
    alertMessages,
    onCloseButtonClick: () => setIsAlertOpen(false),
  });
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      lineItems: invoice.lineItems?.map((item) => ({
        ...item,
        unitPrice: Math.round(item.unitPrice * 100),
        discount: Math.round(item.discount * 100),
        freightAmount: item.freightAmount
          ? Math.round(item.freightAmount * 100)
          : null,
        shippedAmount: item.shippedAmount || null,
        localTaxPercent: item.isTaxable
          ? Math.round(item.localTaxPercent * 1000)
          : defaultLineItem.localTaxPercent,
        nationalTaxPercent: item.isTaxable
          ? Math.round(item.nationalTaxPercent * 1000)
          : defaultLineItem.nationalTaxPercent,
        advancedFieldsEnabled:
          (item.shippedAmount || null) !== null ||
          (item.freightAmount || null) !== null,
        unitOfMeasureId:
          item.unitOfMeasureId || defaultLineItem.unitOfMeasureId,
      })) || [defaultLineItem],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "lineItems",
  });

  useEffect(() => {
    if (invoice.remainingBalance !== undefined) {
      methods.trigger();
      calculateFees();
    }
  }, []);

  const formatInvoice = ({ lineItems, invoiceStatusId }) => {
    return {
      invoiceStatusId,
      invoiceTypeId: 1, // Hard coding invoice type id to OneTime for MVP
      lineItems: lineItems.map((item) => ({
        ...item,
        unitPrice: Dinero({ amount: item.unitPrice }).toUnit(),
        discount: Dinero({ amount: item.discount }).toUnit(),
        shippedAmount: item.advancedFieldsEnabled ? item.shippedAmount : null,
        freightAmount: item.advancedFieldsEnabled
          ? Dinero({
              amount: item.freightAmount || 0,
            }).toUnit()
          : null,
        unitOfMeasureId: item.advancedFieldsEnabled
          ? item.unitOfMeasureId
          : null,
        localTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.localTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
        nationalTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.nationalTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
      })),
      remainingBalance: parseFloat(fees.total),
      subtotal: parseFloat(fees.subtotal),
      total: parseFloat(fees.total),
      tax: parseFloat(fees.tax),
      discounts: parseFloat(fees.discounts),
    };
  };

  const handlePageChange = (invoice) => {
    onPageChange(formatInvoice(invoice));
  };

  const handleSubmit = (invoice) => {
    setIsAlertOpen(false);
    methods.setValue("invoiceStatusId", "");
    onSubmit(formatInvoice(invoice));
  };

  const handleDelete = (index) => {
    if (mode === "edit")
      onDelete(formatInvoice(methods.getValues()).lineItems[index], index);

    remove(index);

    calculateFees();
  };

  const handleClear = () => {
    if (mode === "edit")
      methods
        .getValues("lineItems")
        .forEach((li, index) => onDelete(li, index));
    methods.resetField("lineItems", { defaultValue: [defaultLineItem] });

    calculateFees();
  };

  const handleMarkAsPaid = (invoice) => {
    invoice.invoiceStatusId = 4;
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: [
        "Once this invoice is marked as paid, this invoice will be created and this action cannot be updated or undone.",
      ],
      actionButtons: [
        {
          text: "Yes, Mark Paid!",
          color: "secondary",
          onclick: () => handleSubmit(invoice),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "secondary",
      closeButtonVariant: "outlined",
      onCloseButtonClick: () => setIsAlertOpen(false),
    });
    setIsAlertOpen(true);
  };

  const calculateFees = () => {
    const { lineItems } = methods.getValues();
    const payload = {
      lineItems: lineItems.map((lineItem) => ({
        quantity: lineItem.quantity,
        unitPrice: Dinero({ amount: lineItem.unitPrice }).toUnit(),
        discount: Dinero({ amount: lineItem.discount }).toUnit(),
        freightAmount: lineItem.advancedFieldsEnabled
          ? Dinero({
              amount: lineItem.freightAmount || 0,
            }).toUnit()
          : null,
        isTaxable: lineItem.isTaxable,
        localTaxPercent: lineItem.isTaxable
          ? Dinero({
              amount: lineItem.localTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
        nationalTaxPercent: lineItem.isTaxable
          ? Dinero({
              amount: lineItem.nationalTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
      })),
      shippingState: invoice.invoiceAddresses.find((a) => a.isShipping)?.state,
    };
    const url = sassEndpoints.invoices.calculateFees.replace(
      "{gatewayId}",
      gatewayId,
    );

    axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          let fees = response.data.data;

          if (mode !== "view") fees.remainingBalance = fees.total;
          else fees.remainingBalance = invoice.remainingBalance;

          // Default to pretax as that is more common
          if (fees.shipping?.amount > 0 && !fees.shipping?.taxPlacement)
            fees.shipping.taxPlacement = "PRE";

          setFees(fees);
        }
      })
      .catch((e) => {
        setAlertDialogProps({
          ...alertDialogProps,
          alertMessages: e?.response?.data?.statusDetails || [
            "Failed to calculate totals",
            CONTACT_SUPPORT,
          ],
        });
        setIsAlertOpen(true);
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => e.preventDefault()}
        onBlur={
          onEdit && Object.keys(methods.formState.errors).length === 0
            ? methods.handleSubmit((values) => onEdit(formatInvoice(values)))
            : null
        }
      >
        <Grid container>
          <Grid item container className="invoice-section" padding={4}>
            {fields.map((_field, index) => (
              <LineItem
                key={index}
                index={index}
                viewOnly={mode === "view"}
                unitsOfMeasure={unitsOfMeasure}
                showDelete={fields.length > 1}
                onDelete={handleDelete}
                onBlur={calculateFees}
              />
            ))}
            <Grid item container className="invoice-actions-container">
              <Grid item container xs={12} sm={6} spacing={1} marginBottom={2}>
                {mode !== "view" && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        data-cy="add-line-item"
                        onClick={() => append(defaultLineItem)}
                      >
                        Add Line Item
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClear}
                      >
                        Clear All Line Items
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                item
                container
                columnSpacing={3}
                className="invoice-calculation-container"
              >
                <Grid item>
                  <p>Subtotal:</p>
                  <p>Discounts applied:</p>
                  {fees.shipping?.amount > 0 &&
                    fees.shipping?.taxPlacement === "PRE" && <p>Shipping: </p>}
                  <p>Taxes:</p>
                  {fees.shipping?.amount > 0 &&
                    fees.shipping?.taxPlacement === "POST" && <p>Shipping: </p>}
                  {(invoice.fees || 0) > 0 && <p>Fees:</p>}
                  {invoice.total &&
                    invoice.total !== invoice.remainingBalance && (
                      <p>Amount Paid:</p>
                    )}
                </Grid>
                <Grid item>
                  <p>${fees.subtotal?.toFixed(2)}</p>
                  <p>-${fees.discounts?.toFixed(2)}</p>
                  {fees.shipping?.amount > 0 &&
                    fees.shipping?.taxPlacement === "PRE" && (
                      <p>${fees.shipping?.amount.toFixed(2)}</p>
                    )}
                  <p>${fees.tax?.toFixed(2)}</p>
                  {fees.shipping?.amount > 0 &&
                    fees.shipping?.taxPlacement === "POST" && (
                      <p>${fees.shipping?.amount.toFixed(2)}</p>
                    )}
                  {(invoice.fees || 0) > 0 && (
                    <p>${invoice.fees?.toFixed(2)}</p>
                  )}
                  {invoice.total &&
                    invoice.total !== invoice.remainingBalance && (
                      <p>
                        -$
                        {invoice.amountCaptured?.toFixed(2)}
                      </p>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              className="invoice-total-container"
              justifyContent="space-between"
            >
              <Grid
                item
                container
                spacing={2}
                xs={12}
                sm="auto"
                justifyContent="flex-end"
              >
                <Grid item>
                  {invoice.total &&
                    invoice.total !== invoice.remainingBalance && (
                      <p className="original-balance">Original Balance Due:</p>
                    )}
                  <Typography
                    color={mode !== "view" ? "secondary" : ""}
                    className="invoice-total"
                  >
                    {invoice.total && invoice.total !== invoice.remainingBalance
                      ? "Remaining Balance Due:"
                      : "Balance Due:"}
                  </Typography>
                </Grid>
                <Grid item>
                  {invoice.total &&
                    invoice.total !== invoice.remainingBalance && (
                      <p className="original-balance">
                        ${fees.total?.toFixed(2)}
                      </p>
                    )}
                  <Typography
                    color={mode !== "view" ? "secondary" : ""}
                    className="invoice-total"
                  >
                    ${fees.remainingBalance?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              {invoice.transactions?.length > 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  sm="auto"
                  alignSelf="flex-end"
                  className="transaction-history-link"
                >
                  <Link
                    color="secondary"
                    onClick={() =>
                      history.push(merchantRoutes.reporting.transactions, {
                        invoiceId: invoice.invoiceId,
                      })
                    }
                  >
                    View Transaction History
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
          {mode !== "view" && (
            <Grid
              item
              container
              xs={12}
              spacing={1}
              className="next-page-button"
            >
              {mode === "create" && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handlePageChange(methods.getValues())}
                  >
                    Previous
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={methods.handleSubmit(handleMarkAsPaid)}
                  data-cy="paid-invoice"
                  disabled={!methods.formState.isValid || fees.total === 0}
                >
                  Mark As Paid
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Select
                  control={methods.control}
                  name="invoiceStatusId"
                  label={mode === "create" ? "Create" : "Update"}
                  fullWidth
                  className="invoice-create-menu"
                  disabled={
                    !methods.formState.isValid ||
                    fees.total === 0 ||
                    disableSubmit
                  }
                  onChange={methods.handleSubmit(handleSubmit)}
                >
                  <MenuItem value={1}>{`${
                    mode === "create" ? "Create" : "Update"
                  } and Save`}</MenuItem>
                  {(invoice.invoiceNotifications.length > 0 ||
                    mode === "edit") && (
                    <MenuItem value={3}>{`${
                      mode === "create" ? "Create" : "Update"
                    } and Send`}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <AlertDialog {...alertDialogProps} alertOpen={isAlertOpen} />
    </FormProvider>
  );
};

InvoiceStepThree.propTypes = {
  invoice: PropTypes.object,
  onPageChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.oneOf(["create", "edit", "view"]),
  disableSubmit: PropTypes.bool,
};

InvoiceStepThree.defaultProps = {
  mode: "create",
};

export default InvoiceStepThree;
