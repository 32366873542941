import React from "react";
import PropTypes from "prop-types";
import { Button, Radio, Menu, Grid } from "@mui/material";
import "./globalSearch.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { useTheme } from "@mui/material/styles";

const DateRangePicker = ({
  defaultDateObj,
  anchorEl,
  open,
  onClose,
  globalFilters,
  setGlobalFilters,
  dates,
  setDates,
  searchByDate,
  setSearchByDate,
}) => {
  const theme = useTheme();

  const setClickedDates = (x) => {
    let d = new Date();
    switch (x) {
      case 0:
        setDates([
          {
            startDate: new Date(d.setDate(d.getDate() - 6)).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "selection",
          },
        ]);
        break;
      case 1:
        setDates([
          {
            startDate: new Date(d.setDate(d.getDate() - 29)).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "selection",
          },
        ]);
        break;
      case 2:
        setDates([
          {
            startDate: new Date(
              new Date().setFullYear(new Date().getFullYear() - 1),
            ).setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59),
            key: "selection",
          },
        ]);
        break;
      case 3:
        setDates([
          {
            startDate: new Date(new Date().getFullYear(), 0, 1).setHours(
              0,
              0,
              0,
            ),
            endDate: new Date().setHours(23, 59, 59),
            key: "selection",
          },
        ]);
        break;
    }
  };

  const handleRadioButton = (e) => {
    setSearchByDate(e.target.value);
  };

  const handleChange = (item) => {
    item.selection.startDate.setHours(0, 0, 0);
    item.selection.endDate.setHours(23, 59, 59);
    setDates([item.selection]);
  };

  const handleFilter = () => {
    if (dates[0].startDate === null && dates[0].endDate === null) {
      if (globalFilters.dueDate) {
        delete globalFilters.dueDate;
      }
      if (globalFilters.createdDateTime) {
        delete globalFilters.createdDateTime;
      }
      setGlobalFilters({ ...globalFilters });
    } else {
      if (searchByDate === "createdDate") {
        if (globalFilters.dueDate) {
          delete globalFilters.dueDate;
        }
        setGlobalFilters({
          ...globalFilters,
          createdDateTime: {
            startDate: new Date(dates[0].startDate),
            operator: "Between",
            endDate: new Date(new Date(dates[0].endDate).setHours(23, 59, 59)),
          },
        });
      } else {
        if (globalFilters.createdDateTime) {
          delete globalFilters.createdDateTime;
        }
        setGlobalFilters({
          ...globalFilters,
          dueDate: {
            startDate: new Date(dates[0].startDate),
            operator: "Between",
            endDate: new Date(new Date(dates[0].endDate).setHours(23, 59, 59)),
          },
        });
      }
    }

    onClose();
  };

  return (
    <>
      <Menu
        className={theme?.palette?.mode === "dark" ? "dark-theme" : ""}
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="calendar-container">
          <Grid container className="default-date-btns">
            {defaultDateObj.map((obj, i) => (
              <Button
                key={i}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setClickedDates(obj.value);
                }}
              >
                {obj.label}
              </Button>
            ))}
          </Grid>
          <div className="menu-checked">
            <Radio
              color="secondary"
              checked={searchByDate === "dueDate"}
              onChange={handleRadioButton}
              value="dueDate"
              name="radio-buttons"
              inputProps={{ "aria-label": "Due Date" }}
            />
            {"Due Date "}
            <Radio
              color="secondary"
              checked={searchByDate === "createdDate"}
              onChange={handleRadioButton}
              value="createdDate"
              name="radio-buttons"
              inputProps={{ "aria-label": "Created On" }}
            />
            {"Created On "}
          </div>
          <div className="date-range-picker">
            <DateRange
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              editableDateInputs={true}
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
              ranges={dates}
            />
          </div>
          <Grid container justifyContent="space-evenly">
            <Button
              data-cy="reset_filter"
              className="reset-filter-button"
              onClick={() => {
                setDates([
                  {
                    startDate: null,
                    endDate: null,
                    key: "selection",
                  },
                ]);
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFilter}
              data-cy="date_filter"
            >
              Filter
            </Button>
          </Grid>
        </div>
      </Menu>
    </>
  );
};

DateRangePicker.propTypes = {
  defaultDateObj: PropTypes.array,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setGlobalFilters: PropTypes.func,
  globalFilters: PropTypes.object,
  dates: PropTypes.arrayOf(PropTypes.object),
  setDates: PropTypes.func,
  searchByDate: PropTypes.string,
  setSearchByDate: PropTypes.func,
};

export default DateRangePicker;
