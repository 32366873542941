import { Grid, Link } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link as RouterLink } from "react-router-dom";
import { formatDateTime } from "../../../utils/stringHelpers";
import { merchantRoutes } from "../../../constants/routes";

export const getInvoicesKeyValue = (key, data, userSettings) => {
  const invoiceId =
    data?.invoiceId?.length > 8
      ? data.invoiceId.slice(0, 4) + "..." + data.invoiceId.slice(-4)
      : data?.invoiceId;

  switch (key) {
    case "invoice_id":
      return userSettings?.gatewayUserPermissions["READ_INVOICE"] ? (
        <Link
          color="secondary"
          component={RouterLink}
          to={`${merchantRoutes.invoices.base}/${data.invoiceId}`}
        >
          {invoiceId}
        </Link>
      ) : (
        invoiceId
      );

    case "remaining_balance": {
      return `$${data?.remainingBalance?.toFixed(2) || "0.00"}`;
    }

    case "captured_amount": {
      return `$${data?.amountCaptured?.toFixed(2) || "0.00"}`;
    }

    case "fees": {
      return `$${data?.fees?.toFixed(2) || "0.00"}`;
    }

    case "due_date": {
      return formatDateTime(data?.dueDate).split(" ")[0];
    }
    case "created_on": {
      return formatDateTime(data?.createdDateTime).split(" ")[0];
    }

    case "customer_name": {
      return data?.customer?.name;
    }

    case "status": {
      return (
        <Grid container>
          <FiberManualRecordIcon
            fontSize="inherit"
            htmlColor={getStatusColors(data?.invoiceStatus.name)}
            style={{ margin: "0 7 0 5", alignSelf: "center" }}
          />
          {data?.invoiceStatus.name}
        </Grid>
      );
    }

    default:
      return data[key];
  }
};

export const getStatusColors = (status) => {
  switch (status) {
    case "Draft": {
      return "#D9D9D9";
    }
    case "Overdue": {
      return "#F57C00";
    }
    case "Cancelled": {
      return "#FBC02D";
    }
    case "Sent": {
      return "#189AD0";
    }
    case "Paid": {
      return "#43A047";
    }
    case "Partially Paid": {
      return "#D4E157";
    }
    case "default": {
      return "#D9D9D9";
    }
  }
};
