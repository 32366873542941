import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import Select from "../../../ReactHookForm/Select";
import AutoCompleteStates from "../../../Contact/AutoCompleteStates";
import PhoneNumber from "../../../Contact/PhoneNumber";
import { useAlertDialog, useIsMounted } from "../../../../hooks";
import AlertDialog from "../../../Dialog/AlertDialog";
import { State } from "country-state-city";
const InvoiceContactForm = ({
  countries,
  type,
  requiredFields,
  defaultValues,
  viewOnly,
  onChange,
}) => {
  let country = useRef("US");
  let isMounted = useIsMounted();
  const { isAlertOpen, setIsAlertOpen, alertMessages } = useAlertDialog();
  const [states, setStates] = useState([]);
  const methods = useFormContext();
  const address = useWatch({
    control: methods.control,
    name: type,
    defaultValue: defaultValues,
  });

  useEffect(() => {
    loadStates(
      defaultValues?.country || "US",
      methods.getValues(`${type}.state`) || defaultValues?.state,
    );
  }, [defaultValues]);

  useEffect(() => {
    if (onChange) onChange(type);
  }, [address]);

  const handleCountryChange = (newCountry, state) => {
    if (country.current === newCountry) return;
    methods.setValue(`${type}.state`, null);
    country.current = newCountry;
    newCountry === "" ? setStates([]) : loadStates(newCountry, state);
  };

  const loadStates = (country, state) => {
    if (isMounted()) {
      setStates(
        State.getStatesOfCountry(country).map((s) => {
          s.code = s.isoCode;
          return s;
        }),
      );
      if (country) methods.setValue(`${type}.country`, country);
      if (state) methods.setValue(`${type}.state`, state);
    }
  };

  return (
    <>
      <Grid container spacing={0} className="invoice-contact-form">
        <Grid container rowSpacing={1.5} columnSpacing={1}>
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="firstName"
              inputProps={{
                "data-cy": "vt_first_name",
              }}
              name={`${type}.firstName`}
              defaultValue={defaultValues?.firstName}
              label="First name"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength50,
                required: requiredFields.firstName,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="lastName"
              inputProps={{
                "data-cy": "vt_last_name",
              }}
              name={`${type}.lastName`}
              defaultValue={defaultValues?.lastName}
              label="Last name"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength50,
                required: requiredFields.lastName,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RegisteredTextField
              id="company"
              inputProps={{
                "data-cy": "vt_company",
              }}
              name={`${type}.company`}
              defaultValue={defaultValues?.company}
              label="Company name"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength50,
                required: requiredFields.company,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <RegisteredTextField
              id="address1"
              inputProps={{
                "data-cy": "vt_address1",
              }}
              name={`${type}.addressLine1`}
              defaultValue={defaultValues?.addressLine1}
              label="Address line 1"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength100,
                required: requiredFields.addressLine1,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <RegisteredTextField
              id="address2"
              inputProps={{
                "data-cy": "vt_address2",
              }}
              name={`${type}.addressLine2`}
              defaultValue={defaultValues?.addressLine2}
              label="Address line 2"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength100,
                required: requiredFields.addressLine2,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              control={methods.control}
              name={`${type}.country`}
              label="Country"
              onChange={handleCountryChange}
              defaultValue={defaultValues?.country || "US"}
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength40,
                required: requiredFields.country,
              }}
              data-cy="contact-country"
            >
              <MenuItem key="country-default" value="">
                Select Country
              </MenuItem>
              {countries.map((country) => (
                <MenuItem
                  key={`${type}-option-value-${country.code}`}
                  name={`${type}.country.${country.code}`}
                  value={country.code}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="city"
              inputProps={{
                "data-cy": "vt_city",
              }}
              name={`${type}.city`}
              defaultValue={defaultValues?.city}
              label="City"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength40,
                required: requiredFields.city,
                pattern: validationRules.city,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AutoCompleteStates
              options={states}
              data-cy="contact-state"
              name={`${type}.state`}
              defaultValue={
                defaultValues?.state?.length > 0
                  ? defaultValues.state
                  : undefined
              }
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength3,
                required: requiredFields.state,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <RegisteredTextField
              id="postal_code"
              inputProps={{
                "data-cy": "vt_postal_code",
              }}
              name={`${type}.postalCode`}
              defaultValue={defaultValues?.postalCode}
              label="Postal Code"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                maxLength: validationRules.maxLength10,
                required: requiredFields.postalCode,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="email"
              inputProps={{
                "data-cy": "vt_email",
              }}
              name={`${type}.email`}
              defaultValue={defaultValues?.email}
              label="Email"
              fullWidth
              viewOnly={viewOnly}
              rules={{
                pattern: validationRules.email,
                maxLength: validationRules.maxLength100,
                required: requiredFields.email,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PhoneNumber
              name={`${type}.phone`}
              id="phone"
              defaultValue={defaultValues?.phone || ""}
              label="Phone"
              required={requiredFields.phone}
              viewOnly={viewOnly}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PhoneNumber
              name={`${type}.fax`}
              id="fax"
              defaultValue={defaultValues?.fax || ""}
              label="Fax"
              required={requiredFields.fax}
              viewOnly={viewOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        alertTitle="Error"
        alertLevel="error"
        alertOpen={isAlertOpen}
        alertMessages={alertMessages}
        onCloseButtonClick={() => setIsAlertOpen(false)}
      />
    </>
  );
};

InvoiceContactForm.propTypes = {
  countries: PropTypes.array,
  type: PropTypes.string,
  requiredFields: PropTypes.object,
  defaultValues: PropTypes.object,
  viewOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

InvoiceContactForm.defaultPropTypes = {
  viewOnly: false,
};

export default InvoiceContactForm;
